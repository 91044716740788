/*
 * まとめ記事
 */

import Vue from 'vue';
import '../../utils/filters.js';

let ShowArticle = Vue.extend({
  props: ['items', 'talkData', 'workData', 'affiliateId', 'isLoaded', 'isAdultMode'],

  template: require('../views/show-article.html'),
});

module.exports = ShowArticle;