/*
 * マガジン内本文
 */

import Vue from 'vue';
import '../../utils/filters.js';
import EventBus from "../../utils/event-bus";

let ShowArticle = Vue.extend({
  props: ['items', 'affiliateId', 'isLoaded'],
  data () {
    return {
      orderBy: {
        new: {
          label: '新着順',
          value: 'new'
        },
        update: {
          label: '最近更新された',
          value: 'update'
        },
        vote: {
          label: 'いいねされた数',
          value: 'vote'
        },
        view: {
          label: 'よく見られている',
          value: 'view'
        },
        condition: 'new',
        classes: {
          isOpen: false
        }
      },
    };
  },
  template: require('../views/show-article.html'),
  methods: {
    // ソート切り替え
    toggleOrder (name, val, defaultVal) {
      this.orderBy.condition = val;

      EventBus.$emit('toggleCondition', name, val, defaultVal);
    },
    mounted() {
      this.$nextTick(() => {
        const self = this;

        document.body.addEventListener('click', () => {
          self.orderBy.classes.isOpen = false;
        });

        this.orderBy.condition = this.$root.query.order_by;
      });
    }
  }
});

module.exports = ShowArticle;