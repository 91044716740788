import Vue from 'vue';
import '../../utils/filters.js';
import Validation from '../../utils/validation.js';
import EventBus from '../../utils/event-bus';

const validation = new Validation();

let ArticleAction = Vue.extend({
  props: ['items'],

  data () {
    return {
      compName: 'articleAction'
    };
  },

  template: require('../views/article-action.html'),

  methods: {
    /**
     * アイテム削除
     */
    removeItem(index){
      // 親のremoveItemを実行
      EventBus.$emit('removeItem', index);
    }
  }
});

module.exports = ArticleAction;
