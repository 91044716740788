/**
 * まとめトップ
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import "../utils/service-survey.js"

let MatomeTop = Super.extend({
  data () {
    return {
      isLoading: null,
      fetchedDomString: {
        favView: null,
        newView: null,
        prView: null,
        sideView: null,
        campaignView: null
      },
      spActiveTab: 'campaign'
    };
  },

  methods: {
    fetchDomString () {
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/matome/open/api/matome-top-contents/${this.apiSuffix}`
      }, true)
      .then(response => {
        this.fetchedDomString = response.data;

        if (!this.fetchedDomString.campaignView) this.spActiveTab = 'new';

        this.$nextTick(() => {
          this.isLoading = false;

          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.initTwitterWidget();
          this.isRendered = true;
        });
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      // 年齢認証表示判定
      if (this.userInfo.is_adult_mode && !this.userInfo.can_show_adult) {
        document.body.classList.add('is-scroll-lock');
        document.body.classList.add('adult-check');
      }
  
      this.fetchDomString();
    });
  }
});

module.exports = MatomeTop;