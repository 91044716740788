/**
 * マイページ
 */

import Vue from 'vue';
import _ from 'lodash';
import swal from 'sweetalert';
import HasModal from '../utils/has-modal.js';
import Validation from '../utils/validation.js';
import EventBus from '../utils/event-bus';

// アイコン画像のファイルサイズ上限
const iconImageSizeMax = 2000000;
const validation = new Validation();
let initialData;

let Settings = HasModal.extend({
  data () {
    return {
      iconImage: {
        dataUrl: null,
        thumbData: null,
        delFlg: false,
        _blobUri: null,
        _type: null
      },
      nicknameDraft: '',
      descriptionDraft: '',
      affiliateIdDraft: '',
      validation: {
        nickname: {
          isValid: true,
          message: ''
        },
        description: {
          isValid: true,
          message: ''
        },
        affiliateId: {
          isValid: true,
          message: ''
        }
      },
      isSubmitting: false
    };
  },

  methods: {
    /**
     * バリデーション: ニックネーム
     */
    validateNickname () {
      let _nickname = this.nicknameDraft.trim();
      this.validation.nickname.isValid = true;
      this.validation.nickname.message = '';

      if (!validation.maxSize(_nickname, 50)) {
        this.validation.nickname.isValid = false;
        this.validation.nickname.message = '※50字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 自己紹介
     */
    validateDescription () {
      let _description = this.descriptionDraft.trim();
      this.validation.description.isValid = true;
      this.validation.description.message = '';

      if (!validation.maxSize(_description, 250)) {
        this.validation.description.isValid = false;
        this.validation.description.message = '※250字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: アフィリエイト ID
     */
    validateAffiliateId () {
      let _affiliateId = this.affiliateIdDraft.trim();
      this.validation.affiliateId.isValid = true;
      this.validation.affiliateId.message = '';

      if (!validation.maxSize(_affiliateId, 20)) {
        this.validation.affiliateId.isValid = false;
        this.validation.affiliateId.message = '※20字以内で入力してください。';

        return;
      }
    },

    /**
     * アイコン削除
     *
     * アイコン削除ボタン押下時、アイコン情報をクリア
     */
    deleteIcon () {
      this.iconImage.dataUrl = null;
      this.iconImage.thumbData = null;
      this.iconImage.delFlg = true;
      this.iconImage._blobUri = null;
      this.iconImage._type = null;
    },

    /**
     * アイコンアップロードの設定
     */
    initIconUploader () {
      let iconUpload = document.getElementById('icon-upload');

      // アイコン画像選択時
      iconUpload.addEventListener('change', () => {
        let file = iconUpload.files[0];

        if (!file) return;

        if (!/\bimage/.test(file.type)) {
          // 画像ファイルではない場合
          swal({
            title: '',
            text: '画像ファイルをアップロードしてください',
            confirmButtonColor: '#17A1E6',
            type: 'warning'
          });

          return;
        } else if (file.size > iconImageSizeMax) {
          // 画像サイズが上限を超えている場合
          swal({
            title: '',
            text: '2MB以下の画像ファイルをアップロードしてください',
            confirmButtonColor: '#17A1E6',
            type: 'warning'
          });

          return;
        }

        this.iconImage._type = file.type;

        let fileReader = new FileReader();

        // アイコン画像読み込み時
        fileReader.onload = () => {
          this.iconImage._blobUri = window.URL.createObjectURL(file);

          this.$nextTick(() => {
            this.callModal('icon', null, this.iconImage);
          });

          iconUpload.value = null;
        };

        fileReader.readAsDataURL(file);
      });
    },

    /**
     * 変更を保存
     */
    saveProfile () {
      this.validateNickname();
      this.validateDescription();
      this.validateAffiliateId();

      if (!(this.validation.nickname.isValid && this.validation.description.isValid && this.validation.affiliateId.isValid)) return;

      this.isSubmitting = true;

      if (this.nicknameDraft === 'まだ名前がありません') this.nicknameDraft = '';

      this.$nextTick(() => {
        window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
        document.forms.profile.submit();
      });
    },

    /**
     * エラーボックスを非表示
     */
    closeErrorBox () {
      document.querySelector('.fixed-notice.is-visible').classList.remove('is-visible');
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler (e) {
      const message = 'このページから移動してもよろしいですか？';

      initialData.mode = this.mode;
      initialData.timeline = this.timeline;
      initialData.timelinePage = this.timelinePage;
      initialData.currentView = this.currentView;
      initialData.filter = this.filter;
      initialData.throttled = this.throttled;
      initialData.isNoContent = this.isNoContent;

      if (JSON.stringify(this.$data) !== JSON.stringify(initialData)) {
        e.returnValue = message;
        return message;
      }
    },

    /**
     * アイコン設定
     * @param  {String} dataUrl [アイコン画像の dataUrl]
     */
    setIcon (dataUrl) {
      let base64 = dataUrl.replace(/^.*,/, '');

      this.iconImage.dataUrl = dataUrl;
      this.iconImage.thumbData = base64;
      this.iconImage.delFlg = false;
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.nicknameDraft = document.getElementById('nickname').getAttribute('data-value');
      this.descriptionDraft = document.getElementById('description').getAttribute('data-value');
      this.affiliateIdDraft = document.getElementById('affiliate-id').getAttribute('data-value');

      let self = this;
      // 初期状態の保存
      initialData = _.cloneDeep(self.$data);

      if (!self.isNest) window.addEventListener('beforeunload', self.onBeforeUnloadHandler);

      // アイコンアップロードの設定
      self.initIconUploader();

      this.isRendered = true;

      EventBus.$on('setIcon', this.setIcon);
    });
  }
});

module.exports = Settings;
