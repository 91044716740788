/**
 * アンテナ: 作品詳細画面
 */

import Vue from 'vue';
import Swiper from 'swiper';
import Velocity from 'velocity-animate';
import Super from '../utils/super.js';
import VoteUnit from '../utils/vote-unit.js';
import WatchUnit from '../utils/watch-unit.js';
import ButtonUnit from './button-unit.js';

let galleryTop, galleryThumbs;

let AntennaDetail = Super.extend({
  data () {
    return {
      isDropdownOpened: false,
      mainView: null,
      isTheaterMode: false
    }
  },

  components: {
    'vote-unit-component': VoteUnit,
    'watch-unit-component': WatchUnit,
    'button-unit-component': ButtonUnit
  },

  methods: {
    /**
     * シアターモード切り替え
     */
    toggleTheaterMode () {
      this.isTheaterMode = !this.isTheaterMode;

      setTimeout(() => {
        galleryTop.updateContainerSize();
        galleryThumbs.updateContainerSize();

        let resizeEvent = document.createEvent('HTMLEvents');
        resizeEvent.initEvent('resize', false, true);

        window.dispatchEvent(resizeEvent);
        // サムネイルエリアの選択中サムネイルがずれる問題の対応
        window.dispatchEvent(resizeEvent);
      }, 400);

    },

    /**
     * メディアリストのスライド設定
     */
    setSwiper () {
      galleryTop = new Swiper('.gallery-top', {
        simulateTouch: false,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
      });

      galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        mousewheelControl: true
      });

      galleryTop.params.control = galleryThumbs;
      galleryThumbs.params.control = galleryTop;
    },

    /**
     * メディアリスト位置にスクロール
     */
    scrollToMediaList () {
      let offsetTop = document.getElementsByClassName('player-container')[0].offsetTop;

      Velocity(document.body, 'scroll', { duration: 200, offset: offsetTop });
    }
  },

  mounted () {
    this.$nextTick(() => {
      let self = this;

      document.body.addEventListener('click', () => {
        self.isDropdownOpened = false;
      });

      // メディアリストのクラス管理(IE / Edge)
      let ua = window.navigator.userAgent.toLowerCase();

      if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1 || ua.indexOf('edge') !== -1) {
        let playerBody = document.getElementsByClassName('player-body')[0];

        if (playerBody) {
          playerBody.addEventListener('mouseover', () => {
            playerBody.classList.add('is-hover');
          });

          playerBody.addEventListener('mouseout', () => {
            playerBody.classList.remove('is-hover');
          });
        }
      }

      self.setSwiper();
      this.isRendered = true;
    });
  }
});

module.exports = AntennaDetail;