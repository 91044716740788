/**
 * ツイッター検索モーダル
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import swal from 'sweetalert';
import dragula from 'dragula';
import EventBus from '../event-bus';
import '../../utils/filters.js';

let TwitterSearchAction = Vue.extend({
  props: ['searchResult'],

  data () {
    return {
      keyword: '',
      page: 0,
      nextToken: null,
      tab: 'search',
      timeline: 'public',
      selectingTweets: [],
      showMedia: true,
      index: 0,
      isLoading: false,
      isNoContent: false,
      isApiLimit: false,
      isAuthenticationError: false,
      isError: false,
      throttled: null,
      isLocked: true
    };
  },

  template: require('../views/twitter-search-action.html'),

  methods: {
    /**
     * タブ切り替え
     *
     * "あなたのタイムライン"を選択した場合、デフォルトでホームタイムラインを取得する。
     * TwitterAPIv2対応によって"あなたのタイムライン"が廃止になったので呼ばれない
     * @param  {String} tab ['search' || 'yours']
     */
    changeTab (tab) {
      this.tab = tab;

      if (tab === 'yours') {
        this.search('home');
      } else {

        this.searchResult.items = [];
        this.page = 0;
        this.nextToken = null;
        this.index = 0;
        this.isLoading = false;
        this.isNoContent = false;
        this.isApiLimit = false;
        this.isError = false;
        this.isLocked = true;
      }
    },

    /**
     * ツイートを取得
     * @param  {String} timeline     [選択中のタイムライン]
     * @param  {String} keyword = '' [検索キーワード]
     * @param  {number} page = 1     [ページ番号]
     */
    search (timeline, keyword = '', page = 1) {
      if (this.tab === 'search' && !keyword) return;

      let 
        query = {},
        url;

      if (page === 1) {
        this.timeline = timeline;
        this.searchResult.items = [];
        this.keyword = keyword;
        this.isNoContent = false;
        this.isApiLimit = false;
        this.isError = false;
      }

      this.page = page + 1;
      this.isLocked = true;
      this.isLoading = true;

      switch (timeline) {
        // 検索タイムライン
        case 'public':
          if (page > 1) {
            url = `/matome/api/search-twitter?next_params=${this.nextToken}`;
            query.keyword = this.keyword;
            query.rt_flg = false;
          } else {
            url = '/matome/api/search-twitter';
            query.keyword = this.keyword;
            query.rt_flg = false;
          }

          break;

        // ホームタイムライン
        case 'home':
          url = '/matome/api/twitter-timeline';
          query.page = page;

          break;

        // 自分のツイート
        case 'user':
          url = '/matome/api/twitter-user-timeline';
          query.page = page;

          break;

        // favorites
        case 'favorites':
          url = '/matome/api/twitter-user-favorites';
          query.page = page;

          break;
      }

      this.$root.ajax({
        method: 'GET',
        url: url,
        params: query
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        if (response.status === 204) {
          // API limit
          this.isApiLimit = true;
          return;
        }

        // No content
        if (response.status === 200 && response.data.statuses.length === 0) {
          this.isNoContent = true;
          this.nextToken = '';
          return;
        }

        response.data.statuses.forEach(item => {
          item.showMedia = true;
          item.index = this.index;

          this.index += 1;
        });

        this.searchResult.items = this.searchResult.items.concat(response.data.statuses);

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);
        });

        if (response.data.search_metadata && response.data.search_metadata.next_token) {
          this.nextToken = response.data.search_metadata.next_token;
        } else {
          this.isNoContent = true;
        }

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError || this.isApiLimit) return;

            if (document.querySelector('.is-unselected .tweet-elements')) {
              if (document.querySelector('.is-unselected .tweet-elements').clientHeight - 100 < document.querySelector('.is-unselected .tweet-timeline-body').scrollTop + document.querySelector('.is-unselected .tweet-timeline-body').clientHeight) {
                this.isLoading = true;

                this.search(this.timeline, this.keyword, this.page);
              }
            }
          }, 500);

          // スクロールで次ページを取得
          document.querySelector('.is-unselected .tweet-timeline-body').onscroll = this.throttled;
        }
      }
      ).catch(error => {
        console.log(error);

         if (error.status === 401) {
          // 認証エラー
          this.isLoading = false;
          this.isLocked = false;
          this.isAuthenticationError = true;
          EventBus.$emit('resetTwitterAuthentication');
        } else {
          this.isLoading = false;
          this.isLocked = false;
          this.isError = true;
        }
      });
    },

    /**
     * ツイートを追加
     * @param  {number} index                                  [追加するツイートのインデックス]
     * @param  {number} preOrder = this.selectingTweets.length [並び順]
     */
    appendTweet (index, preOrder = this.selectingTweets.length) {
      let selectedTweet = this.searchResult.items[index];

      this.selectingTweets.splice(preOrder, 0, selectedTweet);
    },

    /**
     * 選択したツイートを記事に追加
     */
    appendSelectingTweets () {
      this.closeModal();
      EventBus.$emit('appendSelectingTweets', this.selectingTweets);
    },

    /**
     * ツイートの選択解除
     * @param  {String} id = null [対象のツイートの ID]
     */
    deselectTweets (id = null) {
      if (id !== null) {
        this.selectingTweets.forEach((tweet, index) => {
          if (id == tweet.id) {
            this.selectingTweets.splice(index, 1);

            document.getElementById('timeline-container')
            .querySelector(`[data-id="${id}"]`)
            .classList.remove('is-selected');
          }
        });
      } else {
        // 選択済み状態を解除
        [].forEach.call(
          document.getElementById('timeline-container')
          .getElementsByClassName('tweet-element'), tweet => {
            tweet.classList.remove('is-selected');
          }
        );

        this.selectingTweets = [];
      }
    },

    /**
     * 選択中のツイートのメディアの表示・非表示を切り替える
     */
    toggleShowMedia () {
      this.showMedia = !this.showMedia;

      this.selectingTweets.forEach(tweet => {
        tweet.showMedia = this.showMedia;
      });
    },

    /**
     * dragula の初期化
     */
    initDragula () {
      let
        self = this,
        from = null,
        id,
        key,
        // タイムラインエリア
        timeLineContainer = document.getElementById('timeline-container'),
        // 選択済みエリア
        selectedContainer = document.getElementById('selected-container');

      let drake = dragula([timeLineContainer, selectedContainer], {
        moves: (el, source, handle, sibling) => {
          // 非公開アカウントの場合
          if (el.classList.contains('is-protected')) {
            swal({
              title: '',
              text: 'このツイートは非公開です',
              confirmButtonColor: '#17A1E6',
              type: 'warning'
            });

            return false;
          }

          // 選択済みのツイートはドラッグさせない
          return !el.classList.contains('is-selected');
        },
        copy: (el, source) => {
          // タイムラインからドラッグされた場合はコピー（タイムラインからツイートを削除しない）
          return source === timeLineContainer;
        },
        accepts: (el, target) => {
          // タイムラインへのドロップは不許可
          return target !== timeLineContainer;
        }
      });

      drake.on('drag', (el, source) => {
        let index = [].indexOf.call(el.parentNode.children, el);
        from = index;
      }).on('drop', (el, target, source, sibling) => {
        if (target === selectedContainer) {
          if (source === timeLineContainer) {
            // タイムラインから選択済みエリアにドロップした場合ツイートを追加
            let
              index = [].indexOf.call(el.parentNode.children, el),
              preOrder = index,
              tweetIndex = el.getAttribute('data-index');

            id = el.getAttribute('data-id');

            timeLineContainer.querySelector(`[data-id="${id}"]`).classList.add('is-selected');

            this.appendTweet(tweetIndex, preOrder);
          } else {
            // 選択済みエリア内でドラッグされた場合ソート
            let index = [].indexOf.call(el.parentNode.children, el);

            self.selectingTweets.splice(index, 0, self.selectingTweets.splice(from, 1)[0]);

            const tmp = JSON.parse(JSON.stringify(self.selectingTweets));

            self.selectingTweets = [];

            this.$nextTick(() => {
              self.selectingTweets = tmp;
            });
          }

          if (selectedContainer.getElementsByClassName('to-delete')[0]) {
            // アイテム追加時に追加されるドラッグ元要素のコピーを削除
            selectedContainer.removeChild(selectedContainer.getElementsByClassName('to-delete')[0]);
          }
        }
      });
    },

    /**
     * モーダルを閉じる
     */
    closeModal () {
      this.$parent.closeModal();
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.initDragula();
    });
  }
});

module.exports = TwitterSearchAction;