/**
 * キャンペーンLP
 */

import Vue from 'vue';
import Super from '../utils/super.js';
import _ from 'lodash';
import Velocity from 'velocity-animate';
import userInfo from '../../stores/user-info.js';

module.exports = Super.extend({
  data () {
    return {
      isTruncate: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (typeof ga === 'function' && !this.userInfo.pageview_sent) userInfo.sendGaPageview();
  
      // ページ先頭に戻すボタン
      _.forEach(document.getElementsByClassName('_scroll-top'), el => {
        el.addEventListener('click', e => {
          Velocity(document.body, 'scroll', { duration: 200, offset: 0 });
        });
      });

      this.isRendered = true;
    });
  }
});
