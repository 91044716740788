'use strict';

import * as SentryBrowser from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vue from 'vue';
import UAParser from 'ua-parser-js';
import device from '../utils/device';

const ignoreErrors = [
  'null',
  'undefined',
  'SyntaxError',
  'SecurityError',
  'Script error.', // 外部起因
  'Object.defineProperty: invalid modification of non-configurable property', // IE11稀
  'The operation is insecure.', // Google Translate
  'NaverTranslator is not defined', // Naver Translator
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.', // safari auto play
  'ResizeObserver loop limit exceeded', // ResizeObserverの監視がループの最大まで進んでいるだけなので無視しても問題ないです
  /adblock/i, // AdBlock, fuckAdBlock
  'The operation was aborted.', // xhrキャンセル
  'The play() request was interrupted by a call to pause().', // Twitter埋め込み動画
  /^moz-extension:/,
  /^chrome:/,
  'ChunkLoadError: Loading chunk 692 failed.', // NewRelic起因、どうしようもないので追加
  'Unhandled Promise Rejection: Loading chunk 891 failed.', // NewRelic起因、どうしようもないので追加
  'Cannot redefine property: googletag', // ユーザー側ブラウザ拡張機能のアドブロッカー起因
  'Failed to fetch', // NewRelic起因、どうしようもないので追加
  'Load failed', // GA起因、どうしようもないので追加
  'NetworkError when attempting to fetch resource.',// Firefox特有らしい
  'Elasticsearch\Common\Exceptions\Serializer\JsonErrorException', // httpアクセスされた際に出る
];

const ignoreBrowsers = [
  'Focus', // firefox focus
  'Smooz',
  'UCBrowser',
  'Puffin',
  'QQBrowser',
  'MQQBrowser'
];

const capturedErrors = [];

export default class Sentry {
  constructor () {
    if (!this.isSupported()) {
      console.info('not supported');
      return;
    }

    SentryBrowser.init({
      dsn: process.env.DSN,
      debug: process.env.NODE_ENV !== 'production',
      release: process.env.RELEASE,
      environment: process.env.NODE_ENV,
      ignoreErrors: ignoreErrors,
      integrations: [new Integrations.Vue({ Vue })],
      // 許可するJSファイルのURL
      whitelistUrls: [/^https?:\/\/[a-z.]+dlsite\.com/],
      beforeSend(event, hint) {
        try {
          const { message } = hint.originalException;

          // 多重送信しない
          // if (capturedErrors.indexOf(message) !== -1) {
          //   return null;
          // }

          capturedErrors.push(message);

          return event;
        } catch (error) {
          // message が取れない事があるのでとりあえず event を返す
          return event;
        }
      },
      //sentryのパンくずに追加情報を付与
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'ui.click') {
          const { target } = hint.event;
          console.log(hint);
          if (target.textContent) {
            breadcrumb.message += ' (ボタン:' +target.textContent + ')';
          }
        }
        return breadcrumb;
      }
    });

    this.client = SentryBrowser;
  }

  getClient () {
    return this.client;
  }

  /**
   * Sentry送信条件
   * @returns {boolean}
   */
  isSupported () {
    const ua = navigator.userAgent;
    const uaParser = new UAParser();
    const os = uaParser.getOS();
    const browser = uaParser.getBrowser();
    const browserVersion = browser.major|0;

    // 外部サイト経由（翻訳等）
    if (!/^ch(\.[a-z]+)?\.dlsite\.com$/.test(location.hostname)) {
      return false;
    }

    // UAParserで判定不能なやつ
    if (ignoreBrowsers.some(name => ua.indexOf(name + '/') !== -1)) {
      return false;
    }

    if (device.isMobile()) {
      // iOS
      if (os.name === 'iOS') {
        if (browser.name === 'Mobile Safari') {
          return browserVersion >= 10;
        }
        else if (browser.name === 'Chrome') {
          return browserVersion >= 50;
        }
      }
      // Android
      else if (os.name === 'Android') {
        if (browser.name === 'Chrome') {
          return browserVersion >= 50;
        }
        else if (browser.name === 'Firefox') {
          return browserVersion >= 50;
        }
      }
    }
    // PC
    else {
      if (browser.name === 'Chrome') {
        return browserVersion >= 65;
      }
      else if (browser.name === 'Firefox') {
        return browserVersion >= 60;
      }
      else if (browser.name === 'Safari') {
        return browserVersion >= 10;
      }
      else if (browser.name === 'IE') {
        return browserVersion >= 11;
      }
      else if (browser.name === 'Edge') {
        return true;
      }
    }

    return false;
  }
}
