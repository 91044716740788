'use strict';

import UAParser from 'ua-parser-js';

// ブラウザ言語設定
const language = navigator.languages&&navigator.languages[0]||navigator.language||navigator.userLanguage;

export default class Device {

  /**
   * モバイル判定（タブレットは含まない）
   * @returns {boolean}
   */
  static isMobile () {
    return /^.*(iphone|android.*mobile|windows phone|iemobile|\(mobile;.*firefox|playstation vita|nintendo 3ds).*$/i.test(navigator.userAgent);
  }

  /**
   * Nest内部ブラウザ判定
   * @returns {boolean}
   */
  static isNest () {
    return navigator.userAgent.indexOf('DLsiteNest') !== -1;
  }

  /**
   * 中国語環境
   * @returns {boolean}
   */
  static isChinese () {
    return /zh/.test(language);
  }

  /**
   * 外国語環境
   * @returns {boolean}
   */
  static isForeign () {
    return ! /ja/.test(language);
  }

  /**
   * iOS
   * @returns {boolean}
   */
  static isIOS () {
    return new UAParser().getOS().name === 'iOS';
  }

  /**
   * Android
   * @returns {boolean}
   */
  static isAndroid () {
    return new UAParser().getOS().name === 'Android';
  }

}
