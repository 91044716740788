/**
 * ヘルプ
 */

import Vue from 'vue';
import Super from '../utils/super.js';

let Help = Super.extend({
  methods: {
  /**
   * ヘルプの開閉
   * @param  {Event} e [クリックイベント]
   */
    toggleState (e) {
      e.target.parentNode.classList.toggle('is-opened');
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isRendered = true;
    });
  }
});

module.exports = Help;
