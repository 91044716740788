/**
 * アンテナ: 動画登録画面
 */

import Vue from 'vue';
import _ from 'lodash';
import swal from 'sweetalert';
import HasModal from '../utils/has-modal.js';
import VideoAction from './components/video-action.js';
import EventBus from '../utils/event-bus';

let VideoStore = HasModal.extend({
  data () {
    return {
      playId: null,
      selectedVideo: {
        type: '',
        title: null,
        publishedAt: null,
        video_url: null,
        thumb_url: null,
        site: null,
        duration: null,
        classes: {
          'is-selected': false
        }
      },
      isSubmitting: false
    };
  },

  components: {
    'video-action-component': VideoAction
  },

  methods: {
    /**
     * 動画選択
     * @param  {Object} item [モーダルで選択したビデオ]
     */
    selectItem (item) {
      this.selectedVideo.title = item.title;
      this.selectedVideo.publishedAt = item.publishedAt;
      this.selectedVideo.video_url = item.url;
      this.selectedVideo.thumb_url = item.origin_url;
      this.selectedVideo.site = this.searchMode;
      this.selectedVideo.duration = item.duration;
      this.selectedVideo.owner = item.owner;

      this.selectedVideo.classes['is-selected'] = true;

      this.$children.forEach(child => {
        if (child.compName === 'videoAction') child.validateVideo();
      });
    },

    /**
     * 動画の種類選択
     * @param  {String} val [動画の種類]
     */
    selectVideoType (val) {
      this.selectedVideo.type = val;
    },

    /**
     * 動画登録
     */
    save () {
      this.isSubmitting = true;
      let video = this.selectedVideo;

      video.play_id = this.playId;
      video.profile = 1;
      delete video.classes;

      this.ajax({
        method: 'PUT',
        url: `/antenna/${this.playId}/videos/update`,
        data: video
      }, false)
      .then(response => {
        if (typeof ga === 'function') {
          this.sendGaEvent({
            eventCategory: 'AntennaProduct',
            eventAction: 'VideoEdit',
            eventLabel: `play/${this.playId}`,
            eventValue: 1
          });
        }

        location.reload();
      })
      .catch(error => {
        this.isSubmitting = false;
        this.selectedVideo.classes = { 'is-selected': true }

        this.setValidationResult(error.data);
      });
    },

    /**
     * 動画削除
     * @param  {Number} videoId [削除する動画 ID]
     */
    deleteVideo (videoId) {
      this.isSubmitting = true;

      swal({
        title: '削除の最終確認',
        text: '本当に削除しますか？' + "\n" + '一度削除すると元に戻すことは出来ません。',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#c00',
        cancelButtonText: 'キャンセル',
        allowOutsideClick: true,
        closeOnConfirm: true
      }, (isConfirm) => {
        if (isConfirm) {
          this.ajax({
            method: 'DELETE',
            url: `/antenna/${this.playId}/videos/delete/${videoId}/`,
            params: {
              profile: 1
            }
          }, true)
          .then(response => {
            location.reload();
          })
          .catch(error => {
            this.isSubmitting = false;
            this.setValidationResult(error.data);
          });
        } else {
          this.isSubmitting = false;
        }
      });
    },

    /**
     * submit 時のバリデーションの結果をセット
     * @param {Object} responseData [バリデーション結果]
     */
    setValidationResult (responseData) {
      this.serverErrors = [];

      Object.keys(responseData).forEach(index => {
        let
          prop = index,
          message = responseData[index][0],
          error = {};

        console.warn(`prop: ${prop}\nmessage: ${message}`);

        this.serverErrors.push(message);

        if (prop === 'type') {
          this.$children.forEach(child => {
            if (child.compName === 'video_action') {
              child.validation.type.isValid = false;
              child.validation.type.message = `※${message}`;
            }
          });
        }
      });

      let filteredErrors = this.serverErrors.filter((x, i, originalArray) => {
        return originalArray.indexOf(x) === i;
      });

      this.serverErrors = filteredErrors;
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.isRendered = true;

      EventBus.$on('selectVideoType', this.selectVideoType);
      EventBus.$on('save', this.save);
      EventBus.$on('selectItem', this.selectItem);
    });
  }
});

module.exports = VideoStore;