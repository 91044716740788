/**
 * クリエイター申請画面
 */

import Super from '../utils/super.js';

let Creators = Super.extend({
  data () {
    return {
      agree: false
    };
  },

  methods: {
    switch_agree() {
      this.agree = !this.agree;
    }
  }
});

module.exports = Creators;
