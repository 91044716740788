/**
 * モーダル設定
 */

import Vue from 'vue';
import Super from './super.js';
import Modal from './components/modal.js';
import EventBus from './event-bus.js';

let preScrollTop;

let HasModal = Super.extend({
  data () {
    return {
      hasModal: true,
      showModal: false,
      mediaType: '',
      searchMode: '',
      currentItem: null,
      hasDefault: false
    };
  },

  components: {
    'modal-component': Modal
  },

  methods: {
    changeSearchMode (searchMode) {
      this.searchMode = searchMode;
    },

    callModal (mediaType, searchMode, item) {
      let
        body = document.body,
        html = document.documentElement,
        scrollTop = body.scrollTop || html.scrollTop;

      this.mediaType = mediaType;
      this.searchMode = searchMode;
      this.currentItem = item;
      preScrollTop = scrollTop;

      if (searchMode === 'default') this.hasDefault = true;

      this.showModal = true;

      document.querySelector('body').classList.add('is-scroll-lock');
    },

    resetModal () {
      this.showModal = false;
      this.hasDefault = false;

      document.querySelector('html').classList.remove('is-scroll-lock');
      document.querySelector('body').classList.remove('is-scroll-lock');
      window.scrollTo(0, preScrollTop);
    }
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('changeSearchMode', this.changeSearchMode);
      EventBus.$on('callModal', this.callModal);
      EventBus.$on('resetModal', this.resetModal);
    })
  }
});

module.exports = HasModal;