/**
 * アンテナページ用ボタン
 */

import Vue from 'vue';

var ButtonUnit = Vue.extend({
  props: [
    'workNo',
    'siteId',
    'isAna',
    'isTrial',
    'isBought',
    'downloadUrl',
    'webSupported'
  ],

  computed: {
    /**
     * 販売中か予告か
     * @return  {String} ['announce' || 'work']
     */
    state () {
      return Number(this.isAna) === 1 ? 'announce' : 'work';
    },

    /**
     * ボタンのリンク先
     * @return  {String} url  [リンク先 URL]
     */
    url () {
      return `https://www.dlsite.com/${this.siteId}/${this.state}/=/product_id/${this.workNo}.html`;
    },

    /**
     * ボタン設定
     * @return  {object} [{
     *                    label: ボタンテキスト,
     *                    style: { className: boolean }
     *                  }]
     */
    buttonState () {
      let
        label = 'ショップで詳細を見る',
        style = {
          'btn-blue': true,
          'btn-cta': true
        };

      if (this.isTrial) {
        // 体験版
        label = 'ダウンロード'
        style = {
          'btn-green': true,
          'btn-action-install': true
        };
      }

      return { label: label, style: style };
    }
  },

  template: require('./views/button-action.html')
});

module.exports = ButtonUnit;