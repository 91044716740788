/**
 * ウォッチボタン
 */

import Vue from 'vue';
import EventBus from '../utils/event-bus';

var WatchUnit = Vue.extend({
  props: [
    'watchingId',
    'watchingCount',
    'targetContent',
    'targetService',
    'targetId',
    'targetCategory',
    'targetName',
    'isLogined',
    'showCount',
    'hasBody',
    'isGlobal',
    'isProfile'
  ],

  data () {
    return {
      currentWatchingId: null,
      currentWatchingCount: null,
      isAnimate: false,
      isActioned: false
    }
  },

  template: require('./views/watch-action.html'),

  methods: {
    /**
     * ウォッチ / ウォッチ取り消し
     * @param  {Event} e [クリックイベント]
     */
    watch (e) {
      // 未ログインならキャンセル
      if (!this.isLogined) {
        this.$root.loginCheck(this.isLogined, null, e);
        return;
      }

      if (!this.isAnimate) this.isAnimate = true;

      this.isActioned = !this.isActioned;

      // GA 設定
      let
        promise,
        eventLabel,
        eventValue,
        isTag = false,
        _eventCategory;

      switch (this.targetService) {
        case 'MatomeTag':
        case 'TalkTag':
        case 'AntennaTag':
        case 'ProfileTag':
          eventLabel = `tag/${this.targetName}`;
          isTag = true;
          break;

        case 'TalkThread':
        case 'ProfileThread':
          eventLabel = `talk/${this.targetId}`;
          break;

        case 'AntennaMaker':
        case 'ProfileMaker':
          eventLabel = `maker/${this.targetId}`;
          break;

        case 'MatomeArticle':
        case 'ProfileArticle':
          eventLabel = `matome/${this.targetId}`;
          break;

        case 'MatomeAuthor':
        case 'Profile':
        case 'ProfileFollow':
        case 'ProfileFollower':
          eventLabel = `profile/${this.targetId}`;
          break;

        case 'Magazine':
          eventLabel = `magazine/${this.targetId}`;
          break;

        default:
          break;
      }

      if (this.currentWatchingId) {
        promise = this.$root.ajax({
          method: 'DELETE',
          url: `/aspect/contents/${this.targetContent}/target/${this.targetId}/watching/${this.watchingId}`
        }, true)
        .then(response => {
          eventValue = -1;

          this.currentWatchingCount = response.data.watching_count;
          this.currentWatchingId = null;
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        promise = this.$root.ajax({
          method: 'POST',
          url: `/aspect/contents/${this.targetContent}/target/${this.targetId}/watching`
        }, true)
        .then(response => {
          eventValue = 1;

          this.currentWatchingCount = response.data.watching_count;
          this.currentWatchingId = response.data.watching_id;
        })
        .catch(error => {
          console.log(error);
        });
      }

      promise.then(() => {
        if (typeof ga === 'function') {
          this.$root.sendGaEvent({
            eventCategory: this.targetService,
            eventAction: 'Watch',
            eventLabel: eventLabel,
            eventValue: eventValue
          });

          this.$root.sendGaEvent({
            eventCategory: this.targetService,
            eventAction: 'Watch',
            eventLabel: location.href,
            eventValue: eventValue
          });

          if (!(isTag || this.targetService === 'AntennaMaker' || this.targetContent === 'user')) {
            this.$root.sendGaEvent({
              eventCategory: this.targetService,
              eventAction: 'Watch',
              eventLabel: `category/${this.targetCategory}`,
              eventValue: eventValue
            });
          }
        }
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.watchingId) this.isActioned = true;
    });
  },
  
  created () {
    this.currentWatchingId = this.watchingId;
    this.currentWatchingCount = this.watchingCount;
  }
});

module.exports = WatchUnit;
