import Vue from 'vue';
import ArticleAction from './article-action.js';

var ArticleEditor = Vue.extend({
  props: [
    'items',
    'isSubmitting',
    'isLoaded',
  ],

  data () {
    return {
      compName: 'articleEditor'
    };
  },

  template: require('../views/article-editor.html'),

  components: {
    'article-action-component': ArticleAction,
  }
});

module.exports = ArticleEditor;