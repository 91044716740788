/**
 * ユーザーアクティビティ
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import EventBus from '../../utils/event-bus';
import '../../utils/filters.js';
import '../../utils/service-survey.js';

let Timeline = Super.extend({
  props: [
    'timeline',
    'currentPage',
    'isLoading',
    'isNoContent',
    'isError',
    'isMypage'
  ],

  data () {
    return {
      content: ''
    }
  },

  methods: {
    selectTlContent (content = '') {
      this.content = content;
      EventBus.$emit('changeTlContent', content);
    },

    fetchTimeline (page = 1) {
      EventBus.$emit('fetchTimeline', page);
    }
  },

  template: require('../views/timeline.html')
});

module.exports = Timeline;