/**
 * マガジン編集画面
 */

import Vue from 'vue';
import _ from 'lodash';
import Velocity from 'velocity-animate';
import swal from 'sweetalert';
import objectFitImages from 'object-fit-images';
import jsonp from 'jsonp';
import HasModal from '../utils/has-modal.js';
import ArticleEditor from './components/article-editor.js';
import AddMatome from './components/add-matome.js';
import Validation from '../utils/validation.js';
import EventBus from '../utils/event-bus';

const validation = new Validation();
let initialData, loadedItems;
let domStorage;
const domStorageKey = 'magazineData';

try {
  domStorage = localStorage;
} catch (e) {
  domStorage = sessionStorage;
}

let MagazineStore = HasModal.extend({
  data () {
    return {
      magazineId: null,
      title: '',
      thumbImg: {
        id: null,
        url: null,
        isThumb: true,
        isChanged: false,
        source: {
          label: null,
          name: null,
          url: null
        }
      },
      ageCategory: null,
      description: '',
      body: null,
      items: [],
      publicFlg: 0,
      itemCount: 0,
      validation: {
        title: {
          isValid: true,
          message: ''
        },
        thumb: {
          isValid: true,
          message: ''
        },
        ageCategory: {
          isValid: true,
          message: ''
        },
        description: {
          isValid: true,
          message: ''
        },
        publicFlg: {
          isValid: true,
          message: ''
        }
      },
      isSubmitting: false,
      isLoaded: false,
      isNew: null,
      isReleased: null,
      titleCount: 0,
      descriptionCount: 0
    }
  },

  computed: {
    // 入力値判定
    isValid () {
      return this.validation.thumb.isValid
          && this.validation.title.isValid
          && this.validation.description.isValid
          && this.validation.ageCategory.isValid
          && this.serverErrors.length === 0;
    },

    /**
     * 成人向け作品を検索させるか
     * @return {Boolean} [成人向け作品検索許可設定]
     */
    isAdultSearchAllowed () {
      return this.ageCategory === 2;
    }
  },

  components: {
    'article-editor-component': ArticleEditor,
    'add-matome-component': AddMatome,
  },

  methods: {
    /**
     * 登録されているまとめ読み込み
     */
    initializeItem() {
      // セッションストレージから復元 or 復元無し
      this.fetchArticle();
    },
    
    /**
     * アイテム追加
     *
     * @param {String} item [追加するまとめ]
     */
    appendItem(item) {
      // 追加したまとめ情報をitemsに追加
      this.items.unshift(item);
      this.$nextTick(() => {
        // id="section-title"までスクロール
        let containerOffsetTop = document.getElementsByClassName('horizontal-container')[1].offsetTop;
        let offsetTop = document.getElementById(`section-title`).offsetTop;

        Velocity(document.body, 'scroll', {duration: 200, offset: offsetTop + containerOffsetTop - 20});
      });
    },

    /**
     * アイテム削除
     * @param  {number} index [削除するアイテムのインデックス]
     */
    removeItem(index) {
      // 削除したまとめ情報をitemsから削除
      this.items.splice(index, 1);

      if (domStorage) {
        domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
      }

    },

    /**
     * アイテム選択
     *
     * モーダルで選択したアイテムを記事に追加する。
     * @param  {object} item   [モーダルで選択したアイテム]
     */
    selectItem (item) {
      let matches;

      switch (this.mediaType) {
        case 'image':
          let data = {
            source: this.searchMode,
            url: item.origin
          };
          // 画像選択後に編集状態にしたいのでeditingItemIndex確保
          let itemIndex = this.editingItemIndex;

          // 出典元の利用規約に違反する画像が指定されている場合は拒否する
          if (this.$store.getters['restrictedDomains/isRestricted'](item.origin)){
            let imageValidation = this.currentItem.isThumb ? this.validation.thumb : this.currentItem._validation.image;
            imageValidation.isValid = false;
            imageValidation.message = '※出典元の利用規約に違反する可能性のある画像のため、再度選択し直してください。';
            break;
          }

          if (this.currentItem.isThumb) {
            this.validation.thumb.isValid = true;
            this.validation.thumb.message = '';
          } else {
            this.currentItem._validation.image.isValid = true;
            this.currentItem._validation.image.message = '';
          }

          console.log(item);

          matches = item.origin.match(/https?:\/\/([^\/]+)(\/.*)?/);

          this.currentItem.source.url = matches[0] || '';
          this.currentItem.source.label = matches[1] || '';

          if (this.currentItem.isThumb) {
            // サムネイルの場合
            this.currentItem.isChanged = true;

            // 画像 ID を発行
            this.ajax({
              method: 'POST',
              url: '/aspect/authed/img/image',
              params: data
            }, false)
                .then(response => {
                  this.currentItem.id = this.currentItem._id = null;
                  this.currentItem.url = this.currentItem._url = null;

                  setTimeout(() => {
                    this.$nextTick(() => {
                      console.log('set');
                      this.currentItem.id = this.currentItem._id = response.data.id;
                      this.currentItem.url = this.currentItem._url = response.data.url;
                      this.currentItem.source.name = matches[1];
                      this.currentItem._isSet = true;

                      if (domStorage) {
                        domStorage.setItem('articleData', JSON.stringify(this.$data));
                      }
                      this.objectFit();
                    });
                  }, 0);
                })
                .catch(error => {
                  console.log(error);

                  this.removeImage();
                  this.validation.thumb.isValid = false;
                  this.validation.thumb.message = '※この画像は使用できません。';
                });
          }
          break;
      }
    },

    /**
     * バリデーション: サムネイル
     */
    validateThumbImg() {
      this.validation.thumb.isValid = true;
      this.validation.thumb.message = '';

      if (!(this.thumbImg.id && this.thumbImg.url)) {
        this.validation.thumb.isValid = false;
        this.validation.thumb.message = '※サムネイル画像を選択してください。';

        return;
      }
    },

    /**
     * バリデーション: タイトル
     */
    validateTitle() {
      let _title = this.title.trim();
      this.validation.title.isValid = true;
      this.validation.title.message = '';

      if (_title.length === 0) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※タイトルの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_title, 50)) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※50字以内で入力してください。';

        return;
      }

      if (domStorage) {
        domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: 記事説明
     */
    validateDescription() {
      let _description = this.description.trim();
      this.validation.description.isValid = true;
      this.validation.description.message = '';

      if (_description.length === 0) {
        this.validation.description.isValid = false;
        this.validation.description.message = '※マガジンの説明の入力は必須です。';

        return;
      }

      if (!validation.maxSize(_description, 255)) {
        this.validation.description.isValid = false;
        this.validation.description.message = '※255字以内で入力してください。';

        return;
      }

      if (domStorage) {
        domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: 年齢区分
     */
    validateAgeCategory() {
      this.validation.ageCategory.isValid = true;
      this.validation.ageCategory.message = '';

      if (!this.ageCategory) {
        this.validation.ageCategory.isValid = false;
        this.validation.ageCategory.message = '※どちらかにチェックを入れてください。';

        return;
      }

      if (domStorage && this.isLoaded) {
        domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: アイテム個数
     */
    validateItemLength() {
      this.serverErrors = [];

      if (this.items.length > 50) {
        this.serverErrors.push({
          message: 'アイテムは50個以内で記事を作成してください。'
        });
      }
    },

    /**
     * バリデーション: アイテムの年齢制限
     */
    validationItemAgeCategory() {
      this.serverErrors = [];
      if(this.ageCategory == 1){
        let find = this.items.find(({age_category}) => age_category == 2);
        if(find){
          this.serverErrors.push({
            message: '成人向けの内容を含む記事が含まれています。'
          });
        }
      }
    },

    /**
     * 全項目をバリデート
     */
    validateAll() {
      this.validateThumbImg();
      this.validateTitle();
      this.validateDescription();
      this.validateAgeCategory();
      this.validateItemLength();
      this.validationItemAgeCategory();
    },

    /**
     * 画像削除
     * 利用できない画像だったりバリデーションエラーの際に削除する
     */
    removeImage() {
      this.thumbImg = {
        id: null,
        url: null,
        isThumb: true,
        isChanged: false,
        source: {
          label: null,
          name: null,
          url: null
        }
      };
      this.validation.thumb = {
        isValid: true,
        message: ''
      };

      if (domStorage) {
        domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
      }
    },

    /**
     * 保存
     */
    save() {

      // ブラウザ自動操作チェック
      this.checkBrowser(this.userInfo.profile_id, 'マガジン作成/編集');

      this.validateAll();

      this.$nextTick(() => {
        if (!this.isValid) {
          let offsetTop = document.querySelector('.form-error') ? document.querySelector('.form-error').offsetTop : null;

          if (offsetTop) Velocity(document.body, 'scroll', {duration: 200, offset: offsetTop});

          return;
        }
      });

      if (!this.isValid) return;

      this.isSubmitting = true;

      // 不要な項目の削除
      let itemsClone = _.cloneDeep(this.items);

      itemsClone.forEach(item => {
        Object.keys(item).forEach(key => {
          // 表示時のみ使用するプロパティを削除
          if (/\b_/.test(key)) {
            delete item[key];
          }
        });
      });

      this.body = JSON.stringify({items: itemsClone});

      let data = {
        magazine_id: this.magazineId,
        title: _.trim(this.title),
        thumb_id: this.thumbImg.id,
        age_category: this.ageCategory,
        description: _.trim(this.description),
        body: this.body,
        public_flg: this.publicFlg,
        profile: 1, // 使わなさそう
      };

      if (this.magazineId) {
        // 編集の場合
        this.ajax({
          method: 'PUT',
          url: `/magazine/api/putMagazineData/${this.magazineId}`,
          data: data
        }, false)
            .then(response => {
              domStorage.clear();

              if (this.publicFlg == 0) {
                // 下書きの場合
                this.isSubmitting = false;

                // ページ遷移時の比較用初期データを更新
                initialData = _.cloneDeep(this.$data);
                swal({
                  title: '非公開で保存しました',
                  type: 'success',
                  confirmButtonColor: '#17A1E6',
                });
              } else {
                if (typeof ga === 'function') {
                  this.sendGaEvent({
                    eventCategory: 'MagazineArticle',
                    eventAction: 'Edit',
                    eventLabel: `magazine/${this.magazineId}`,
                    eventValue: 1
                  });
                }

                window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
                location.href = `/magazine/${this.magazineId}`;
              }
            })
            .catch(error => {
              console.log(error);

              this.isSubmitting = false;

              if (error.status === 422) {
                this.setValidationResult(error.data);
              } else {
                let err = {
                  type: 'serverError',
                  id: `#`,
                  message: '保存に失敗しました。画面を更新してください。'
                };

                this.serverErrors.push(err);
              }
            });
      }
    },

    /**
     * submit 時のバリデーションの結果をセット
     * @param {object} responseData [バリデーション結果]
     */
    setValidationResult(responseData) {
      this.serverErrors = [];

      Object.keys(responseData).forEach(index => {
        let
            matches = index.match(/((\d+)-?)?([^\.]*)\.?(.*)/),
            message = responseData[index][0],
            error = {};

        if (matches[2]) {
          // 記事アイテムの場合
          let
              idx = ~~matches[2],
              type = matches[3],
              prop = matches[4];

          console.warn(`index: ${idx}\ntype: ${type}\nprop: ${prop}\nmessage: ${message}`);

          if (this.items[idx]._validation[prop]) {
            error = {
              index: idx,
              id: `#item-${idx}`,
              prop: prop,
              message: `${this.itemLabels[type]}(${idx + 1}) ${message}`
            };

            this.items[idx]._validation[prop].isValid = false;
            this.items[idx]._validation[prop].message = `※${message}`;
          } else {
            error = {
              index: idx,
              id: `#item-${idx}`,
              prop: prop,
              message: 'データが不正です。このアイテムを削除してください。'
            };
          }
        } else {
          // まとめ概要部分の場合
          let formName = matches[0].match(/([a-zA-Z]+)_?[a-zA-Z]*/)[1];

          console.warn(`formName: ${formName}\nmessage: ${message}`);

          error = {
            id: '#',
            prop: formName,
            message: message
          };

          if (this.validation[formName]) {
            this.validation[formName].isValid = false;
            this.validation[formName].message = `※${message}`;
          }
        }

        this.serverErrors.push(error);
      });
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler(e) {
      const message = 'このページから移動してもよろしいですか？';

      initialData.hasTwitterAuthentication = this.hasTwitterAuthentication;
      initialData.isLoaded = this.isLoaded;
      initialData.isNew = this.isNew;
      initialData.userInfo = this.userInfo;

      if (JSON.stringify(this.$data) !== JSON.stringify(initialData)) {
        if (domStorage) {
          domStorage.setItem(domStorageKey, JSON.stringify(this.$data));
        }

        e.returnValue = message;
        return message;
      }
    },

    /**
     * アイテムの初期化
     * @param  {Array} loadedItems [取得した記事アイテムの配列]
     */
    initializeItems(loadedItems) {
      loadedItems.forEach((item, index) => {
        // アイテム毎のデフォルトオブジェクト
        let itemTemplate = this.initializeItem(item.type);

        // デフォルトオブジェクトとマージ
        Object.keys(itemTemplate).forEach(prop => {
          if (itemTemplate.hasOwnProperty(prop) && !item.hasOwnProperty(prop)) {
            item[prop] = itemTemplate[prop];
          }
        });

        if (item.hasOwnProperty('_isSet')) {
          item._isSet = true;
        }
      });

      this.items = loadedItems;

      this.$nextTick(() => {
        this.items.forEach((item, index) => {
          // 編集内容の同期
          this.cancelItem(index);
        });
      });
    },

    /**
     * 記事情報の復元
     * @param  {object} articleData  [記事情報]
     * @param  {boolean} isSessioned [sessionStrage からの復元かどうか]
     */
    initializeArticle(articleData, isSessioned) {
      if (isSessioned) {
        // domStorage から復元した場合
        loadedItems = articleData.items;
        this.thumbImg = articleData.thumbImg;
        this.ageCategory = articleData.ageCategory || '1';
        this.publicFlg = articleData.publicFlg || '0';
      } else {
        // 記事編集の場合
        loadedItems = articleData.matome.items;
        this.thumbImg.isChanged = false;
        this.thumbImg.id = articleData.thumb_id;
        this.thumbImg.url = articleData.thumb_url;
        this.ageCategory = articleData.age_category;
        this.publicFlg = articleData.public_flg;
      }

      if (!this.magazineId) this.magazineId = articleData.magazineId;

      this.title = articleData.title;
      this.description = articleData.description;

      this.$nextTick(() => {
        this.objectFit();
        this.isLoaded = true;
        this.items = loadedItems;
      });
    },

    /**
     * セッションから記事情報を復元
     * @param  {String} storageData [記事情報]
     */
    restoreStorageData(storageData) {
      if (storageData) {
        if ((this.isNew && storageData.isNew)
            || (!this.isNew && !storageData.isNew && this.magazineId === storageData.magazineId)) {
          swal({
            title: '保存されなかった編集があります',
            text: '前回の状態を復元しますか？',
            type: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#17A1E6',
            cancelButtonText: 'キャンセル',
            showCancelButton: true
          }, isConfirm => {
            if (isConfirm) {
              this.initializeArticle(storageData, true);
            } else {
              if (this.isNew) {
                this.isLoaded = true;
              } else {
                this.fetchArticle();
              }

              domStorage.clear();
            }
          });
        } else {
          this.isLoaded = true;
        }
      } else {
        this.isLoaded = true;
      }
    },

    /**
     * マガジン内まとめ情報を取得
     */
    fetchArticle() {
      this.isLoaded = false;
      this.ajax({
        method: 'GET',
        url: `/magazine/api/getMagazineBody/${this.magazineId}`
      }, false)
          .then(response => {
            this.initializeArticle(response.data, false);
          })
          .catch(error => {
            console.log(error);

            this.isLoaded = true;

            if (error.status === 500) {
              let err = {
                type: 'serverError',
                id: `#`,
                message: '記事の取得に失敗しました。画面を更新してください。'
              };

              this.serverErrors.push(err);
            }
          });
    },

    /**
     * サムネイル選択時読み込み失敗
     */
    loadThumbFailed() {
      if (this.thumbImg.url) {
        this.removeImage();
        this.validation.thumb.isValid = false;
        this.validation.thumb.message = '※この画像は使用できません。';
      }
    },

    /**
     * object-fit polifyll
     */
    objectFit() {
      let images = document.querySelectorAll('.img-box > img');
      if (images.length > 0) objectFitImages(images);
    },
  },

  watch: {
    /**
     * 状態監視
     */
    isNew (n, o) {
      if (o === null) {
        this.isLoaded = false;

        let storageData = domStorage.getItem(domStorageKey);

        if (storageData) storageData = JSON.parse(storageData);

        if (storageData && this.magazineId == storageData.magazineId) {
          // 一時保存データが有る && 一時保存したものと同じ記事である
          this.restoreStorageData(storageData);
        } else if (this.magazineId) {
          this.fetchArticle();
        } else {
          this.isLoaded = true;
        }
      }
    },

    ageCategory () {
      this.validateAgeCategory();
    },

    isLoaded () {
      if (loadedItems) {
          this.$nextTick(() => {
            initialData = _.cloneDeep(this.$data);
            this.objectFit();
          });
      }
    },

    editingItemIndex () {
      this.objectFit();
    },

    /**
     * タイトル文字数カウント
     */
    title () {
      let _title = this.title.trim();
      this.titleCount = _title.length;
    },

    /**
     * 説明文文字数カウント
     */
    description () {
      let _description = this.description.trim();
      this.descriptionCount = _description.length;
    },

    /**
     * まとめの数カウント
     */
    items() {
      this.itemCount = this.items.length;
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.ageCategory = document.getElementById('g').disabled ? 1 : null;

      let self = this;
  
      // 初期状態の保存
      initialData = _.cloneDeep(self.$data);
  
      if (!self.isNest) window.addEventListener('beforeunload', self.onBeforeUnloadHandler);
  
      this.isRendered = true;

      EventBus.$on('appendItem', this.appendItem);
      EventBus.$on('removeItem', this.removeItem);
      EventBus.$on('selectItem', this.selectItem);
      EventBus.$on('fetchReference', this.fetchReference);
      EventBus.$on('loadImageFailed', this.loadImageFailed);
      EventBus.$on('referenceRestrictedImageError', this.referenceRestrictedImage);
    });
  },
});

module.exports = MagazineStore;