import Vue from 'vue';
import Vuex from 'vuex';
import RestrictedDomainStore from './restricted-domain';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    restrictedDomains: RestrictedDomainStore,
  },
})

export default store;