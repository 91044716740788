/**
 * LP
 */

import Vue from 'vue';
import Headroom from 'headroom.js';
import smoothScroll from 'smooth-scroll';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import _ from 'lodash';

let overlayPlayer = null;
let backgroundPlayer = null;

let Landing = Super.extend({
  data () {
    return {
      showOverlay: false,
      isMenuOpened: false,
      mobile: false
    }
  },

  methods: {
    /**
     * オーバーレイを閉じる
     */
    closeOverlay () {
      if (!this.isVideoReady()) return;
      overlayPlayer.stopVideo();
      this.showOverlay = false;
      if (!this.mobile) {
        setTimeout(() => backgroundPlayer.playVideo(), 400);
      }
    },

    /**
     * オーバーレイを表示
     */
    showVideo () {
      if (!this.isVideoReady()) return;
      this.showOverlay = true;
      if (!this.mobile) {
        backgroundPlayer.pauseVideo();
        overlayPlayer.playVideo();
      }
    },

    /**
     * youtube 動画の設定(背景)
     */
    setBackgroundPlayer () {
      backgroundPlayer = new YT.Player('bgVideo', {
        width: '480',
        height: '270',
        videoId: '-6Hx9d9nzcM',
        playerVars: {
          autoplay: this.mobile ? 0 : 1,
          controls: 0,
          loop: 1,
          playlist: '-6Hx9d9nzcM',
          rel: 0,
          showinfo: 0,
          wmode: 'transparent'
        },
        events: {
          'onReady' : e => {
            e.target.mute();
          }
        }
      });
    },

    /**
     * youtube 動画の設定(オーバーレイ)
     */
    setOverlayPlayer () {
      overlayPlayer = new YT.Player('olVideo', {
        width: '853',
        height: '480',
        videoId: 'Z2furY7_UkY'
      });
    },

    /**
     * スクロールイベント
     * @param {Event} e
     */
    onScroll (e) {
      if (!this.isVideoReady() || this.mobile) return;
      let h = window.innerHeight;
      let mov = document.querySelector('#movie');
      let rect = mov.getBoundingClientRect();
      if (h > rect.top && rect.bottom > 0) {
        backgroundPlayer.playVideo();
      } else {
        backgroundPlayer.pauseVideo();
      }
    },

    /**
     * youtube準備状態
     * @returns {boolean}
     */
    isVideoReady () {
      return (backgroundPlayer && backgroundPlayer.playVideo && overlayPlayer && overlayPlayer.playVideo);
    }

  },

  mounted () {
    this.$nextTick(() => {
      let ua = window.navigator.userAgent;
      this.mobile = /^.*(iPhone|Android.*Mobile|Windows Phone|IEMobile).*$/i.test(ua) || /^.*(iPad|Android.*(?!Mobile)).*$/i.test(ua);
  
      // youtube 動画の設定
      let _this = this;
  
      (function callee () {
        if (typeof YT !== 'undefined' && typeof YT.Player !== 'undefined') {
          _this.setBackgroundPlayer();
          _this.setOverlayPlayer();
        } else {
          setTimeout(callee, 100);
        }
      })();
  
      let header = document.getElementsByClassName('ct-nav')[0];
  
      let headroom = new Headroom(header, {
        offset: window.innerHeight - header.clientHeight,
        classes: {
          notTop: 'is-tracking'
        }
      });
  
      headroom.init();
  
      smoothScroll.init({
        selector: '[data-scroll]',
        speed: 500,
        easing: 'easeInOutCubic'
      });
  
      window.addEventListener('scroll', _.throttle(this.onScroll, 500), false);
  
      document.getElementsByClassName('ct-nav')[0].addEventListener('click', e => {
        this.isMenuOpened = false;
      });
  
      let images = document.querySelectorAll('.img-box > img');
      if (images.length > 0) objectFitImages(images);
  
      this.isRendered = true;
    });
  }
});

module.exports = Landing;