/**
 * ランキングページ
 */

import Super from '../utils/super.js';

let RankingTop = Super.extend({
  mounted () {
    this.$nextTick(() => {
      // 年齢認証表示判定
      if (this.userInfo.is_adult_mode && !this.userInfo.can_show_adult) {
        document.body.classList.add('is-scroll-lock');
        document.body.classList.add('adult-check');
      }
    });
  }
});

module.exports = RankingTop;