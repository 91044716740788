/**
 * 動画検索モーダル
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import EventBus from '../../utils/event-bus';

let VideoSearchAction = Vue.extend({
  props: ['searchMode', 'searchResult'],

  data () {
    return {
      keyword: '',
      page: 0,
      nextToken: '',
      isLoading: false,
      isNoContent: false,
      isError: false,
      throttled: null,
      isLocked: true
    }
  },

  template: require('../views/video-search-action.html'),

  methods: {
    // 検索対象切り替え
    changeSearchMode (searchMode) {
      EventBus.$emit('changeSearchMode', searchMode);

      // キーワードが入力されていれば検索
      this.$nextTick(() => {
        if (this.keyword) {
          this.search(this.keyword);
        } else {
          this.searchResult.items = [];
          this.page = 0;
          this.nextToken = '';
          this.isLoading = false;
          this.isNoContent = false;
          this.isError = false;
          this.isLocked = true;
        }
      });
    },

    search (keyword, page = 1) {
      this.isLocked = true;

      if (page === 1) {
        this.searchResult.items = [];
        this.keyword = keyword;
        this.nextToken = '';
        this.isError = false;
        this.isNoContent = false;
      }

      this.page = page + 1;
      this.isLoading = true;

      this.$root.ajax({
        method: 'GET',
        url: `/aspect/api/videos/search-${this.searchMode}`,
        params: {
          keyword: this.keyword,
          page: this.nextToken
        }
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;
        
        if (!response) return;

        if (response.status === 200 && response.data.items.length === 0) {
          this.isNoContent = true;
          return;
        }

        this.searchResult.items = this.searchResult.items.concat(response.data.items);

        if (this.searchMode === 'youtube') {
          if (response.data.nextToken) {
            this.nextToken = response.data.nextToken;
          } else {
            this.isNoContent = true;
            return;
          }
        } else {
          this.nextToken = this.page;
        }

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);
        });

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError) return;

            if (document.querySelector('.modal-body .video-elements')) {
              if (document.querySelector('.modal-body .video-elements').clientHeight - 100 < document.querySelector('.modal-body .modal-main-column').scrollTop + document.querySelector('.modal-body .modal-main-column').clientHeight) {
                this.isLoading = true;

                this.search(this.keyword, this.page);
              }
            }
          }, 500);

          document.querySelector('.modal-body .modal-main-column').onscroll = this.throttled;
        }
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    // アイテム選択
    selectItem (item) {
      EventBus.$emit('closeModal');
      EventBus.$emit('selectItem', item);
    },

    // モーダルクローズ
    closeModal () {
      EventBus.$emit('closeModal');
    }
  }
});

module.exports = VideoSearchAction;