import _ from 'lodash';

const state = {
  restrictedDomains: undefined,
};

const mutations = {
  // TODO: でかくなってきたらMutationTypesに切り出し
  setRestrictedDomains(state, urls) {
    if (!_.isUndefined(state.restrictedDomains)) {
      return; // すでに読み込まれている場合はなにもしない
    }
    state.restrictedDomains = urls;
  }
};

const getters = {
  isRestricted: state => url => {
    if (_.isEmpty(state.restrictedDomains)) {
      return false; // restrictedDomainsが空または未取得の場合はfalseを返す
    }
    return state.restrictedDomains.find(d => RegExp(d).test(url)) !== undefined;
  }
};

const RestrictedDomainStore = {
  namespaced: true,
  state,
  mutations,
  getters,
};

export default RestrictedDomainStore;