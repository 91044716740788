/**
 * 画像検索モーダル
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import EventBus from '../event-bus';

let ImageSearchAction = Vue.extend({
  props: ['searchMode', 'searchResult', 'hasDefault'],

  data () {
    return {
      keyword: '',
      page: 0,
      isLoading: false,
      isNoContent: false,
      isError: false,
      throttled: null,
      isLocked: true,
      noac: 0
    };
  },

  template: require('../views/image-search-action.html'),
  
  methods: {
    // 検索対象切り替え
    changeSearchMode (searchMode) {
      EventBus.$emit('changeSearchMode', searchMode);

      // キーワードが入力されていれば検索
      if (this.keyword || searchMode === 'default') {
        this.$nextTick(() => {
          this.search(this.keyword);
        });
      } else {
        this.searchResult.items = [];
        this.page = 0;
        this.isLoading = false;
        this.isNoContent = false;
        this.isError = false;
        this.isLocked = true;
        this.noac = 0;
      }
    },

    /**
     * 検索実行
     * @param  {String} keyword = '' [検索キーワード]
     * @param  {number} page    = 1  [ページ番号]
     */
    search (keyword = '', page = 1) {
      let
        query = {
          page: page,
          mode : this.searchMode
        },
        url = '/aspect/authed/img/search';

      this.isLocked = true;

      let matches = keyword.match(/^https?:\/\/.+/);

      if (matches) {
        // URL を直接入力された場合、モーダルを閉じて画像登録 API を実行
        this.isLocked = false;
        this.selectItem({
          isDirect: true,
          origin: matches[0]
        });

        return;
      }

      if (page === 1) {
        this.searchResult.items = [];
        this.keyword = keyword;
        this.isError = false;
        this.isNoContent = false;
      }

      this.page = page + 1;
      this.isLoading = true;

      query.keyword = this.keyword;

      if (this.searchMode === 'default') {
        delete query.mode;
      }

      this.$root.ajax({
        method: 'GET',
        url: url,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        if (!response) return;

        if (response.status === 204) {
          this.isNoContent = true;
          return;
        }

        this.searchResult.items = this.searchResult.items.concat(response.data);

        // デフォルトなら検索終了
        if (this.searchMode === 'default') this.isNoContent = true;

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);
        });

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError) return;

            if (document.querySelector('.modal-body .img-elements')) {
              if (document.querySelector('.modal-body .img-elements').clientHeight - 100 < document.querySelector('.modal-body .modal-main-column').scrollTop + document.querySelector('.modal-body .modal-main-column').clientHeight) {
                this.isLoading = true;

                this.search(this.keyword, this.page);
              }
            }
          }, 500);

          document.querySelector('.modal-body .modal-main-column').onscroll = this.throttled;
        }
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    // アイテム選択
    selectItem (item) {
      EventBus.$emit('closeModal');
      EventBus.$emit('selectItem', item);
    },

    // モーダルクローズ
    closeModal () {
      EventBus.$emit('closeModal');
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.searchMode === 'default') this.changeSearchMode('default');
    });
  }
});

module.exports = ImageSearchAction;
