/**
 * まとめ記事作成・編集画面
 */

import Vue from 'vue';
import _ from 'lodash';
import dragula from 'dragula';
import Velocity from 'velocity-animate';
import swal from 'sweetalert';
import objectFitImages from 'object-fit-images';
import jsonp from 'jsonp';
import HasModal from '../utils/has-modal.js';
import ArticleEditor from './components/article-editor.js';
import GlobalTagSelector from '../utils/global-tag-selector.js';
import TagSelector from '../utils/components/tag-selector.js';
import Validation from '../utils/validation.js';
import categories from '../utils/categories.json';
import EventBus from '../utils/event-bus';
import Sentry from '../../classes/sentry';

const sentry = new Sentry();
const client = sentry.getClient();

const validation = new Validation();
let initialData, loadedItems;
let domStorage;

try {
  domStorage = localStorage;
} catch (e) {
  domStorage = sessionStorage;
}

let MatomeStore = HasModal.extend({
  data () {
    return {
      matomeId: null,
      title: '',
      thumbImg: {
        id: null,
        url: null,
        isThumb: true,
        isChanged: false,
        source: {
          label: null,
          name: null,
          url: null
        }
      },
      category: {
        id: null,
        subCategoryId: null,
        name: 'カテゴリを選択',
        value: null,
        classes: {
          'is-opened': false
        }
      },
      ageCategory: null,
      selectedTags: [],
      tagJson: null,
      description: '',
      body: null,
      items: [],
      preFlg: 0,
      isCreator: 0,
      creatorStatus: null,
      hasTwitterAuthentication: false,
      draggingItem: {},
      editingItemIndex: null,
      isCampaign: false,
      campaignId: null,
      campaignStatus: 0,
      validation: {
        title: {
          isValid: true,
          message: ''
        },
        thumb: {
          isValid: true,
          message: ''
        },
        category: {
          isValid: true,
          message: ''
        },
        ageCategory: {
          isValid: true,
          message: ''
        },
        description: {
          isValid: true,
          message: ''
        }
      },
      isSubmitting: false,
      isLoaded: false,
      isNew: null,
      isDraft: 1,
      itemLabels: {
        'heading': '見出し',
        'subhead': '小見出し',
        'text': 'テキスト',
        'link': 'リンク',
        'image': '画像',
        'video': '動画',
        'reference': '引用',
        'twitter': 'ツイッター',
        'work': '作品',
        'talk': 'トーク'
      },
      talkData: {},
      workData: {},
      isReleased: null,
      titleCount: 0,
      descriptionCount: 0
    }
  },

  computed: {
    // 入力値判定
    isValid () {
      if (!(
        this.validation.thumb.isValid
        && this.validation.title.isValid
        && this.validation.description.isValid
        && this.validation.category.isValid
        && this.validation.ageCategory.isValid
        && this.serverErrors.length === 0)) {
        return false;
      }

      return true;
    },

    /**
     * 成人向け作品を検索させるか
     * @return {Boolean} [成人向け作品検索許可設定]
     */
    isAdultSearchAllowed () {
      return this.ageCategory == 2 ? true : false;
    }
  },

  components: {
    'article-editor-component': ArticleEditor,
    'global-tag-selector-component': GlobalTagSelector,
    'tag-selector-component': TagSelector
  },

  events: {
    lettersCountUpdated (comment, index) {
      this.letters[index] = comment;
    }
  },

  methods: {
    /**
     * アイテムの初期化
     * @param  {string} type            [アイテムの種類]
     * @return {object} item            [種類に応じたアイテムのオブジェクト]
     */
    initializeItem (type) {
      let item = {
        type: type,
        comment: '',
        _comment: '',
        _validation: {
          comment: {
            isValid: true,
            message: ''
          }
        }
      };

      switch (type) {
        // テキスト
        case 'text':
          item.rows = 5;
          break;

        // リンク
        case 'link':
          item.url = '';
          item.title = '';
          item.description = '';
          item.image = '';
          item.withoutImage = false;
          item._imageIndex = null;
          item._images = [];
          item._url = '';
          item._title = '';
          item._description = '';
          item._currentImageIndex = null;
          item._withoutImage = false;
          item._isLoading = false;
          item._isSet = false;
          item._validation.url = {
            isValid: true,
            message: ''
          };
          item._validation.title = {
            isValid: true,
            message: ''
          };
          item._validation.description = {
            isValid: true,
            message: ''
          };

          break;

        // 引用
        case 'reference':
          item.message = '';
          item.url = '';
          item.title = '';
          item._message = '';
          item._url = '';
          item._title = '';
          item._isLoading = false;
          item._validation.message = {
            isValid: true,
            message: ''
          };
          item._validation.url = {
            isValid: true,
            message: '',
            infomationMessage: ''
          };
          item._validation.title = {
            isValid: true,
            message: ''
          };

          break;

        // トーク
        case 'talk':
          item.id = null;
          item.title = '';
          item.thumbUrl = null;
          item.resCount = null;
          item.lastResDate = null;
          item.categoryLabel = null;
          item.categoryClass = null;
          item.workName = null;
          item.tags = [];
          item._isAlive = true;
          item._isSet = false;
          break;

        // 作品
        case 'work':
          item.id = null;
          item.workName = null;
          item.makerName = null;
          item.thumbUrl = null;
          item.introS = '';
          item.url = '',
          item.embedPlayer = null,
          item._classes = {
            'is-selected': false
          };
          item._isAlive = true;
          item._isSet = false;
          break;

        // 画像
        case 'image':
          item.url = null;
          item.title = '';
          item.description = '';
          item.source = {
            label: null,
            url: null
          };
          item.size = 'img-medium'; // デフォルト値
          item._title = '';
          item._description = '';
          item._size = 'img-medium'; // デフォルト値
          item._isSet = false;
          item._validation.title = {
            isValid: true,
            message: ''
          };
          item._validation.description = {
            isValid: true,
            message: ''
          };
          item._validation.image = {
            isValid: true,
            message: ''
          };
          item._validation.size = {
            isValid: true,
            message: ''
          };
          break;

        // 動画
        case 'video':
          item.title = '';
          item.url = null;
          item.pageUrl = null;
          item.thumbUrl = null;
          item.source = {
            label: null,
            url: null
          };
          item._title = '';
          item._isSet = false;
          item._validation.title = {
            isValid: true,
            message: ''
          };
          break;

        // twitter
        case 'twitter':
          item.url = null;
          item.id_str = null;
          item.text = null;
          item.createdAt = null;
          item.media = [];
          item.showMedia = true;
          item.name = null;
          item.screenName = null;
          item.profileImageUrl = null;
          item._isSet = false;

          if (!this.hasTwitterAuthentication) {
            this.AuthenticateTwitter();
          }

          break;
      }

      return item;
    },

    /**
     * カテゴリ選択ボックスの開閉
     */
    toggleSelectBox () {
      this.category.classes['is-opened'] = !this.category.classes['is-opened'];
    },

    /**
     * カテゴリ選択
     * @param  {number} categoryId    [カテゴリ ID]
     * @param  {number} subCategoryId [サブカテゴリ ID]
     * @param  {String} categoryName  [カテゴリ名]
     * @param  {String} categoryValue [カテゴリ値]
     */
    selectCategory (categoryId, subCategoryId, categoryName, categoryValue) {
      this.category.id = categoryId;
      this.category.subCategoryId = subCategoryId;
      this.category.name = categoryName;
      this.category.value = categoryValue;

      this.validateCategory();
    },

    /**
     * 新規タグの追加
     * @param  {object} newTag [新規タグ]
     */
    pushNewTag (newTag) {
      this.selectedTags.push(newTag);
    },

    /**
     * タグの同期
     * @param  {object} tag [選択中のタグ]
     */
    removeTag (tag) {
      let r = [];

      this.selectedTags.forEach(v => {
        if (v.name !== tag.name) r.push(v);
      })

      this.selectedTags = r;
    },

    /**
     * 編集開始
     *
     * アイテムを編集状態にする。
     * @param  {number} index [編集するアイテムのインデックス]
     */
    editStart (index) {
      this.editingItemIndex = index;
    },

    /**
     * アイテム追加
     *
     * 種類に応じてアイテムを追加する。
     * @param  {String} type                         [アイテムの種類]
     * @param  {number} preOrder = this.items.length [並び順]
     */
    appendNode (type, preOrder = this.items.length) {
      const
        beforeItemsLength = this.items.length,
        beforeEditingItemIndex = this.editingItemIndex;

      if (beforeEditingItemIndex !== null) {
        if (!this.checkEditingItem()) return;
      }

      if (this.items.length < beforeItemsLength && preOrder > beforeEditingItemIndex) {
        preOrder -= 1;
      }

      // 追加するアイテムのテンプレート
      let item = this.initializeItem(type);

      this.items.splice(preOrder, 0, item);
      this.draggingItem = {};
      this.editingItemIndex = preOrder;

      this.$nextTick(() => {
        let containerOffsetTop = document.getElementsByClassName('vertical-container')[0].offsetTop;
        let addingItem =  document.getElementById(`item-${preOrder}`);
        let offsetTop = addingItem.offsetTop;

        if (addingItem.querySelector('textarea')) {
          addingItem.querySelector('textarea').focus();
        } else if (addingItem.querySelector('input') && addingItem.querySelector('input').type === 'text') {
          addingItem.querySelector('input').focus();
        }

        Velocity(document.body, 'scroll', { duration: 200, offset: offsetTop + containerOffsetTop - 24 });
      });
    },

    /**
     * アイテム削除
     * @param  {number} index [削除するアイテムのインデックス]
     */
    removeNode (index) {
      this.items.splice(index, 1);
      this.editingItemIndex = null;

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * アイテム移動
     * @param  {number} index   [移動するアイテムのインデックス]
     * @param  {number} indexTo [移動先インデックス]
     */
    shiftItem (index, indexTo) {
      this.items.splice(indexTo, 0, this.items.splice(index, 1)[0]);
    },

    /**
     * 編集内容確定
     * @param  {number} editingItemKey [編集中のアイテムのキー]
     */
    fixItem (editingItemKey) {
      let editingItem = this.items[editingItemKey];

      editingItem.comment = editingItem._comment;

      switch (editingItem.type) {
        // リンク
        case 'link':
          editingItem.url = editingItem._url;
          editingItem.title = editingItem._title;
          editingItem.description = editingItem._description;
          editingItem.withoutImage = editingItem._withoutImage;

          if (editingItem._images.length > 0) {
            editingItem._imageIndex = editingItem._currentImageIndex;
            editingItem.image = editingItem._images[editingItem._imageIndex];
          }

          break;

        // 引用
        case 'reference':
          editingItem.message = editingItem._message;
          editingItem.url = editingItem._url;
          editingItem.title = editingItem._title;
          break;

        // 画像
        case 'image':
          editingItem.title = editingItem._title;
          editingItem.description = editingItem._description;
          editingItem.size = editingItem._size;
          break;

        // 動画
        case 'video':
          editingItem.title = editingItem._title;
          break;
      }

      this.editingItemIndex = null;

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * 編集キャンセル
     *
     * アイテムを編集前の状態に戻す
     * @param  {number} editingItemIndex [編集中のアイテムのインデックス]
     */
    cancelItem (editingItemIndex) {
      let editingItem = this.items[editingItemIndex];
      let removing = false;

      editingItem._comment = editingItem.comment;
      editingItem._validation.comment = {
        isValid: true,
        message: ''
      };

      switch (editingItem.type) {
        case 'heading':
        case 'subhead':
        case 'text':
          if (!editingItem._comment.trim().length) removing = true;

          break;

        // リンク
        case 'link':
          editingItem._url = editingItem.url;
          editingItem._title = editingItem.title;
          editingItem._description = editingItem.description;
          editingItem._withoutImage = editingItem.withoutImage;
          editingItem._validation.url = {
            isValid: true,
            message: ''
          };
          editingItem._validation.title = {
            isValid: true,
            message: ''
          };
          editingItem._validation.description = {
            isValid: true,
            message: ''
          };

          if (editingItem._images.length > 0) {
            editingItem._currentImageIndex = editingItem._imageIndex;
          }

          if (!editingItem._url.trim().length) removing = true;

          break;

        // 引用
        case 'reference':
          editingItem._message = editingItem.message;
          editingItem._url = editingItem.url;
          editingItem._title = editingItem.title;
          editingItem._validation.message = {
            isValid: true,
            message: ''
          };
          editingItem._validation.url = {
            isValid: true,
            message: ''
          };
          editingItem._validation.title = {
            isValid: true,
            message: ''
          };

          if (!editingItem._message.trim().length) removing = true;

          break;

        // 画像
        case 'image':
          editingItem._title = editingItem.title;
          editingItem._description = editingItem.description;
          editingItem._size = editingItem.size;
          editingItem._validation.title = {
            isValid: true,
            message: ''
          };
          editingItem._validation.description = {
            isValid: true,
            message: ''
          };
          editingItem._validation.image = {
            isValid: true,
            message: ''
          };
          editingItem._validation.size = {
            isValid: true,
            message: ''
          };

          if (!editingItem.url) removing = true;

          break;

        // 動画
        case 'video':
          editingItem._title = editingItem.title;
          editingItem._validation.title = {
            isValid: true,
            message: ''
          };

          if (!editingItem.url) removing = true;

          break;

        case 'work':
          if (!editingItem.id) removing = true;

          break;

        case 'talk':
          if (!editingItem.id) removing = true;

          break;


        case 'twitter':
          if (!editingItem.id_str) removing = true;

          break;
      }

      if (removing) this.removeNode(editingItemIndex);

      this.editingItemIndex = null;
    },

    /**
     * 編集中のアイテムの状態確認
     *
     * 編集中の内容をバリデートし、適切なら確定、不正ならエラー表示、入力がなければ削除する。
     * TODO: どうにかする
     * @return {boolean} canAppend [新規アイテムが追加可能かどうか]
     */
    checkEditingItem () {
      let
        editingItem = this.items[this.editingItemIndex],
        canAppend = true;

      if (editingItem) {
        editingItem._validation.comment.isValid = true;
        editingItem._validation.comment.message = '';

        switch (editingItem.type) {
          case 'heading':
          case 'subhead':
            if (editingItem._comment.trim().length > 100) {
              editingItem._validation.comment.isValid = false;
              editingItem._validation.comment.message = '※100字以内で入力してください。';

              canAppend = false;
            } else if (editingItem._comment.trim().length > 0) {
              this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'text':
            if (editingItem._comment.trim().length > 0) {
              this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'link':
            editingItem._validation.description.isValid = true;
            editingItem._validation.description.message = '';

            if (editingItem.url.length > 0) {
              if (editingItem._description.trim().length > 255) {
                // リンクの説明
                editingItem._validation.description.isValid = false;
                editingItem._validation.description.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (editingItem._comment.trim().length > 255) {
                // コメント
                editingItem._validation.comment.isValid = false;
                editingItem._validation.comment.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (canAppend) this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'reference':
            editingItem._validation.message.isValid = true;
            editingItem._validation.message.message = '';
            editingItem._validation.title.isValid = true;
            editingItem._validation.title.message = '';

            if (editingItem._message.trim().length > 0) {
              if (editingItem._message.trim().length > 1000) {
                // 引用
                editingItem._validation.message.isValid = false;
                editingItem._validation.message.message = '※1000字以内で入力してください。';

                canAppend = false;
              }

              if (editingItem._title.trim().length > 255) {
                // 引用
                editingItem._validation.title.isValid = false;
                editingItem._validation.title.message = '※255字以内で入力してください。';

                canAppend = false;
              } else if (editingItem._title.trim().length === 0) {
                editingItem._validation.title.isValid = false;
                editingItem._validation.title.message = '※引用の出典元の入力は必須です。';

                canAppend = false;
              }

              if (editingItem._comment.trim().length > 255) {
                // コメント
                editingItem._validation.comment.isValid = false;
                editingItem._validation.comment.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (canAppend) this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'image':
            if (editingItem._isSet) {
              editingItem._validation.title.isValid = true;
              editingItem._validation.title.message = '';
              editingItem._validation.description.isValid = true;
              editingItem._validation.description.message = '';

              if (editingItem._title.trim().length > 100) {
                // 画像のタイトル
                editingItem._validation.title.isValid = false;
                editingItem._validation.title.message = '※100字以内で入力してください。';

                canAppend = false;
              }

              if (editingItem._description.trim().length > 255) {
                // 画像の説明
                editingItem._validation.description.isValid = false;
                editingItem._validation.description.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (editingItem._comment.trim().length > 255) {
                // コメント
                editingItem._validation.comment.isValid = false;
                editingItem._validation.comment.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (canAppend) this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'video':
            if (editingItem._isSet) {
              editingItem._validation.title.isValid = true;
              editingItem._validation.title.message = '';

              if (editingItem._title.trim().length > 100) {
                // 動画のタイトル
                editingItem._validation.title.isValid = false;
                editingItem._validation.title.message = '※100字以内で入力してください。';

                canAppend = false;
              }

              if (editingItem._comment.trim().length > 255) {
                // コメント
                editingItem._validation.comment.isValid = false;
                editingItem._validation.comment.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (canAppend) this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
          case 'work':
          case 'talk':
          case 'twitter':
            if (editingItem._isSet) {
              if (editingItem._comment.trim().length > 255) {
                // コメント
                editingItem._validation.comment.isValid = false;
                editingItem._validation.comment.message = '※255字以内で入力してください。';

                canAppend = false;
              }

              if (canAppend) this.fixItem(this.editingItemIndex);
            } else {
              this.removeNode(this.editingItemIndex);
            }

            break;
        }
      }

      return canAppend;
    },

    /**
     * アイテム選択
     *
     * モーダルで選択したアイテムを記事に追加する。
     * @param  {object} item   [モーダルで選択したアイテム]
     */
    selectItem (item) {
      let matches;

      switch (this.mediaType) {
        case 'image':
          let data = {
            source: this.searchMode,
            url: item.origin
          };
          // 画像選択後に編集状態にしたいのでeditingItemIndex確保
          let itemIndex = this.editingItemIndex;

          // 出典元の利用規約に違反する画像が指定されている場合は拒否する
          if (this.$store.getters['restrictedDomains/isRestricted'](item.origin)){
            let imageValidation = this.currentItem.isThumb ? this.validation.thumb : this.currentItem._validation.image;
            imageValidation.isValid = false;
            imageValidation.message = '※出典元の利用規約に違反する可能性のある画像のため、再度選択し直してください。';
            break;
          }

          if (this.currentItem.isThumb) {
            this.validation.thumb.isValid = true;
            this.validation.thumb.message = '';
          } else {
            this.currentItem._validation.image.isValid = true;
            this.currentItem._validation.image.message = '';
          }

          console.log(item);

          matches = item.origin.match(/https?:\/\/([^\/]+)(\/.*)?/);

          this.currentItem.source.url = matches[0] || '';
          this.currentItem.source.label = matches[1] || '';

          if (this.currentItem.isThumb) {
            // サムネイルの場合
            this.currentItem.isChanged = true;

            // 画像 ID を発行
            this.ajax({
              method: 'POST',
              url: '/aspect/authed/img/image',
              params: data
            }, false)
            .then(response => {
              this.currentItem.id = this.currentItem._id = null;
              this.currentItem.url = this.currentItem._url = null;

              setTimeout(() => {
                this.$nextTick(() => {
                  console.log('set');
                  this.currentItem.id = this.currentItem._id = response.data.id;
                  this.currentItem.url = this.currentItem._url = response.data.url;
                  this.currentItem.source.name = matches[1];
                  this.currentItem._isSet = true;

                  if (domStorage) {
                    domStorage.setItem('articleData', JSON.stringify(this.$data));
                  }

                  this.$nextTick(() => {
                    this.objectFit();
                  });
                });
              }, 0);
            })
            .catch(error => {
              console.log(error);

              this.removeImage();
              this.validation.thumb.isValid = false;
              this.validation.thumb.message = '※この画像は使用できません。';
            });
          } else {
            // 画像アイテムの場合
            if (item.isDirect) {
              // URL を直接入力された場合
              this.ajax({
                method: 'POST',
                url: '/aspect/authed/img/image',
                params: data
              }, false)
              .then(response => {
                this.currentItem.url = null;

                this.$nextTick(() => {
                  this.currentItem.url = response.data.url;
                  this.currentItem._isSet = true;
                  this.editingItemIndex = null;
                  // 編集状態にする
                  this.editStart(itemIndex);
                });
              })
              .catch(error => {
                console.log(error);

                this.currentItem.source.url = null;
                this.currentItem.source.label = null;
                this.currentItem.url = null;
                this.currentItem._isSet = false;
                this.currentItem._validation.image.isValid = false;
                this.currentItem._validation.image.message = '※この画像は使用できません。';
              });
            } else {
              this.currentItem.url = item.cached;
              this.currentItem._isSet = true;
              this.editingItemIndex = null;
              // 編集状態にする
              this.editStart(itemIndex);
            }
          }

          break;

        case 'video':
          this.currentItem.title = this.currentItem._title = item.title;
          this.currentItem.url = item.url;
          this.currentItem.pageUrl = item.video_url;
          this.currentItem.thumbUrl = item.thumb;
          this.currentItem._isSet = true;

          matches = item.video_url.match(/https?:\/\/([^\/]+)\/.*/);

          this.currentItem.source.url = matches[0];
          this.currentItem.source.label = matches[1];
          this.editingItemIndex = null;

          break;

        case 'talk':
          this.talkData[item.id] = item;

          this.$nextTick(() => {
            this.currentItem.id = item.id;
            this.currentItem._isSet = true;
            this.editingItemIndex = null;

            this.$nextTick(() => {
              this.objectFit();
            });
          });

          break;

        case 'work':
          this.$set(this.workData, item.play_id, item);

          this.$nextTick(() => {
            if (item && item.workno) {
              // chobit に音声体験版があるか判定
              jsonp(
                '//chobit.cc/api/v1/dlsite/embed',
                {
                  param: `workno=${item.workno}&callback=cb_${Date.now()}`
                },
                (err, data) => {
                  if (err) {
                    console.error(err.message);
                  } else {
                    if (data && data.count > 0 && data.works) {
                      let work = data.works[0];

                      if (work.file_type === 'audio') {
                        item.embedPlayer = true;
                      }
                    }
                  }
                }
              );
            }

            this.currentItem.id = item.play_id;
            this.currentItem._classes['is-selected'] = true;
            this.currentItem._isSet = true;
            this.editingItemIndex = null;

          });

          break;

      }

      this.$nextTick(() => {
        if (domStorage && !this.currentItem.isThumb) {
          // 記事内のアイテムの場合、現在の状態を domStorage に保存
          domStorage.setItem('articleData', JSON.stringify(this.$data));
        }
      });
    },

    /**
     * バリデーション: サムネイル
     */
    validateThumbImg () {
      this.validation.thumb.isValid = true;
      this.validation.thumb.message = '';

      if (!(this.thumbImg.id && this.thumbImg.url)) {
        this.validation.thumb.isValid = false;
        this.validation.thumb.message = '※サムネイル画像を選択してください。';

        return;
      }
    },

    /**
     * バリデーション: タイトル
     */
    validateTitle () {
      let _title = this.title.trim();
      this.validation.title.isValid = true;
      this.validation.title.message = '';

      if (_title.length === 0) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※まとめ記事タイトルの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_title, 50)) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※50字以内で入力してください。';

        return;
      }

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: 記事説明
     */
    validateDescription () {
      let _description = this.description.trim();
      this.validation.description.isValid = true;
      this.validation.description.message = '';

      if (_description.length === 0) {
        this.validation.description.isValid = false;
        this.validation.description.message = '※まとめ記事の説明の入力は必須です。';

        return;
      }

      if (!validation.maxSize(_description, 255)) {
        this.validation.description.isValid = false;
        this.validation.description.message = '※255字以内で入力してください。';

        return;
      }

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: カテゴリ
     */
    validateCategory () {
      this.validation.category.isValid = true;
      this.validation.category.message = '';

      if (!this.category.subCategoryId) {
        this.validation.category.isValid = false;
        this.validation.category.message = '※カテゴリを選択してください。';

        return;
      }

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: 年齢区分
     */
    validateAgeCategory () {
      this.validation.ageCategory.isValid = true;
      this.validation.ageCategory.message = '';

      if (!this.ageCategory) {
        this.validation.ageCategory.isValid = false;
        this.validation.ageCategory.message = '※どちらかにチェックを入れてください。';

        return;
      }

      if (domStorage && this.isLoaded) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * バリデーション: アイテム個数
     */
    validateItemLength () {
      this.serverErrors = [];

      if (this.items.length < 3) {
        this.serverErrors.push({
          message: '3個以上のアイテムを追加してください。'
        });
      } else if (this.items.length > 500) {
        this.serverErrors.push({
          message: 'アイテムは500個以内で記事を作成してください。'
        });
      }
    },

    /**
     * タグ編集
     */
    updateTag () {
      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * 全項目をバリデート
     */
    validateAll () {
      this.validateThumbImg();
      this.validateTitle();
      this.validateDescription();
      this.validateCategory();
      this.validateAgeCategory();
      this.validateItemLength();
    },

    removeImage () {
      this.thumbImg = {
        id: null,
        url: null,
        isThumb: true,
        isChanged: false,
        source: {
          label: null,
          name: null,
          url: null
        }
      };
      this.validation.thumb = {
        isValid: true,
        message: ''
      };

      if (domStorage) {
        domStorage.setItem('articleData', JSON.stringify(this.$data));
      }
    },

    /**
     * 下書き保存時に警告を表示
     *
     * 編集かつ現在の編集で初めて下書き保存する場合、メッセージを表示する。
     * @param  {Number} preFlg    [下書きフラグ]
     * @param  {Number} isCreator [クリエイター記事フラグ]
     */
    showMessageBeforeSave (preFlg, isCreator) {
      this.checkEditingItem();
      this.validateAll();

      this.$nextTick(() => {
        let text = '公開中の記事を下書き保存した場合、非公開状態になります';

        if (!this.isValid) {
          let offsetTop = document.querySelector('.form-error') ? document.querySelector('.form-error').offsetTop : null;

          if (offsetTop) Velocity(document.body, 'scroll', { duration: 200, offset: offsetTop });

          return;
        }

        if (!this.isValid) return;

        // isCreator = true : クリエイターズ記事申請押下 / isCreator = false : 公開ボタン押下
        if (this.isReleased) {
          if (isCreator) {
            // まとめが公開済みの場合
            // このパターン存在しない
            text = '公開中の記事は申請できません';
          }
        } else {
          if (isCreator) {
            // まとめが未公開の場合
            text = '反映前にもう一度確認してください。<br>' +
                '・誤字脱字や不適切な表現がないか<br>' +
                '・指定タグの間違い（送り仮名、カナ違いなど）<br>' +
                '・規定に沿った内容か<br>' +
                '<b>※申請後の記事編集はお控えください。</b><br>' +
                '<b>※審査完了まで記事を公開できません。</b>';
          } else {
            // 初めて公開する場合
            if (this.userInfo.is_creator) {
              // クリエイター認証済みユーザ
              text = '反映前にもう一度確認してください。<br>' +
                  '・誤字脱字やタグの不備がないか<br>' +
                  '<b>※一度反映した記事はクリエイターズ記事に出来ません。</b>';
            } else {
              // 一般ユーザ
              text = '反映前にもう一度確認してください。<br>' +
                  '・誤字脱字やタグの不備がないか';
            }
          }
        }

        swal({
          title: '記事の公開',
          text: text,
          confirmButtonColor: '#17A1E6',
          type: 'info',
          showCancelButton: true,
          cancelButtonText: 'キャンセル',
          closeOnConfirm: true,
          html: true
        }, isConfirm => {
          if (isConfirm) {
            this.save(preFlg, isCreator)
          } else {
            return;
          }
        });
      });
    },

    /**
     * 保存
     * @param {Number} preFlg     [下書きフラグ]
     * @param {Number} isCreator [クリエイター記事フラグ]
     */
    save (preFlg, isCreator) {

      // ブラウザ自動操作チェック
      this.checkBrowser(this.userInfo.profile_id, 'まとめ作成/編集');

      this.preFlg = preFlg;

      if (!this.isCreator) this.isCreator = isCreator;

      this.checkEditingItem();
      this.validateAll();

      this.$nextTick(() => {
        if (!this.isValid) {
          let offsetTop = document.querySelector('.form-error') ? document.querySelector('.form-error').offsetTop : null;

          if (offsetTop) Velocity(document.body, 'scroll', { duration: 200, offset: offsetTop });

          return;
        }
      });

      if (!preFlg && this.isCreator && !this.userInfo.is_channel_admin) {
        // 審査中の記事は公開させない
        swal({
          title: '',
          text: '審査中の記事は公開できません',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: false,
          closeOnConfirm: true
        }, isConfirm => {
            return;
        });

        return;
      }

      if (!this.isValid) return;

      this.isSubmitting = true;
      this.tagJson = JSON.stringify({ tags: this.selectedTags });

      // 不要な項目の削除
      let itemsClone = _.cloneDeep(this.items);
      // 作品アイテムを含むか
      let hasWorkItem = false;

      itemsClone.forEach(item => {
        Object.keys(item).forEach(key => {
          if (item.type === 'work') hasWorkItem = true;

          // 表示時のみ使用するプロパティを削除
          if (/\b_/.test(key)) {
            delete item[key];
          }
        });
      });

      this.body = JSON.stringify({ items: itemsClone });

      // 文字数
      let letters = this.sumOfLetters();

      let data = {
        title: _.trim(this.title),
        thumb_id: this.thumbImg.id,
        category_id: this.category.id,
        sub_category_id: this.category.subCategoryId,
        age_category: this.ageCategory,
        description: _.trim(this.description),
        body: this.body,
        pre_flg: this.preFlg,
        profile: 1,
        letters: letters,
        is_good_matome: hasWorkItem && letters >= 300 ? true : false,
        is_incentive_matome: this.userInfo.is_creator && hasWorkItem // クリエイターズの承認が通っている かつ　作品を追加している場合のみtrue
      };

      // クリエイターズ
      if (isCreator) data.is_creator = this.isCreator;

      // キャンペーン
      if (this.isCampaign) {
        data.campaign = {
          campaign_id: this.campaignId,
          status: 1
        };
      } else {
        if (this.campaignId) {
          data.campaign = {
            campaign_id: this.campaignId,
            status: 0
          };
        }
      }

      data.tag_json = this.tagJson;

      if (this.matomeId) {
        // 編集の場合
        this.ajax({
          method: 'PUT',
          url: `/matome/api/matome/${this.matomeId}`,
          data: data
        }, false)
        .then(response => {
          domStorage.clear();

          if (this.preFlg) {
            // 下書きの場合
            this.isSubmitting = false;
            this.isDraft = 1;

            if (isCreator) this.creatorStatus = 2;

            // ページ遷移時の比較用初期データを更新
            initialData = _.cloneDeep(this.$data);

            swal({
              title: isCreator ? '申請しました' : '下書き保存しました',
              type: 'success',
              confirmButtonColor: '#17A1E6',
            });
          } else {
            if (typeof ga === 'function') {
              this.sendGaEvent({
                eventCategory: 'MatomeArticle',
                eventAction: 'Edit',
                eventLabel: `matome/${this.matomeId}`,
                eventValue: 1
              });
            }

            window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
            location.href = `/matome/${this.matomeId}`;
          }
        })
        .catch(error => {
          console.log(error);
          client.captureMessage("まとめの保存に失敗しました", 'error');

          this.isSubmitting = false;

          if (error.status === 422) {
            this.setValidationResult(error.data);
          } else {
            let err = {
              type: 'serverError',
              id: `#`,
              message: '保存に失敗しました。画面を更新してください。'
            };

            this.serverErrors.push(err);
          }
        });
      } else {
        // 新規作成の場合
        this.ajax({
          method: 'POST',
          url: '/matome/api/matome/create',
          data: data
        }, false)
        .then(response => {
          domStorage.clear();

          if (typeof ga === 'function') {
            this.sendGaEvent({
              eventCategory: 'MatomeArticle',
              eventAction: 'Create',
              eventLabel: `category/${this.category.value}`,
              eventValue: 1
            });
          }

          if (this.preFlg) {
            // 下書きの場合
            this.isSubmitting = false;
            this.isDraft = 1;
            this.matomeId = response.data.id;
            this.isNew = false;

            if (isCreator) this.creatorStatus = 2;

            // ページ遷移時の比較用初期データを更新
            initialData = _.cloneDeep(this.$data);

            swal({
              title: isCreator ? '申請しました' : '下書き保存しました',
              type: 'success',
              confirmButtonColor: '#17A1E6',
            });
          } else {
            window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
            location.href = `/matome/${response.data.id}`;
          }
        })
        .catch(error => {
          console.log(error);
          client.captureMessage("まとめの保存に失敗しました", 'error');

          this.isSubmitting = false;

          if (error.status === 422) {
            this.setValidationResult(error.data);
          } else {
            let err = {
              type: 'serverError',
              id: `#`,
              message: '保存に失敗しました。画面を更新してください。'
            };

            this.serverErrors.push(err);
          }
        });
      }
    },

    /**
     * submit 時のバリデーションの結果をセット
     * @param {object} responseData [バリデーション結果]
     */
    setValidationResult (responseData) {
      this.serverErrors = [];

      Object.keys(responseData).forEach(index => {
        let
          matches = index.match(/((\d+)-?)?([^\.]*)\.?(.*)/),
          message = responseData[index][0],
          error = {};

        if (matches[2]) {
          // 記事アイテムの場合
          let
            idx = ~~matches[2],
            type = matches[3],
            prop = matches[4];

          console.warn(`index: ${idx}\ntype: ${type}\nprop: ${prop}\nmessage: ${message}`);

          if (this.items[idx]._validation[prop]) {
            error = {
              index: idx,
              id: `#item-${idx}`,
              prop: prop,
              message: `${this.itemLabels[type]}(${idx + 1}) ${message}`
            };

            this.items[idx]._validation[prop].isValid = false;
            this.items[idx]._validation[prop].message = `※${message}`;
          } else {
            error = {
              index: idx,
              id: `#item-${idx}`,
              prop: prop,
              message: 'データが不正です。このアイテムを削除してください。'
            };
          }
        } else {
          // まとめ概要部分の場合
          let formName = matches[0].match(/([a-zA-Z]+)_?[a-zA-Z]*/)[1];

          console.warn(`formName: ${formName}\nmessage: ${message}`);

          error = {
            id: '#',
            prop: formName,
            message: message
          };

          if (this.validation[formName]) {
            this.validation[formName].isValid = false;
            this.validation[formName].message = `※${message}`;
          }
        }

        this.serverErrors.push(error);
      });
    },

    /**
     * アイテムメニューのスクロール設定
     */
    initMenuScrolling () {
      let
        body = document.body,
        html = document.documentElement,
        editableArea = document.getElementsByClassName('vertical-container')[0],
        editableTop = editableArea.offsetTop,
        menu = editableArea.getElementsByClassName('matome-edit-section')[0],
        footer = document.querySelector('footer');

      // アイテムメニューの追従
      window.onscroll = () => {
        let scrollTop = body.scrollTop || html.scrollTop;

        if (scrollTop > html.scrollHeight - (footer.clientHeight + 200 + 16 + menu.clientHeight)) {
          menu.classList.add('is-fixed');
          menu.classList.remove('is-tracking');
        } else if (scrollTop >= editableTop - 16) {
          menu.classList.add('is-tracking');
          menu.classList.remove('is-fixed');
        } else {
          menu.classList.remove('is-fixed');
          menu.classList.remove('is-tracking');
        }
      }
    },

    /**
     * dragula (アイテムの DnD に使用しているライブラリ)の初期化
     */
    initDragula () {
      let
        self = this,
        from = null,
        // 記事エリア
        itemsContainer = document.getElementById('items-container'),
        // メニューエリア
        menuContainer = document.getElementById('menu-container');

      dragula([itemsContainer, menuContainer], {
        copy: (el, source) => {
          // メニューからドラッグされた場合はコピー（メニューからアイテムを削除しない）
          return source === menuContainer;
        },
        accepts: (el, target) => {
          // メニューへのドロップは不許可
          return target !== menuContainer;
        },
        moves: (el, container, handle) => {
          return handle.classList.contains('handle');
        }
      }).on('drag', (el, source) => {
        let index = [].indexOf.call(el.parentNode.children, el);
        from = index;
      }).on('drop', (el, target, source, sibling) => {
        if (target === itemsContainer) {
          if (source === menuContainer) {
            // メニューから記事エリアにドラッグされた場合アイテムを追加
            let
              index = [].indexOf.call(el.parentNode.children, el),
              preOrder = index;

            self.appendNode(el.getAttribute('data-type'), preOrder);
          } else {
              // 記事エリア内でドラッグされた場合ソート
              let index = [].indexOf.call(el.parentNode.children, el);

              self.items.splice(index, 0, self.items.splice(from, 1)[0]);

              const tmp = JSON.parse(JSON.stringify(self.items));

              self.items = [];

              this.$nextTick(() => {
                self.items = tmp;
              });
          }

          if (itemsContainer.getElementsByClassName('to-delete')[0]) {
            // アイテム追加時に追加されるドラッグ元要素のコピーを削除
            itemsContainer.removeChild(document.getElementsByClassName('to-delete')[0]);
          }
        }
      });
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler (e) {
      const message = 'このページから移動してもよろしいですか？';

      initialData.hasTwitterAuthentication = this.hasTwitterAuthentication;
      initialData.isLoaded = this.isLoaded;
      initialData.isNew = this.isNew;
      initialData.isDraft = this.isDraft;
      initialData.userInfo = this.userInfo;

      if (JSON.stringify(this.$data) !== JSON.stringify(initialData)) {
        if (domStorage) {
          domStorage.setItem('articleData', JSON.stringify(this.$data));
        }

        e.returnValue = message;
        return message;
      }
    },

    /**
     * アイテムの初期化
     * @param  {Array} loadedItems [取得した記事アイテムの配列]
     */
    initializeItems (loadedItems) {
      loadedItems.forEach((item, index) => {
        // アイテム毎のデフォルトオブジェクト
        let itemTemplate = this.initializeItem(item.type);

        // デフォルトオブジェクトとマージ
        Object.keys(itemTemplate).forEach(prop => {
          if (itemTemplate.hasOwnProperty(prop) && !item.hasOwnProperty(prop)) {
            item[prop] = itemTemplate[prop];
          }
        });

        if (item.hasOwnProperty('_isSet')) {
          item._isSet = true;
        }
      });

      this.items = loadedItems;

      this.$nextTick(() => {
        this.items.forEach((item, index) => {
          // 編集内容の同期
          this.cancelItem(index);
        });
      });
    },

    /**
     * 記事情報の復元
     * @param  {object} articleData  [記事情報]
     * @param  {boolean} isSessioned [sessionStrage からの復元かどうか]
     */
    initializeArticle (articleData, isSessioned) {
      this.selectedTags = articleData.tags || articleData.selectedTags;

      //phpのjson_encodeの仕様で空配列の場合は配列で、空でない場合はオブジェクトでくるのでvue側で吸収する
      if (Object.keys(articleData.talkData).length >= 1) {
        this.talkData = articleData.talkData;
      } else {
        this.talkData = {};
      }

      if (Object.keys(articleData.workData).length >= 1) {
        this.workData = articleData.workData;
      } else {
        this.workData = {};
      }

      if (isSessioned) {
        // domStorage から復元した場合
        loadedItems = articleData.items;
        this.thumbImg = articleData.thumbImg;
        this.category = articleData.category;
        this.ageCategory = articleData.ageCategory || '1';
        this.isCreator = articleData.isCreator;
        this.creatorStatus = articleData.creatorStatus;
        this.isReleased = articleData.isReleased;

        if (articleData.campaignStatus != -1 && this.campaignId == articleData.campaignId) {
          this.isCampaign = articleData.isCampaign || false;
          this.campaignId = articleData.campaignId;
        }
      } else {
        // 記事編集の場合
        articleData = articleData.matome;
        loadedItems = JSON.parse(articleData.body).items;
        this.thumbImg.isChanged = false;
        this.thumbImg.id = articleData.thumb_id;
        this.thumbImg.url = articleData.thumb_url;

        this.ageCategory = articleData.age_category;
        this.category.id = articleData.category_id;
        this.category.subCategoryId = articleData.sub_category_id;
        this.category.name = document.getElementById('category_name').value;
        this.isCreator = articleData.creator_status === 2 ? 1 : 0;
        this.creatorStatus = articleData.creator_status;
        this.isReleased = articleData.released_at !== null;

        if (articleData.campaign && articleData.campaign.length) {
          // キャンペーンはひとつとする
          let campaign = articleData.campaign[0];

          if (campaign.status != -1 && this.campaignId == campaign.campaign_id) {
            this.isCampaign = campaign.status == 1 || false;
            this.campaignId = campaign.campaign_id;
          }
        }
      }

      if (!this.matomeId) this.matomeId = articleData.matomeId;

      this.title = articleData.title;
      this.description = articleData.description;

      this.$nextTick(() => {
        this.objectFit();
        this.isLoaded = true;
      });
    },

    /**
     * セッションから記事情報を復元
     * @param  {String} storageData [記事情報]
     */
    restoreStorageData (storageData) {
      if (storageData) {
        if ((this.isNew && storageData.isNew)
          || (!this.isNew && !storageData.isNew && this.matomeId == storageData.matomeId)) {
          swal({
            title: '保存されなかった編集があります',
            text: '前回の状態を復元しますか？',
            type: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#17A1E6',
            cancelButtonText: 'キャンセル',
            showCancelButton: true
          }, isConfirm => {
            if (isConfirm) {
              this.initializeArticle(storageData, true);
            } else {
              if (this.isNew) {
                this.isLoaded = true;
              } else {
                this.fetchArticle();
              }

              domStorage.clear();
            }
          });
        } else {
          this.isLoaded = true;
        }
      } else {
        this.isLoaded = true;
      }
    },

    /**
     * 記事情報を取得
     */
    fetchArticle () {
      this.ajax({
        method: 'GET',
        url: `/matome/open/api/matome-data/${this.matomeId}`
      }, false)
      .then(response => {
        this.initializeArticle(response.data, false);
      })
      .catch(error => {
        console.log(error);

        this.isLoaded = true;

        if (error.status === 500) {
          let err = {
            type: 'serverError',
            id: `#`,
            message: '記事の取得に失敗しました。画面を更新してください。'
          };

          this.serverErrors.push(err);
        }
      });
    },

    /**
     * chobit 音声体験版を取得
     */
    fetchChobitTrials () {
      this.items.forEach(item => {
        if (item.type === 'work' && this.workData[item.id] && this.workData[item.id].workno) {
          jsonp(
            '//chobit.cc/api/v1/dlsite/embed',
            {
              param: `workno=${this.workData[item.id].workno}&callback=cb_${Date.now()}`,
            },
            (err, data) => {
              if (err) {
                console.error(err.message);
              } else {
                console.log(data);

                if (data && data.count > 0 && data.works) {
                  let work = data.works[0];

                  if (work.file_type === 'audio') {
                    item.embedPlayer = true;
                  }
                }
              }
            }
          );
        }
      });
    },

    /**
     * サムネイル選択時読み込み失敗
     */
    loadThumbFailed () {
      if (this.thumbImg.url) {
        this.removeImage();
        this.validation.thumb.isValid = false;
        this.validation.thumb.message = '※この画像は使用できません。';
      }
    },

    /**
     * object-fit polifyll
     */
    objectFit () {
      let images = document.querySelectorAll('.img-box > img');
      if (images.length > 0) objectFitImages(images);
    },

    /**
     * コメント合計文字数を計算
     * @return {Number}
     */
    sumOfLetters () {
      let sum = 0;

      const space = '\\s|' //半角スペースなど
        + '[\\u2000-\\u200F]|'
        + '\\u00A0|'
        + '[\\u202A-\\u202E]|'
        + '\\u05c1|'
        + '\\u007F|'
        + '\\u0e3a|'
        + '\\u3164|'
        + '\\u2800|'
        + '\\uFFA0|'
        + '　'; //全角スペース

      this.items.forEach(item => {
        if (item.type === 'image') {
          sum += item._description.trim().length;
        }
        sum += item._comment.trim().replace(new RegExp(space, 'g'), '').length;
      });

      return sum;
    },

    /**
     * ツイッター認証
     */
    AuthenticateTwitter () {
      this.ajax({
        method: 'GET',
        url: '/api/member/info/'
      }, false)
      .then(response => {
        if (response.data.twitter_authed) {
          this.hasTwitterAuthentication = true;
        } else {
          this.hasTwitterAuthentication = false;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * ツイッター認証状態初期化
     */
    resetTwitterAuthentication () {
      this.hasTwitterAuthentication = false;
    },

    /**
     * ツイート追加
     *
     * モーダルで選択したツイートを記事に追加する
     * @param  {Array} tweets [選択したツイートの配列]
     */
    appendSelectingTweets (tweets) {
      let order = this.editingItemIndex;

      this.removeNode(order);

      tweets.forEach(tweet => {
        let item = {
          type: 'twitter',
          url: '',
          id_str: tweet.id,
          text: tweet.full_text || tweet.text,
          createdAt: tweet.created_at,
          media: [],
          showMedia: tweet.showMedia,
          name: tweet.user.name,
          screenName: tweet.user.username,
          profileImageUrl: tweet.user.profile_image_url,
          comment: '',
          _validation: {
            comment: {
              isValid: true,
              message: ''
            }
          },
          _comment: '',
          _classes: (tweet.media && tweet.media.length > 1)
            ? { 'square': true, 'cover': true }
            : { 'cover-static': true },
          _isSet: true
        };

        item.url = `https://twitter.com/${item.screenName}/status/${item.id_str}`;

        if (tweet.media && tweet.media.length > 0) {
          item.media = tweet.media.map(media => {
            if(media.type === 'photo'){
              return {
                type: 'image',
                url: media.url,
                media_url: media.url,
                video_url: ''
              };
            } else {
              return {
                type: 'video',
                url: media.url,
                media_url: media.preview_image_url,
                video_url: media.url
              };
            }
          });
        }

        this.items.splice(order++, 0, item);
      });

      this.editingItemIndex = null;
    },

    /**
     * リンク取得
     * @param  {object} fetched [リンクの取得結果]
     * @param  {object} item    [編集中のリンクアイテム]
     */
    fetchReference (fetched, item) {
      item._images = fetched.imgs;
      item._title = fetched.title;
      item._description = fetched.description;
      item._validation.title.isValid = true;
      item._validation.title.message = '';
      item._isSet = true;

      if (fetched.imgs.length > 0) {
        item._imageIndex = item._currentImageIndex = 0;
        item.image = item._images[0];
      } else {
        item.withoutImage = item._withoutImage = true;
      }
    },

    /**
     * リンクの画像切替
     * @param  {object} item      [リンクアイテムのオブジェクト]
     * @param  {number} indexDiff [前の画像の場合 -1, 次の画像の場合 1]
     */
    changeLinkImage (item, indexDiff) {
      item._withoutImage = false;
      item._currentImageIndex += indexDiff;
    },

    /**
     * 画像アイテム選択時読み込み失敗
     * @param  {Number} index [対象アイテムの index]
     */
    loadImageFailed (index) {
      // this.editingItemIndex = index;
      this.items[index].source.url = null;
      this.items[index].source.label = null;
      this.items[index].url = null;
      this.items[index]._isSet = false;
      this.items[index]._validation.image.isValid = false;
      this.items[index]._validation.image.message = '※この画像は使用できません。';
    },

    /**
     * 出典元利用規約違反の画像アイテム
     * @param  {Number} index [対象アイテムの index]
     */
    referenceRestrictedImage (index) {
      // this.editingItemIndex = index;
      this.items[index].source.url = null;
      this.items[index].source.label = null;
      this.items[index].url = null;
      this.items[index]._isSet = false;
      this.items[index]._validation.image.isValid = false;
      this.items[index]._validation.image.message = '※出典元の利用規約に違反する可能性のある画像のため、再度選択し直してください。';
    },
  },

  watch: {
    /**
     * まとめ記事状態監視
     */
    isNew (n, o) {
      if (o === null) {
        this.isLoaded = false;

        let storageData = domStorage.getItem('articleData');

        if (storageData) storageData = JSON.parse(storageData);

        if (this.title) {
          // タイトルがあれば優先
          storageData.title = this.title;
          // TODO: 開催中キャンペーン判定できないので日付で区切る。終わったら消す
          let startDate = new Date('2022-10-01 00:00:00');
          let endDate = new Date('2022-10-31 23:59:59');
          let nowDate = new Date();
          if(startDate.getTime() <= nowDate.getTime() && endDate.getTime() >= nowDate.getTime()) {
            storageData.campaignId = document.getElementById('campaign-id') ? document.getElementById('campaign-id').value : null;
            if(this.campaignId === null){
              this.campaignId = document.getElementById('campaign-id') ? document.getElementById('campaign-id').value : null;
            }
            storageData.campaignStatus = document.getElementById('is-campaign') ? document.getElementById('is-campaign').value : null;
            storageData.isCampaign = true;
          }
          // TODO: キャンペーン関連ここまで
          this.validateTitle();
          this.initializeArticle(storageData, true);
        } else if (storageData && this.matomeId == storageData.matomeId) {
          // 一時保存データが有る && 一時保存したものと同じ記事である
          this.restoreStorageData(storageData);
        } else if (this.matomeId) {
          this.fetchArticle();
        } else {
          this.isLoaded = true;
        }
      }
    },

    /**
     * 記事サムネイル ID の監視
     *
     * サムネイルが読み込まれたらアイテムメニューのスクロール設定を行う
     */
    'thumbImg.id' () {
      document.querySelector('.form-thumb img').onload = () => {
        this.initMenuScrolling();
      };
    },

    ageCategory () {
      this.validateAgeCategory();
    },

    isLoaded () {
      if (loadedItems) {
        this.initializeItems(loadedItems);

        this.$nextTick(() => {
          this.fetchChobitTrials();

          this.$nextTick(() => {
            initialData = _.cloneDeep(this.$data);
            this.objectFit();
          });
        });
      }
    },

    editingItemIndex () {
      this.objectFit();
    },

    /**
     * まとめ記事のタイトルの入力文字数を表示するためカウント
     */
    title () {
      let _title = this.title.trim();
      this.titleCount = _title.length;
    },

    /**
     * まとめ記事の説明の入力文字数を表示するためカウント
     */
    description () {
      let _description = this.description.trim();
      this.descriptionCount = _description.length;
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.ageCategory = document.getElementById('g').disabled ? 1 : null;
      this.campaignId = document.getElementById('campaign-id') ? document.getElementById('campaign-id').value : null;

      let self = this;

      // 初期状態の保存
      initialData = _.cloneDeep(self.$data);

      // カテゴリの開閉
      self.category.classes['is-opened'] = false;
        document.body.addEventListener('click', () => {
      });

      // ツイッター認証確認
      window.addEventListener('focus', () => {
        if (!self.hasTwitterAuthentication) {
          this.AuthenticateTwitter();
        }
      });

      if (!self.isNest) window.addEventListener('beforeunload', self.onBeforeUnloadHandler);

      // twitterの 認証を確認
      this.AuthenticateTwitter();
      // メニューの追従
      self.initMenuScrolling();
      // dragula の設定
      self.initDragula();

      this.isRendered = true;

      EventBus.$on('updateTag', this.updateTag);
      EventBus.$on('pushNewTag', this.pushNewTag);
      EventBus.$on('removeTag', this.removeTag);
      EventBus.$on('editStart', this.editStart);
      EventBus.$on('appendNode', this.appendNode);
      EventBus.$on('removeNode', this.removeNode);
      EventBus.$on('shiftItem', this.shiftItem);
      EventBus.$on('fixItem', this.fixItem);
      EventBus.$on('cancelItem', this.cancelItem);
      EventBus.$on('selectItem', this.selectItem);
      EventBus.$on('resetTwitterAuthentication', this.resetTwitterAuthentication);
      EventBus.$on('appendSelectingTweets', this.appendSelectingTweets);
      EventBus.$on('fetchReference', this.fetchReference);
      EventBus.$on('changeLinkImage', this.changeLinkImage);
      EventBus.$on('loadImageFailed', this.loadImageFailed);
      EventBus.$on('referenceRestrictedImageError', this.referenceRestrictedImage);
    });
  },

  created (){
    // タイトル取得
    // URLからタイトル取得
    let params = new URLSearchParams(location.search);
    let title = params.get('title');
    if(title){
      this.title = title;
    }
  }
});

module.exports = MatomeStore;
