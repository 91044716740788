/*
 * まとめ記事コメント
 */

import Vue from 'vue';
import Velocity from 'velocity-animate';
import swal from 'sweetalert';
import Super from '../../utils/super.js';
import VoteUnit from '../../utils/vote-unit.js';
import Validation from '../../utils/validation.js';
import '../../utils/filters.js';

const validation = new Validation();

let Comments = Super.extend({
  props: ['matomeId', 'isMobile', 'matomeCategory'],

  data () {
    return {
      comments: [],
      total: 0,
      comment: '',
      currentPage: 0,
      headCommentNum: null,
      lastPage: 0,
      validation: {
        comment:{
          isValid: true,
          message: null
        }
      },
      dropdownOpenedId: null,
      isLoading: false,
      isSubmitting: false
    };
  },

  template: require('../views/comments.html'),

  components: {
    'vote-unit-component': VoteUnit
  },

  methods: {
    /**
     * バリデーション: コメント
     */
    validateComment () {
      this.validation.comment.isValid = true;
      this.validation.comment.message = null;

      if (this.comment.length === 0) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※コメントを入力してください。';

        return;
      }

      if (!validation.maxSize(this.comment, 1000)) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※1000字以内で入力してください。';

        return;
      }
    },

    /**
     * 投稿されたコメントを取得
     */
    fetchComments () {
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/matome/${this.matomeId}/comment`,
        params: {
          page: this.currentPage + 1
        }
      }, true)
      .then(response => {
        console.log(response);

        this.comments = this.comments.concat(response.data.data);
        this.total = response.data.total;
        this.headCommentNum = this.comments[0] ? this.comments[0].res_num : null;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page || 1;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
      });
    },

    /**
     * ページ表示時から投稿までに追加されたコメントを取得
     */
    fetchNewComments () {
      this.ajax({
        method: 'GET',
        url: `/matome/open/api/new-comments/${this.matomeId}/${this.headCommentNum}`
      }, true)
      .then(response => {
        console.log(response);

        this.comments = response.data.data.concat(this.comments);
        this.total = response.data.total;
        this.headCommentNum = this.comments[0].res_num;
        this.comment = '';
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * コメントを投稿
     * @param  {Event} e [クリックイベント]
     */
    postComment (e) {
      if (!this.userInfo.is_logined) {
        this.$root.loginCheck(this.userInfo.is_logined, null, e);
        return;
      }

      // ブラウザ自動操作チェック
      this.checkBrowser(this.userInfo.profile_id, 'まとめコメント投稿');

      this.validateComment();

      if (!this.validation.comment.isValid) return;

      this.isSubmitting = true;

      this.$nextTick(() => {
        this.ajax({
          method: 'POST',
          url: `/matome/${this.matomeId}/comment`,
          data: {
            body: this.comment,
            profile: 1
          }
        }, true)
        .then(response => {
          console.log(response);

          if (typeof ga === 'function') {
            this.sendGaEvent({
              eventCategory: 'MatomeArticle',
              eventAction: 'Comment',
              eventLabel: `matome/${this.matomeId}`,
              eventValue: 1
            });

            this.sendGaEvent({
              eventCategory: 'MatomeArticle',
              eventAction: 'Comment',
              eventLabel: `category/${this.matomeCategory}`,
              eventValue: 1
            });
          }

          this.isSubmitting = false;

          // 差分コメントを取得
          this.fetchNewComments();
        })
        .catch(error => {
          console.log(error);

          this.validation.comment.isValid = false;

          if (error.data.body) {
            this.validation.comment.message = `※ ${error.data.body[0]}`;
          } else if (error.data.profile) {
            this.validation.comment.message = `※ ${error.data.profile[0]}`;
          } else {
            this.validation.comment.message = '※ 投稿に失敗しました。画面を更新してください。';
          }

          this.isSubmitting = false;
        });
      });
    },

    /**
     * コメントを削除
     * @param  {Number} matomeId [まとめ ID]
     * @param  {Number} resId    [レス ID]
     * @param  {Number} index    [削除対象の comments 内の index]
     */
    deleteComment (matomeId, resId, index) {
      if (!this.userInfo.is_logined) {
        this.loginCheck(this.userInfo.is_logined, null, e);
        return;
      }

      swal({
        title: '',
        text: 'このコメントを削除しますか？\n削除すると再表示することはできません。',
        confirmButtonColor: '#c00',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'キャンセル',
      }, isConfirm => {
        if (isConfirm) {
          this.ajax({
            method: 'DELETE',
            url: `/matome/${this.matomeId}/comment/${resId}`
          }, true)
          .then(response => {
            console.log(response);

            if (typeof ga === 'function') {
              this.sendGaEvent({
                eventCategory: 'MatomeArticle',
                eventAction: 'Comment',
                eventLabel: `matome/${this.matomeId}`,
                eventValue: -1
              });

              this.sendGaEvent({
                eventCategory: 'MatomeArticle',
                eventAction: 'Comment',
                eventLabel: `category/${this.matomeCategory}`,
                eventValue: -1
              });
            }

            this.comments.splice([index], 1);
            --this.total;
          })
          .catch(error => {
            console.log(error);
          });
        }
      });
    },

    /**
     * 省略されたコメントを展開
     * @param  {Number} index [レス ID]
     */
    openLongComment (index) {
      let target = document.getElementById(index);
      let targetBody = target.querySelector('.response-body.is-truncate');

      if (targetBody) targetBody.classList.remove('is-truncate');
    },

    /**
     * コメントメニューをトグル
     * @param  {Number} resNum = null [対象のコメント番号]
     */
    toggleDropdownContainer (resId = null) {
      if (resId) {
        this.dropdownOpenedId = (this.dropdownOpenedId === resId) ? null : resId;
      } else {
        this.dropdownOpenedId = null;
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.fetchComments();
  
      document.body.addEventListener('click', () => {
        this.toggleDropdownContainer(null);
      });
    });
  }
});

module.exports = Comments;