/**
 * モーダル基礎
 */

import Vue from 'vue';
import _ from 'lodash';
import ImageSearchAction from './image-search-action.js';
import VideoSearchAction from './video-search-action.js';
import TwitterSearchAction from './twitter-search-action.js';
import TalkSearchAction from './talk-search-action.js';
import WorkSearchAction from './work-search-action.js';
import IconUploadAction from './icon-upload-action.js';
import EventBus from '../../utils/event-bus';

let Modal = Vue.extend({
  props: [
    'showModal',
    'mediaType',
    'searchMode',
    'currentItem',
    'hasDefault'
  ],

  data () {
    return {
      keyword: '',
      searchResult: {
        items: []
      }
    };
  },

  template: require('../views/modal.html'),

  components: {
    'image-search-action-component': ImageSearchAction,
    'video-search-action-component': VideoSearchAction,
    'twitter-search-action-component': TwitterSearchAction,
    'talk-search-action-component': TalkSearchAction,
    'work-search-action-component': WorkSearchAction,
    'icon-upload-action-component': IconUploadAction
  },

  watch: {
    showModal () {
      if (this.showModal) {
        document.body.addEventListener('keyup', this.onEscKeyupHandler);
      } else {
        document.body.removeEventListener('keyup', this.onEscKeyupHandler);
      }
    }
  },

  methods: {
    /**
     * モーダルを閉じる
     */
    closeModal () {
      EventBus.$emit('resetModal');

      this.keyword = '';
      this.searchResult = {
        items: []
      };
    },

    /**
     * エスケープキー押下イベント
     *
     * エスケープキーでモーダルを閉じる
     * @param  {Event} e [keyup イベント]
     */
    onEscKeyupHandler (e) {
      if (e.keyCode === 27) this.closeModal();
    }
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('closeModal', this.closeModal);
    });
  }
});

module.exports = Modal;
