/**
 * いいねボタン
 */

import Vue from 'vue';
import EventBus from '../utils/event-bus';

var VoteUnit = Vue.extend({
  props: [
    'voteId',
    'voteCount',
    'targetContent',
    'targetService',
    'targetId',
    'targetCategory',
    'targetThreadId',
    'profileId',
    'isLogined',
    'hasForcedState',
    'forcedVoteId',
    'isForcedAnimate',
    'isForcedActioned',
    'forcedVoteCount',
    'isGraphical'
  ],

  data () {
    return {
      currentVoteId: null,
      currentVoteCount: null,
      isAnimate: false,
      isActioned: false
    }
  },

  template: require('./views/vote-action.html'),

  methods: {
    /**
     * いいね / いいね取り消し
     * @param  {Event} e [クリックイベント]
     */
    vote (e) {
      // 未ログインならキャンセル
      if (!this.isLogined) {
        this.$root.loginCheck(this.isLogined, null, e);
        return;
      }
      
      // ブラウザ自動操作チェック
      this.$root.checkBrowser(this.$root.userInfo.profile_id, 'いいね');

      if (!this.isAnimate) this.isAnimate = true;

      // いいね済表示切り替え
      this.isActioned = this.hasForcedState ? !this.isForcedActioned : !this.isActioned;

      // GA 設定
      let
        promise,
        eventLabel,
        eventValue,
        _eventCategory,
        _eventLabel;

      if (this.targetService === 'MatomeArticle') {
        eventLabel = `matome/${this.targetId}`;

        _eventCategory = 'matome';
        _eventLabel = 'matome_id';
      } else if (this.targetService === 'MatomeComment') {
        eventLabel = `comment/${this.targetId}`;

        _eventCategory = 'matome';
        _eventLabel = 'matome_id';
      } else if (this.targetService === 'TalkComment') {
        eventLabel = `res/${this.targetId}`;

        _eventCategory = 'talk';
        _eventLabel = 'talk_id';
      } else if (this.targetService === 'AntennaProduct') {
        eventLabel = `play/${this.targetId}`;

        _eventCategory = 'antenna';
        _eventLabel = 'play_id';
      }

      if ((!this.hasForcedState && this.currentVoteId )|| (this.hasForcedState && this.forcedVoteId)) {
        let currentVoteId = this.hasForcedState ? this.forcedVoteId : this.currentVoteId;

        promise = this.$root.ajax({
          method: 'DELETE',
          url: `/aspect/contents/${this.targetContent}/target/${this.targetId}/vote/${currentVoteId}`
        }, true)
        .then(response => {
          eventValue = -1;

          this.currentVoteCount = response.data.vote_count;
          this.currentVoteId = null

          if (this.hasForcedState) {
            EventBus.$emit('updateForcedState', 'forcedVoteCount', response.data.vote_count);
            EventBus.$emit('updateForcedState', 'forcedVoteId', null);
            EventBus.$emit('updateForcedState', 'isForcedAnimate', this.isAnimate);
            EventBus.$emit('updateForcedState', 'isForcedActioned', this.isActioned);
          }
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        promise = this.$root.ajax({
          method: 'POST',
          url: `/aspect/contents/${this.targetContent}/target/${this.targetId}/vote`
        }, true)
        .then(response => {
          eventValue = 1;

          this.currentVoteCount = response.data.vote_count;
          this.currentVoteId = response.data.vote_id;
          
          if (this.hasForcedState) {
            EventBus.$emit('updateForcedState', 'forcedVoteCount', response.data.vote_count);
            EventBus.$emit('updateForcedState', 'forcedVoteId', response.data.vote_id);
            EventBus.$emit('updateForcedState', 'isForcedAnimate', this.isAnimate);
            EventBus.$emit('updateForcedState', 'isForcedActioned', this.isActioned);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }

      promise.then(() => {
        if (typeof ga === 'function') {
          if (this.targetService === 'MatomeArticle') {
            // まとめ記事のカテゴリについて
            this.$root.sendGaEvent({
              eventCategory: this.targetService,
              eventAction: 'Vote',
              eventLabel: `category/${this.targetCategory}`,
              eventValue: eventValue
            });
          }

          // 対象自体について
          this.$root.sendGaEvent({
            eventCategory: this.targetService,
            eventAction: 'Vote',
            eventLabel: eventLabel,
            eventValue: eventValue
          });

          if (this.profileId) {
            // 対象の投稿者について
            this.$root.sendGaEvent({
              eventCategory: this.targetService,
              eventAction: 'Vote',
              eventLabel: `profile/${this.profileId}`,
              eventValue: eventValue
            });
          }
        }
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.voteId) this.isActioned = true;
  
      if (this.hasForcedState) {
        // 同一ページ内に同じコンテンツに対するわかるボタンが複数ある場合
        this.$nextTick(() => {
          EventBus.$emit('updateForcedState', 'forcedVoteCount', this.voteCount);
          EventBus.$emit('updateForcedState', 'forcedVoteId', this.voteId);
          EventBus.$emit('updateForcedState', 'isForcedAnimate', this.isAnimate);
          EventBus.$emit('updateForcedState', 'isForcedActioned', this.isActioned);
        });
      }
    })
  },

  created () {
    this.currentVoteId = this.voteId;
    this.currentVoteCount = this.voteCount;
  }
});

module.exports = VoteUnit;
