export default () => {
  return {
    musics_data: [],
    creators_data: {
      producers: [],
      scenario_writers: [],
      original_illustrators: [],
      character_designers: [],
      illustrators: [],
      authors: [],
      voice_actors: [],
      artists: [],
      musicians: [],
      directors: [],
      others: []
    }
  }
};