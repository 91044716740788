/*
 * まとめ記事閲覧画面
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import jsonp from 'jsonp';
import Super from '../utils/super.js';
import ShowArticle from './components/show-article.js';
import GlobalTagSelector from '../utils/global-tag-selector.js';
import VoteUnit from '../utils/vote-unit.js';
import WatchUnit from '../utils/watch-unit.js';
import Comments from './components/comments.js';
import EventBus from '../utils/event-bus';
import '../utils/service-survey.js';

let MatomeShow = Super.extend({
  data () {
    return {
      matomeId: null,
      title: null,
      categoryId: null,
      thumbId: null,
      comment: null,
      affiliateId: null,
      items: [],
      talkData: {},
      workData: {},
      plays: [],
      selectedTags: [],
      isDropdownOpened: false,
      isLoaded: false,
      hasForcedState: true,
      forcedVoteId: null,
      isForcedAnimate: false,
      isForcedActioned: false,
      forcedVoteCount: 0,
      trialsFetched: false,
    };
  },

  components: {
    'show-article-component': ShowArticle,
    'global-tag-selector-component': GlobalTagSelector,
    'vote-unit-component': VoteUnit,
    'watch-unit-component': WatchUnit,
    'comments-component': Comments
  },

  events: {
    /**
     * ログイン誘導ナビゲーション表示
     */
    navigationCalled (calledId) {
      this.showNavigation = true;
      this.calledId = calledId;
    }
  },

  watch: {
    matomeId () {
      this.ajax({
        method: 'GET',
        url: `/matome/open/api/matome-data/${this.matomeId}`
      }, false)
      .then(response => {
        this.items = JSON.parse(response.data.matome.body).items;
        this.talkData = response.data.talkData;
        this.workData = response.data.workData;
        this.plays = response.data.plays;

        this.$nextTick(() => {
          this.fetchChobitTrials();

          this.$nextTick(() => {
            let images = document.querySelectorAll('.img-box > img');
            if (images.length > 0) objectFitImages(images);

            this.isLoaded = true;
            this.isRendered = true;
          });
        });

      })
      .catch(error => {
        this.isLoaded = true;
      });
    },

    /**
     * chobit 音声体験版の取得完了
     * ブラウザバック時に iframe 内部がキャッシュされる対策
     */
    trialsFetched () {
      let itemIndex = 0;

      this.$nextTick(() => {
        this.items.forEach(item => {
          if (item.type === 'work') {
            let iframe = document.getElementById(`item-${itemIndex}`).querySelector('iframe');
  
            if (iframe) {
              // src を読込
              iframe.src = item.embedPlayer.src;
            }
          }
  
          ++itemIndex;
        });
      });
    }
  },

  methods: {
    /**
     * chobit 音声体験版を取得
     */
    fetchChobitTrials () {
      let itemIndex = 0;

      this.items.forEach(item => {
        if (item.type === 'work' && this.workData[item.id] && this.workData[item.id].workno) {
          jsonp(
            '//chobit.cc/api/v1/dlsite/embed',
            {
              param: `workno=${this.workData[item.id].workno}&callback=cb_${Date.now()}`
            },
            (err, data) => {
              if (err) {
                console.error(err.message);

                if (this.items.length === ++itemIndex) this.trialsFetched = true;
              } else {
                console.log(data);

                if (data && data.count > 0 && data.works) {
                  let work = data.works[0];
    
                  if (work.file_type === 'audio') {
                    this.$set(item, 'embedPlayer', {
                      src: work.embed_url,
                      view: `<div class="chobit_player"><iframe src="" height="${work.embed_height}" frameborder="0" allowfullscreen scrolling="no"></iframe></div>`,
                      isShown: false
                    });
                  }
                }
    
                if (this.items.length === ++itemIndex) this.trialsFetched = true;
              }
            }
          );
        } else {
          if (this.items.length === ++itemIndex) this.trialsFetched = true;
        }
      });
    },

    updateForcedState (prop, value) {
      this[prop] = value;
    },

    /**
     * 追従するTweetボタンの表示制御をする
     */
    floatTweet() {
      const targetElement = document.querySelector('.canopy-actions');
      // targetElement取得できなかったら中断
      if (!targetElement) {
        return;
      }
      const options = {
        root: null,
        rootMargin: "10px",
        threshold: 0
      };
      const observer = new IntersectionObserver(this.callback, options);
      observer.observe(targetElement);
    },
    callback(entries) {
      const target = document.querySelector('#_tweet')
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // scroll内に現れたis-show外す
          target.classList.remove('is-show');
        } else {
          // scroll外に行ったらis-show追加
          target.classList.add('is-show');
        }
      });
    },
  },

  created () {
    this.affiliateId = document.getElementById('affiliate_id').value;
    
    let self = this;

    document.body.addEventListener('click', () => {
      self.isDropdownOpened = false;
    });
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('updateForcedState', this.updateForcedState);
      if ('IntersectionObserver' in window) {
        // canopy-actions監視
        this.floatTweet();
      }
    });
    // Canonical追加
    this.addCanonical();
  }
});

module.exports = MatomeShow;
