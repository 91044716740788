/**
 * マガジン管理
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import WatchUnit from '../../utils/watch-unit.js';
import Pagination from '../../utils/components/pagination.js';
import '../../utils/filters.js';

let Magazines = Super.extend({
  props: [
    'magazines',
    'isLoading',
    'amount',
    'currentPage',
    'isMypage'
  ],

  data () {
    return {
      toggleButtonNo: null,
    }
  },

  template: require('../views/magazines.html'),

  components: {
    'watch-unit-component': WatchUnit,
    'pagination-component': Pagination
  },

  methods: {
    /**
     * マガジン削除
     * @param  {Number} magazineId [削除対象のマガジンID]
     */
    deleteMagazine (magazineId) {
      swal({
        title: '',
        text: '削除すると元には戻せません。よろしいですか？ ',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#c00',
        cancelButtonText: 'キャンセル'
      },
      isConfirm => {
        if (isConfirm) {

          this.ajax({
            method: 'DELETE',
            url: `/magazine/api/deleteMagazine/${magazineId}`
          }, true)
          .then(response => {
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
        }
      });
    },
    /**
     * メニュー格納ボタンにクラス付与する
     */
    toggleHideButton (magazineId) {
      if (this.toggleButtonNo === magazineId) {
        this.toggleButtonNo = null;
      } else {
        this.toggleButtonNo = magazineId;
      }
    },
    /**
     * その他メニューを格納しているポップアップを閉じる
     */
    closeOtherMenu (event) {
      if (!event.target.closest('[data-ref="otherMenu"]')) {
        this.toggleButtonNo = null;
      }
    },

    /**
     * マガジンをピン止め/解除する
     */
    pinMagazine (magazineId, mode) {
      let text_string = 'マガジンを先頭から固定解除しますか？\nこのマガジンは先頭に表示されなくなります。';
      let button_string = '固定を解除する';
      if(mode === 'pin'){
        text_string = 'マガジンを先頭に固定しますか？\nこのマガジンは先頭に表示されます。';
        button_string = '固定する';
      }
      
      swal({
        title: '',
        text: text_string,
        // type: 'warning',
        showCancelButton: true,
        confirmButtonText: button_string,
        confirmButtonColor: '#17A1E6',
        cancelButtonText: 'キャンセル'
      },
      isConfirm => {
        if (isConfirm) {
          this.ajax({
            method: 'POST',
            url: `/magazine/api/pinMagazine/${magazineId}?mode=${mode}`
          }, true)
            .then(response => {
              location.reload();
            })
            .catch(error => {
              console.log(error);
          });
        }
      });
    },

    /**
     * マガジンを作成する
     */
    createMagazine () {
      this.ajax({
        method: 'POST',
        url: `/magazine/api/createMagazine`
      }, true)
      .then(response => {
        location.reload();
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  mounted() {
    document.addEventListener('click', this.closeOtherMenu);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeOtherMenu);
  }
});

module.exports = Magazines;