/**
 * 年齢認証
 */

import Vue from 'vue';

let AgeVerification = Vue.extend({
  data () {
    return {
      adultchecked: false
    };
  },

  methods: {
    accept () {
      document.cookie = `adultchecked=1;domain=.dlsite.com;path=/;max-age=${60 * 60 * 24 * 365}`;
      this.adultchecked = true;

      document.querySelector('body').classList.remove('is-scroll-lock');
      document.querySelector('body').classList.remove('adult-check');
    }
  }
});

module.exports = AgeVerification;