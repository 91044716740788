import Vue from 'vue';
import ArticleAction from './article-action.js';
import ItemsMenu from './items-menu.js';

var ArticleEditor = Vue.extend({
  props: [
    'items',
    'talkData',
    'workData',
    'editingItemIndex',
    'hasTwitterAuthentication',
    'isSubmitting',
    'isCreator',
    'creatorStatus',
    'isReleased',
    'isLoaded',
    'sumOfLetters'
  ],

  data () {
    return {
      compName: 'articleEditor'
    };
  },

  template: require('../views/article-editor.html'),

  components: {
    'article-action-component': ArticleAction,
    'items-menu-component': ItemsMenu
  }
});

module.exports = ArticleEditor;