import Vue from 'vue';
import userInfo from '../../../stores/user-info.js';
import EventBus from '../../utils/event-bus';

var ItemsMenu = Vue.extend({
  props: ['isSubmitting', 'isCreator', 'creatorStatus', 'isReleased', 'isLoaded', 'sumOfLetters'],

  data () {
    return {
      userInfo: userInfo.state
    };
  },

  template: require('../views/items-menu.html'),

  methods: {
    // アイテムを追加
    appendNode (type) {
      EventBus.$emit('appendNode', type);
    },

    /**
     * 記事保存
     * @param  {Number} preFlg    [下書きフラグ]
     * @param  {Number} isCreator [クリエイター記事フラグ]
     */
    save (preFlg, isCreator) {
      // preFlg:0 / isCreator: 0 → 反映
      // preFlg:0 / isCreator: 1 → 存在しない
      // preFlg:1 / isCreator: 0 → 下書き
      // preFlg:1 / isCreator: 1 → クリエイター申請
      if (preFlg) {
        if (isCreator) {
          // クリエイター申請、確認メッセージ出す
          this.$root.showMessageBeforeSave(preFlg, isCreator);
        } else {
          // 下書き、確認メッセージ出さない
          this.$root.save(preFlg, isCreator);
        }
      } else {
        if(this.$root.isReleased){
          // 編集、確認メッセージ出さない
          this.$root.save(preFlg, isCreator);
        } else {
          // 反映、確認メッセージ出す
          this.$root.showMessageBeforeSave(preFlg, isCreator);
        }
      }
    }
  }
});

module.exports = ItemsMenu;