/** cookieを取得 */
function getCookie() {
  const cookies = document.cookie;
  if (!cookies) return false;

  // オブジェクトに変換して返却
  const _ary = cookies.split(";").map(function (cookie) {
    const _cookieKV = cookie.split("=");
    // 余分な半角スペースを削除(必要に応じてURLデコード)
    const _cookieObj = {};
    _cookieObj[_cookieKV[0].trim()] = _cookieKV[1].trim();
    return _cookieObj;
  });
  return _ary.reduce(function (a, b) {
    return Object.assign(a, b);
  }, {});
}

// cookie取得
const _cookies = getCookie();

function serviceSurveyPopup() {
  /** POPUP表示日時 */
  const displayTimestamp = new Date().toISOString();
  /** フロアTOPページPOPUP前回表示日時キー */
  const KEY_POPUP_WORK_LAST_TIME = "service_survey_popup_floor_top_last_time";
  /** 前回回答済みキー */
  const KEY_POPUP_LAST_ANSWERED = "service_survey_popup_last_answered";
  /** 回答累計回数キー */
  const KEY_TOTAL_COUNT_POPUP = "service_survey_popup_total_count";

  /** SP判定 */
  function isMobile() {
    return window.screen.width < 768;
  }

  /** ローカルストレージの値を取得 */
  function getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  /** ローカルストレージに値を設定 */
  function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  /** DOM生成(PC) */
  function createServiceSurveyPopup() {
    const child = document.createElement("div");
    child.className = "service-survey-popup";
    child.innerHTML =
      '\
      <div class="ssp-wrapper">\
        <div class="ssp-inner">\
          <div class="ssp-title">サービスの満足度についてお聞かせください</div>\
            <div class="ssp-question">\
              <div class="ssp-label bad">とても不満</div>\
              <div class="ssp-choice">1</div>\
              <div class="ssp-choice">2</div>\
              <div class="ssp-choice">3</div>\
              <div class="ssp-choice">4</div>\
              <div class="ssp-choice">5</div>\
              <div class="ssp-choice">6</div>\
              <div class="ssp-label good">とても満足</div>\
            </div>\
          <div class="ssp-note">お答えいただいた内容はサービス向上のために利用されます</div>\
        </div>\
        <div class="ssp-thank-message">ご回答ありがとうございます</div>\
        <div class="ssp-close"><svg width="24" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.204 8.70779C11.5945 8.31735 11.5945 7.68327 11.204 7.29283C10.8136 6.90239 10.1795 6.90239 9.78908 7.29283L6.5 10.585L3.20779 7.29595C2.81735 6.90551 2.18327 6.90551 1.79283 7.29595C1.40239 7.6864 1.40239 8.32047 1.79283 8.71092L5.08504 12L1.79596 15.2922C1.40551 15.6826 1.40551 16.3167 1.79596 16.7072C2.1864 17.0976 2.82047 17.0976 3.21092 16.7072L6.5 13.415L9.79221 16.704C10.1826 17.0945 10.8167 17.0945 11.2072 16.704C11.5976 16.3136 11.5976 15.6795 11.2072 15.2891L7.91496 12L11.204 8.70779Z" fill="white"/></svg></div>\
      </div>\
      ';
    document.getElementsByTagName("body")[0].appendChild(child);
  }

  /** DOM生成(SP) */
  function createServiceSurveyPopup4Sp() {
    const child = document.createElement("div");
    child.className = "service-survey-popup";
    child.innerHTML =
      '\
      <div class="ssp-wrapper">\
        <div class="ssp-inner">\
          <div class="ssp-title">サービスの満足度についてお聞かせください</div>\
          <div class="ssp-question">\
            <div class="ssp-choice">1</div>\
            <div class="ssp-choice">2</div>\
            <div class="ssp-choice">3</div>\
            <div class="ssp-choice">4</div>\
            <div class="ssp-choice">5</div>\
            <div class="ssp-choice">6</div>\
          </div>\
          <div class="ssp-label-group">\
            <div class="ssp-label bad">とても不満</div>\
            <div class="ssp-label good">とても満足</div>\
          </div>\
          <div class="ssp-note">お答えいただいた内容はサービス向上のために利用されます</div>\
        </div>\
        <div class="ssp-thank-message">ご回答ありがとうございます</div>\
        <div class="ssp-close"><svg width="24" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.204 8.70779C11.5945 8.31735 11.5945 7.68327 11.204 7.29283C10.8136 6.90239 10.1795 6.90239 9.78908 7.29283L6.5 10.585L3.20779 7.29595C2.81735 6.90551 2.18327 6.90551 1.79283 7.29595C1.40239 7.6864 1.40239 8.32047 1.79283 8.71092L5.08504 12L1.79596 15.2922C1.40551 15.6826 1.40551 16.3167 1.79596 16.7072C2.1864 17.0976 2.82047 17.0976 3.21092 16.7072L6.5 13.415L9.79221 16.704C10.1826 17.0945 10.8167 17.0945 11.2072 16.704C11.5976 16.3136 11.5976 15.6795 11.2072 15.2891L7.91496 12L11.204 8.70779Z" fill="white"/></svg></div>\
      </div>\
      ';
    document.getElementsByTagName("body")[0].appendChild(child);
  }

  /** イベント設定 */
  function setEvent() {
    // 閉じるボタンクリック時の処理
    document.querySelector(".ssp-close").addEventListener("click", function () {
      document
        .querySelector(".service-survey-popup")
        .classList.add("ssp-invisible");
      setTimeout(function () {
        document.querySelector(".service-survey-popup").style.display = "none";
      }, 700);
    });

    // 選択肢クリック時の処理
    document.querySelectorAll(".ssp-choice").forEach(function (elm) {
      elm.addEventListener("click", function (e) {
        // 回答累計回数をカウント
        let _count = parseInt(getLocalStorage(KEY_TOTAL_COUNT_POPUP)) || 0;
        setLocalStorage(KEY_TOTAL_COUNT_POPUP, ++_count);
        setLocalStorage(KEY_POPUP_LAST_ANSWERED, "1");
        document.querySelector(".ssp-inner").style.display = "none";
        const thankMessage = document.querySelector(".ssp-thank-message");
        thankMessage.style.display = "flex";
        thankMessage.classList.add("ssp-visible");

        // GA4回答結果イベント送信
        const _eventObj = {
          survey_score: e.target.innerText, // 回答値
          survey_display_timestamp: displayTimestamp, // ポップアップ表示日時
          survey_sent_timestamp: new Date().toISOString(), // 回答送信日時
          survey_total_count: _count, // 過去回答累計回数
          customer_id: _cookies.eridjp, // SFT
          send_to: "G-W2KP75ZMQX", // GA4トラッキングID
        };
        gtag("event", "service_survey_popup", _eventObj);

        // 3秒後にポップアップを非表示
        setTimeout(function () {
          document
            .querySelector(".service-survey-popup")
            .classList.add("ssp-invisible");
          setTimeout(function () {
            document.querySelector(".service-survey-popup").style.display =
              "none";
          }, 700);
        }, 3000);
      });
    });
  }

  // メイン処理
  function mainLogic() {
    const currentPath = window.location.pathname;
    const isMatomeShowPage = (/^\/matome\/\d+$/).test(currentPath)

    const randomNumber = Math.floor(Math.random() * 100) + 1;
    // まとめ詳細ページの場合のみ、実行確率1%（randomNumberが100なら実行）
    if (isMatomeShowPage && randomNumber !== 100) return
    // まとめ詳細ページ以外の場合、実行確率60%（randomNumberが41~100なら実行）
    if (!isMatomeShowPage && randomNumber <= 40) return

    // cookieがない場合は制御できないので終了
    if (!_cookies) return;

    // 未ログインなら終了
    if (
      !(_cookies.loginchecked && ["1", "3"].includes(_cookies.loginchecked) && _cookies.eridjp)
    )
      return;

    // ポップアップの表示対象かチェック
    const _lastTime = getLocalStorage(KEY_POPUP_WORK_LAST_TIME);

    if (_lastTime) {
      const _diff =
        new Date().getTime() - new Date(parseInt(_lastTime)).getTime();
      // 前回表示から90日以内なら表示しない
      if (_diff < 86400000 * 90) return;
    }

    // DOM生成
    if (isMobile()) {
      createServiceSurveyPopup4Sp();
    } else {
      createServiceSurveyPopup();
    }

    // イベント設定
    setEvent();

    // 前回回答済みフラグ
    const _isLastAnswered = getLocalStorage(KEY_POPUP_LAST_ANSWERED);

    if (_isLastAnswered) {
      // 2回目以降は文言を変える
      document.querySelector(".service-survey-popup .ssp-title").innerHTML =
        "前回はご協力ありがとうございました<br>再度サービスの満足度についてお聞かせください";
      document.querySelector(
        ".service-survey-popup .ssp-wrapper"
      ).style.height = isMobile() ? "138px" : "116px";
    } else {
      document.querySelector(".service-survey-popup .ssp-title").innerText =
        "サービスの満足度についてお聞かせください";
    }

    // 画面表示から3秒後にポップアップを表示する
    setTimeout(function () {
      document
        .querySelector(".service-survey-popup")
        .classList.add("ssp-visible");

        // 前回回答済みフラグは削除（回答されたらそのタイミングで再付与）
        localStorage.removeItem(KEY_POPUP_LAST_ANSWERED);
        
        // 一度ポップアップ表示したら90日間ポップアップを表示させない
        setLocalStorage(KEY_POPUP_WORK_LAST_TIME, new Date().getTime());
    }, 3000);

  }

  mainLogic();
}
serviceSurveyPopup();
