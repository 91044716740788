/*
 * マガジン閲覧画面
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import jsonp from 'jsonp';
import Super from '../utils/super.js';
import ShowArticle from './components/show-article.js';
import WatchUnit from '../utils/watch-unit.js';
import EventBus from '../utils/event-bus';

let MagazineShow = Super.extend({
  data () {
    return {
      magazineId: null,
      isDropdownOpened: false,
      isLoaded: false,
      items: [],
      query: {
        order_by: 'new',
      },
      // いいね関連の設定値
      hasForcedState: true,
      forcedVoteId: null,
      isForcedAnimate: false,
      isForcedActioned: false,
      forcedVoteCount: 0,
    };
  },

  components: {
    'show-article-component': ShowArticle,
    'watch-unit-component': WatchUnit,
  },

  events: {
    /**
     * ログイン誘導ナビゲーション表示
     */
    navigationCalled (calledId) {
      this.showNavigation = true;
      this.calledId = calledId;
    }
  },

  methods: {
    /** いいねボタンの状態をvote-unitに伝える
     * @param prop
     * @param value
     */
    updateForcedState (prop, value) {
      this[prop] = value;
    },

    /**
     * 追従するTweetボタンの表示制御をする
     */
    floatTweet() {
      const targetElement = document.querySelector('.canopy-actions');
      // targetElement取得できなかったら中断
      if (!targetElement) {
        return;
      }
      const options = {
        root: null,
        rootMargin: "10px",
        threshold: 0
      };
      const observer = new IntersectionObserver(this.callback, options);
      observer.observe(targetElement);
    },
    callback(entries) {
      const target = document.querySelector('#_tweet')
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // scroll内に現れたis-show外す
          target.classList.remove('is-show');
        } else {
          // scroll外に行ったらis-show追加
          target.classList.add('is-show');
        }
      });
    },
    /**
     * マガジン内まとめを取得する
     */
    search(){
      this.isLoaded = false;
      this.ajax({
        method: 'GET',
        url: `/magazine/open/api/magazine-body/${this.magazineId}`,
        params: this.query,
      }, false)
          .then(response => {
            this.items = response.data;

            this.$nextTick(() => {
              let images = document.querySelectorAll('.img-box > img');
              if (images.length > 0) objectFitImages(images);
              this.isLoaded = true;
              this.isRendered = true;
            });
          })
          .catch((e) => { // fail
            this.isLoaded = true;
            // console.log('fail', e);
          });
    },

    /**
     * ソート条件の切り替え
     * @param name
     * @param val
     * @param defaultVal
     */
    toggleCondition(name, val, defaultVal) {
      this.query.order_by = val;
      this.search();
    }
  },

  created () {
    this.affiliateId = document.getElementById('affiliate_id').value;
    
    let self = this;

    document.body.addEventListener('click', () => {
      self.isDropdownOpened = false;
    });
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('updateForcedState', this.updateForcedState);
      if ('IntersectionObserver' in window) {
        // canopy-actions監視
        this.floatTweet();
      }
      EventBus.$on('toggleCondition', this.toggleCondition);
    });
    // Canonical追加
    this.addCanonical();
  }
});

module.exports = MagazineShow;
