/**
 * お問い合わせ
 */

import Vue from 'vue';
import _ from 'lodash';
import Velocity from 'velocity-animate';
import Super from '../utils/super.js';
import Validation from '../utils/validation.js';

const validation = new Validation();
// 添付ファイルのファイルサイズ上限(1GB)
const fileSizeMax = 1073741824;
let initialData;
// 許可するお問い合わせ種別
const allow_type = ['deletion', 'bugReport', 'feedback', 'creators', 'press', 'other'];

let Contact = Super.extend({
  data () {
    return {
      type: {
        value: '',
        name: 'お問い合わせの種類'
      },
      body: '',
      email: '',
      upload: '',
      validation: {
        type: {
          isValid: true,
          message: ''
        },
        body: {
          isValid: true,
          message: ''
        },
        email: {
          isValid: true,
          message: ''
        },
        file: {
          isValid: true,
          message: ''
        }
      },
      isDropdownOpened: false,
      isPreview: false,
      isSubmitting: false
    };
  },

  computed: {
    isValid () {
      return this.validation.type.isValid && this.validation.body.isValid && this.validation.email.isValid && this.validation.file.isValid;
    }
  },

  watch: {
    isPreview () {
      Velocity(document.body, 'scroll', { duration: 200, offset: 0 });
    },

    'type.value' () {
      this.validateType();
    }
  },

  methods: {
    /**
     * バリデーション: お問い合わせの種類
     */
    validateType () {
      this.validation.type.isValid = true;
      this.validation.type.message = '';

      if (!this.type.value) {
        this.validation.type.isValid = false;
        this.validation.type.message = '※お問い合わせの種類を選択してください。';

        return;
      }
    },

    /**
     * バリデーション: お問い合わせ内容
     */
    validateBody () {
      let _body = this.body.trim();
      this.validation.body.isValid = true;
      this.validation.body.message = '';

      if (_body.length === 0) {
        this.validation.body.isValid = false;
        this.validation.body.message = '※お問い合わせ内容を入力してください。';

        return;
      }
    },

    /**
     * バリデーション: メールアドレス
     */
    validateEmail () {
      let _email = this.email.trim();
      this.validation.email.isValid = true;
      this.validation.email.message = '';

      if (_email.length === 0) {
        this.validation.email.isValid = false;
        this.validation.email.message = '※メールアドレスを入力してください。';

        return;
      }

      if (!validation.emailFormat(_email)) {
        this.validation.email.isValid = false;
        this.validation.email.message = '※有効なメールアドレスを入力してください。';

        return;
      }
    },

    /**
     * お問い合わせの種類セレクトボックスの開閉
     */
    toggleSelectBox () {
      this.isDropdownOpened = !this.isDropdownOpened;
    },

    /**
     * お問い合わせの種類を選択
     * @param  {String} type [お問い合わせの種類]
     * @param  {Event} e     [クリックイベント]
     */
    selectCategory (type, e) {
      this.type.value = type;
      this.type.name = e.target.innerHTML;

      document.getElementById('type_value').value = type;
      document.getElementById('type_name').value = e.target.innerHTML;
    },

    showPreview () {
      this.validateType();
      this.validateBody();
      this.validateEmail();

      if (!this.isValid) return;

      this.isPreview = true;
    },

    /**
     * お問い合わせ送信
     */
    post () {
      this.isSubmitting = true;

      this.$nextTick(() => {
        window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
        document.forms.report.submit();
      });
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler (e) {
      const message = 'このページから移動してもよろしいですか？';

      if (JSON.stringify(this.$data) !== JSON.stringify(initialData)) {
        e.returnValue = message;
        return message;
      }
    },

    /**
     * 添付ファイルアップロードの設定
     */
    initUploader () {
      let Upload = document.getElementById('upload');

      // 添付ファイル選択時
      Upload.addEventListener('change', () => {
        this.validation.file.isValid = true;
        let upload_file = Upload.files[0];

        if (!upload_file) return;
        
        let allow_extension = ['jpg', 'png', 'pdf', 'zip'];
        let file_extension = upload_file.name.split('.').pop().toLowerCase();
        // 許可していない拡張子の場合
        if(allow_extension.indexOf(file_extension) === -1){
          this.validation.file.isValid = false;
          this.validation.file.message = '※添付ファイルはjpg,png,pdf,zipにしてください。';
          return;
        }

        // サイズが上限を超えている場合
        if (upload_file.size > fileSizeMax) {
          this.validation.file.isValid = false;
          this.validation.file.message = '※添付ファイルは1GB未満にしてください。';
          return;
        }
        this.upload = upload_file.name;
      });
    },
    /**
     * URLにパラメータがセットされている場合、対応するお問い合わせ種別を選択する
     */
    initSelectCategory () {
      let url = new URL(window.location.href, 'https://ch.dlsite.com/aspect/contact');
      let searchParams = url.searchParams;
      if (typeof searchParams === 'object' && searchParams.has('type')) {
        let value = searchParams.get('type');
        let name = document.getElementById(value).innerHTML;
        // 許可されたお問い合わせ種別以外はスルー
        if(allow_type.indexOf(value) !== -1) {
          this.type.value = value;
          this.type.name = name;
          document.getElementById('type_value').value = value;
          document.getElementById('type_name').value = name;
        }
      }
    },
  },

  mounted () {
    this.$nextTick(() => {
      let self = this;

      // 初期状態の保存
      initialData = _.cloneDeep(self.$data);

      document.body.addEventListener('click', () => {
        self.isDropdownOpened = false;
      });
      if (!self.isNest) window.addEventListener('beforeunload', self.onBeforeUnloadHandler);
      this.initUploader();
      this.initSelectCategory();

      this.isRendered = true;
    });
  }
});

module.exports = Contact;
