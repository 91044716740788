/**
 * アンテナ: 作品データベース->クリエイター情報入力
 */

import Vue from 'vue';
import _ from 'lodash';
import Awesomplete from 'awesomplete';
import jobLabels from '../job-labels.json';
import Validation from '../../utils/validation.js';
import EventBus from '../../utils/event-bus';

const validation = new Validation();

let CreatorsData = Vue.extend({
  props: ['creatorsData'],

  data () {
    return {
      compName: 'creatorsData',
      throttled: null,
      createdAwesomplete: {},
      suggestingCreators: {
        job: null,
        creatorKey: null,
        creators: []
      },
      isValid: true,
      isLoading: false
    };
  },

  template: require('../views/creators-data-action.html'),

  methods: {
    /**
     * バリデーション: クリエイター
     * @param  {Object} creator [クリエイターオブジェクト]
     */
    validateCreator (creator) {
      let messages = [];
      creator.name = _.trim(creator.name);
      creator.comment = _.trim(creator.comment);
      creator._isValid = validation.creatorFormat(creator);

      if (!creator._isValid) {
        if (!creator.name) {
          messages.push('※クリエイター名を入力してください。');
        }

        if (creator.name.length > 40) {
          messages.push('※クリエイター名は40字以内で入力してください。');
        }

        if (creator.comment.length > 50) {
          messages.push('※備考は50字以内で入力してください。');
        }
      }
      // 文字数に関わらずチェックする
      if (creator.name.match('　') || creator.name.indexOf('#') === 0 || creator.name.indexOf('＃') === 0) {
        messages.push('※クリエイター名に利用できない文字が含まれています。');
        creator._isValid = false;
      }

      creator._message = messages.join("\n");

      this.$nextTick(() => {
        this.isValid = validation.checkAllCreatorsValidationResult(this.creatorsData);
      });
    },

    /**
     * 全項目をバリデート
     */
    validateAll () {
      this.isValid = true;

      _.forEach(this.creatorsData, (creatorsList, job) => {
        creatorsList.forEach(creator => {
          if (creator._isNew) this.validateCreator(creator);
        });
      });
    },

    /**
     * クリエイター追加
     * @param  {String} job [職種]
     */
    appendCreator (job) {
      let creator = {
        id: 'isNew',
        name: '',
        comment: '',
        _isValid: true,
        _message: '',
        _isNew: true
      };

      EventBus.$emit('appendCreator', this.creatorsData[job], creator);

      // クリエイター名入力エリアの初期化
      this.$nextTick(() => {
        this.initCreatorInput(job, this.creatorsData[job].length - 1);
      });
    },

    /**
     * クリエイター削除
     * @param  {String} job         [職種]
     * @param  {Number} creatorKey [対象クリエイターの職種内のキー]
     */
    removeCreator (job, creatorKey) {
      EventBus.$emit('removeCreator', this.creatorsData[job], creatorKey);
    },

    /**
     * クリエイターの職種を取得
     * @param  {String} job [職種]
     * @return {String}     [職種の日本語名]
     */
    getLabel (job) {
      return jobLabels[job];
    },

    /**
     * クリエイター名のサジェスト
     * @param  {String} creatorName [入力中のクリエイター名]
     * @param  {String} job         [入力中の職種]
     * @param  {Number} creatorKey  [入力中のクリエイターの職種内でのキー]
     * @param  {Event} e            [keyup イベント]
     */
    inputingCreatorName (creatorName, job, creatorKey, e) {
      if (creatorName === this.creatorsData[job][creatorKey]._tally) {
        return;
      } else {
        this.creatorsData[job][creatorKey].id = 'isNew';
      }

      // 通信を間引く
      if (!this.throttled) {
        this.throttled = _.throttle((creatorName, job, creatorKey) => {
          this.isLoading = true;
          this.suggestingCreators.job = job;
          this.suggestingCreators.creatorKey = creatorKey;

          this.$root.ajax({
            method: 'GET',
            url: '/api/tag-suggest',
            params: {
              search: creatorName,
              type: 'creator'
            },
            before: request => {
              if (this.$root.previousRequest) {
                this.$root.previousRequest.abort();
              }

              this.$root.previousRequest = request;
            }
          }, false)
          .then(response => {
            this.isLoading = false;

            this.suggestingCreators.creators = response.data.map(tag => {
              return {
                label: tag.name,
                value: tag.id
              };
            });

            this.createdAwesomplete[`${job}-${creatorKey}`].list = this.suggestingCreators.creators;
          })
          .catch(error => {
            console.log(error);

            this.isLoading = false;
          });
        }, 500);
      }

      // 矢印キーの場合は取得しない
      if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
        return;
      }

      // 入力がある場合取得
      if (creatorName.length > 0) this.throttled(creatorName, job, creatorKey);
    },

    /**
     * クリエイター入力エリアを初期化
     * @param  {String} job        [職種]
     * @param  {Number} creatorKey [職種内でのキー]
     */
    initCreatorInput (job, creatorKey) {
      if (!this.createdAwesomplete[`${job}-${creatorKey}`]) {
        let input = document.getElementById(`${job}-${creatorKey}-input`);
        let awesomplete = new Awesomplete(input, {
          minChars: 1,
          autoFirst: true,
          replace: suggestion => {
            input.value = suggestion.label;
            this.creatorsData[job][creatorKey].name = suggestion.label;
            this.creatorsData[job][creatorKey].id = suggestion.value;
            this.creatorsData[job][creatorKey]._tally = suggestion.label;
          }
        });

        this.createdAwesomplete[`${job}-${creatorKey}`] = awesomplete;
      }
    }
  }
});

module.exports = CreatorsData;