import Vue from 'vue';
import EditMenu from './edit-menu.js';
import '../../utils/filters.js';
import Validation from '../../utils/validation.js';
import EventBus from '../../utils/event-bus';

const validation = new Validation();

let ArticleAction = Vue.extend({
  props: ['items', 'talkData', 'workData', 'editingItemIndex', 'hasTwitterAuthentication'],

  data () {
    return {
      compName: 'articleAction'
    };
  },

  template: require('../views/article-action.html'),

  components: {
    'edit-menu-component': EditMenu
  },

  methods: {
    /**
     * アイテム確定
     *
     * input 要素でエンター、または OK ボタン押下時。
     * バリデートし、有効ならばアイテムを確定する。
     * バリデーションエラーの場合はエラーメッセージを表示する。
     * @param  {Object} item  [編集中のアイテムオブジェクト]
     * @param  {Number} index [編集中のアイテムのインデックス]
     */
    fixItem (item, index) {
      let isValid = true;

      switch (item.type) {
        // 見出し
        case 'heading':
          this.validateHeading(item);
          break;

        // 小見出し
        case 'subhead':
          this.validateSubhead(item);
          break;

        // テキスト
        case 'text':
          this.validateText(item);
          break;

        // リンク
        case 'link':
          this.validateLinkTitle(item);
          this.validateDescription(item);
          break;

        // 引用
        case 'reference':
          this.validateReference(item);
          this.validateReferenceUrlOrTitle(item);
          break;
      }

      Object.keys(item._validation).forEach(prop => {
        if (!item._validation[prop].isValid) isValid = false;
      });

      if (isValid) EventBus.$emit('fixItem', index);
    },

    /**
     * 編集キャンセル
     *
     * 編集中の要素の内容を編集前の状態に復元する。
     * @param  {Number} index [編集中のアイテムのインデックス]
     */
    cancelItem (index) {
      EventBus.$emit('cancelItem', index);
    },

    /**
     * 画像アイテム読み込み失敗
     *
     * モーダルで選択した画像が読み込み時にエラーになった場合エラーにする。
     * @param  {Number} index [対象のアイテムの index]
     */
    loadImageError (index) {
      EventBus.$emit('loadImageFailed', index);
    },

    /**
     * バリデーション: 見出し
     * @param  {Object} item [見出しアイテムオブジェクト]
     */
    validateHeading (item) {
      let _heading = item._comment.trim();
      item._validation.comment.isValid = true;
      item._validation.comment.message = '';

      if (_heading.length === 0) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※見出しの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_heading, 100)) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※100字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 小見出し
     * @param  {Object} item [小見出しアイテムオブジェクト]
     */
    validateSubhead (item) {
      let _subhead = item._comment.trim();
      item._validation.comment.isValid = true;
      item._validation.comment.message = '';

      if (_subhead.length === 0) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※小見出しの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_subhead, 100)) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※100字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: テキスト
     * @param  {Object} item [テキストアイテムオブジェクト]
     */
    validateText (item) {
      let _text = item._comment.trim();
      item._validation.comment.isValid = true;
      item._validation.comment.message = '';

      if (_text.length === 0) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※テキストの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_text, 2000)) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※2000字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: URL
     * @param  {Object} item [リンクアイテムオブジェクト || 引用アイテムオブジェクト]
     */
    validateUrl (item) {
      let _url = item._url.replace(/\s/g, '');
      item._validation.url.isValid = true;
      item._validation.url.message = '';
      item._validation.url.infomationMessage = '';

      if (_url.length === 0) {
        item._validation.url.isValid = false;
        item._validation.url.message = '※URLの入力は必須です。';
        return;
      }

      if (!validation.urlFormat(_url)) {
        item._validation.url.isValid = false;
        item._validation.url.message = '※正しい形式で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: URL
     * @param  {Object} item [リンクアイテムオブジェクト || 引用アイテムオブジェクト]
     */
    validateReferenceUrl (item) {
      let _url = item._url.replace(/\s/g, '');
      item._validation.url.isValid = true;
      item._validation.url.message = '';

      if (!validation.urlFormat(_url)) {
        item._validation.url.isValid = false;
        item._validation.url.message = '※正しい形式で入力してください。';
      }
    },

    /**
     * バリデーション: 引用URLまたは引用タイトル
     * @param  {Object} item [リンクアイテムオブジェクト || 引用アイテムオブジェクト]
     */
    validateReferenceUrlOrTitle (item) {
      let _url = item._url.replace(/\s/g, '');
      item._validation.url.isValid = true;
      item._validation.url.message = '';

      let _title = item._title.trim();
      item._validation.title.isValid = true;
      item._validation.title.message = '';

      if (_url.length === 0 && _title.length === 0) {
        item._validation.url.isValid = false;
        item._validation.url.message = '※出典元または出典元URLのどちらか片方の入力は必須です。';
        item._validation.title.isValid = false;
        item._validation.title.message = '※出典元または出典元URLのどちらか片方の入力は必須です。';
        return;
      }

      if (!validation.urlFormat(_url)) {
        item._validation.url.isValid = false;
        item._validation.url.message = '※正しい形式で入力してください。';
      }

      if (!validation.maxSize(_title, 255)) {
        item._validation.title.isValid = false;
        item._validation.title.message = '※255字以内で入力してください。';
      }
    },

    /**
     * バリデーション: リンクタイトル
     * @param  {Object} item [リンクアイテムオブジェクト]
     */
    validateLinkTitle (item) {
      let _title = item._title.trim();
      item._validation.title.isValid = true;
      item._validation.title.message = '';

      if (_title.length === 0) {
        item._validation.title.isValid = false;
        item._validation.title.message = '※リンクのタイトルの入力は必須です。';

        return;
      }

      if (!validation.maxSize(_title, 255)) {
        item._validation.title.isValid = false;
        item._validation.title.message = '※255字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 説明
     * @param  {Object} item [リンクアイテムオブジェクト || 画像アイテムオブジェクト]
     */
    validateDescription (item) {
      let _description = item._description.trim();
      item._validation.description.isValid = true;
      item._validation.description.message = '';

      if (!validation.maxSize(_description, 255)) {
        item._validation.description.isValid = false;
        item._validation.description.message = '※255字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 引用
     * @param  {Object} item [引用アイテムオブジェクト]
     */
    validateReference(item) {
      let _message = item._message.trim();
      item._validation.message.isValid = true;
      item._validation.message.message = '';

      if (_message.length === 0) {
        item._validation.message.isValid = false;
        item._validation.message.message = '※引用の入力は必須です。';

        return;
      }

      if (!validation.maxSize(_message, 1000)) {
        item._validation.message.isValid = false;
        item._validation.message.message = '※1000字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 引用の出典元
     * @param  {Object} item [引用アイテムオブジェクト]
     */
    validateReferenceTitle(item) {
      let _title = item._title.trim();
      item._validation.title.isValid = true;
      item._validation.title.message = '';

      if (!validation.maxSize(_title, 255)) {
        item._validation.title.isValid = false;
        item._validation.title.message = '※255字以内で入力してください。';
      }
    },

    /**
     * バリデーション: メディアタイトル
     * @param  {Object} item [画像アイテムオブジェクト || 動画アイテムオブジェクト]
     */
    validateMediaTitle (item) {
      let _title = item._title.trim();
      item._validation.title.isValid = true;
      item._validation.title.message = '';

      if (!validation.maxSize(_title, 100)) {
        item._validation.title.isValid = false;
        item._validation.title.message = '※100字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: コメント
     * @param  {Object} item [アイテムオブジェクト]
     */
    validateComment (item) {
      let _comment = item._comment.trim();
      item._validation.comment.isValid = true;
      item._validation.comment.message = '';

      if (!validation.maxSize(_comment, 255)) {
        item._validation.comment.isValid = false;
        item._validation.comment.message = '※255字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: 画像サイズ
     * @param  {Object} item [アイテムオブジェクト]
     */
    validateImageSize (item) {
      let _size = item._size.trim();
      item._validation.size.isValid = true;
      item._validation.size.message = '';

      let allowSize = ['img-small', 'img-medium', 'img-large'];
      if (allowSize.indexOf(_size) === -1) {
        item._validation.size.isValid = false;
        item._validation.size.message = '画像サイズが正しくありません。';

        return;
      }
    },

    // 編集モードに切り替え
    editStart (key) {
      EventBus.$emit('editStart', key);
    },

    callModal (mediaType, searchMode, item) {
      EventBus.$emit('callModal', mediaType, searchMode, item);
    },

    // リンクを取得
    fetchReference (item) {
      this.validateUrl(item);

      if (item._validation.url.isValid) {
        item._isLoading = true;
        item._url = item._url.replace(/\s/g, '');

        this.$root.ajax({
          method: 'GET',
          url: `/matome/api/url-data?url=${btoa(unescape(encodeURIComponent(item._url)))}`
        }, false)
        .then(response => {
          item._isLoading = false;

          if (Object.keys(response.data).length > 0) {
            EventBus.$emit('fetchReference', response.data, item);
          } else {
            if (item.type === 'reference') {
              item._validation.url.infomationMessage = '※リンク先の情報が取得できません。URLを変更するか、出典元のサイト名を入力してください。';
            } else {
              item._validation.url.isValid = false;
              item._validation.url.message = '※このリンク先は取得できません。';
            }
          }
        })
        .catch(error => {
          console.log(error);

          item._isLoading = false;
          item._validation.url.isValid = false;
          item._validation.url.message = '※エラーが発生しました。';
        });
      }
    },

    /**
     * リンクの画像を切替
     * @param  {Object} item      [リンクアイテムオブジェクト]
     * @param  {Number} indexDiff [-1 || 1]
     */
    changeLinkImage (item, indexDiff) {
      EventBus.$emit('changeLinkImage', item, indexDiff);
    },

    /**
     * アイテムを削除
     * @param  {Number} key [削除するアイテムのキー]
     */
    removeNode (key) {
      EventBus.$emit('removeNode', key);
    },

    /**
     * テキストエリアの行数を計算
     * @param  {String} text  [入力内容]
     * @param  {Number} index [テキストアイテムのインデックス]
     */
    calcTextareaRows (text, index) {
      if (text.match(/\n|\r\n/g)) {
        this.items[index].rows = text.match(/\n|\r\n/g).length > 3 ? text.match(/\n|\r\n/g).length + 2 : 5;
      } else {
        this.items[index].rows = 5;
      }
    }
  }
});

module.exports = ArticleAction;
