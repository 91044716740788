/*
 * まとめ追加
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import Validation from '../../utils/validation.js';
import '../../utils/filters.js';
import EventBus from "../../utils/event-bus";

const validation = new Validation();

let AddMatome = Super.extend({
  props: ['magazineId', 'isMobile'],

  data () {
    return {
      url: '',
      validation: {
        matome:{
          isValid: true,
          message: null
        }
      },
      dropdownOpenedId: null,
      isLoading: false,
      isSubmitting: false
    };
  },

  template: require('../views/add-matome.html'),

  methods: {
    /**
     * バリデーション: まとめ
     */
    validateMatome() {
      this.validation.matome.isValid = true;
      this.validation.matome.message = null;

      // まとめが50件追加されていたら
      if(this.$root.itemCount >= 50){
        this.validation.matome.isValid = false;
        this.validation.matome.message = 'マガジンに収納できる記事数の上限は50件です';
        return;
      }
      // 入力されたのがURLでない場合
      try {
        new URL(this.url);
      } catch (e) {
        this.validation.matome.isValid = false;
        this.validation.matome.message = 'URL形式で入力してください';
        return;
      }

      // 既に追加されていたら
      let matomeId = this.getMatomeIdFromUrl();
      let find = this.$root.items.find(({id}) => id == matomeId);
      if(find){
        this.validation.matome.isValid = false;
        this.validation.matome.message = '該当するまとめ記事は既に追加されております';
      }
    },

    /**
     * まとめを追加
     * @param  {Event} e [クリックイベント]
     */
    getMatome(e) {
      if (!this.userInfo.is_logined) {
        this.$root.loginCheck(this.userInfo.is_logined, null, e);
        return;
      }

      // ブラウザ自動操作チェック
      // いらないかも
      this.checkBrowser(this.userInfo.profile_id, 'マガジンにまとめ追加');

      // 追加したまとめのバリデーション
      this.validateMatome();
      if (!this.validation.matome.isValid) return;

      this.isSubmitting = true;

      this.$nextTick(() => {
        this.ajax({
          method: 'GET',
          url: `/magazine/api/getMatome`,
          params: {
            url: this.url,
            age_category: this.$root.ageCategory
          }
        }, true)
            .then(response => {
              this.appendItem(response.data);
              this.isSubmitting = false;
              // 追加成功したらテキストボックスのURLクリアする
              this.url = '';
            })
            .catch(error => {
              this.validation.matome.isValid = false;
              this.validation.matome.message = error.response.data.message;
              this.isSubmitting = false;
            });
      });
    },
    /** まとめを追加する
     * @param item
     */
    appendItem (item) {
      // 親のappednItemを実行する
      EventBus.$emit('appendItem', item);
    },
    /**
     * マガジンを保存
     */
    save(){
      // 親のsaveを実行する
      this.$root.save();
    },

    /**
     * urlからまとめIDを抽出する
     */
    getMatomeIdFromUrl(){
      let parse_url = new URL(this.url);
      return parse_url.pathname.match(/[0-9]+/g).shift();
    }
  },
});

module.exports = AddMatome;