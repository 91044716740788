/**
 * トーク検索モーダル
 */

import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import EventBus from '../event-bus';
import '../../utils/filters.js';

let TalkSearchAction = Vue.extend({
  props: ['searchMode', 'searchResult'],

  data () {
    return {
      keyword: '',
      category: {
        id: 0,
        name: 'カテゴリで絞り込む',
        classes: {
          'is-opened': false
        }
      },
      page: 0,
      isLoading: false,
      isNoContent: false,
      isError: false,
      throttled: null,
      isLocked: true
    };
  },

  template: require('../views/talk-search-action.html'),

  methods: {
    // 検索対象切り替え
    changeSearchMode (searchMode) {
      EventBus.$emit('changeSearchMode', searchMode);

      // キーワードが入力されている or 自分の作成したトークならば検索
      if (this.keyword || searchMode === 'created') {
        this.$nextTick(() => {
          this.search(this.keyword);
        });
      } else {
        this.searchResult.items = [];
        this.page = 0;
        this.isLoading = false;
        this.isNoContent = false;
        this.isError = false;
        this.isLocked = true;
      }
    },

    // カテゴリセレクトボックスの開閉
    toggleSelectBox () {
      this.category.classes['is-opened'] = !this.category.classes['is-opened'];
    },

    // カテゴリ選択
    selectCategory (categoryId, categoryName) {
      this.category.id = categoryId;
      this.category.name = categoryName;

      if (this.keyword) {
        this.search(this.keyword);
      }
    },

    /**
     * 検索実行
     * @param  {String} keyword = '' [検索キーワード]
     * @param  {number} page    = 1  [ページ番号]
     */
    search (keyword = '', page = 1) {
      let
        query = { page: page },
        url;

      this.isLocked = true;

      if (page === 1) {
        this.searchResult.items = [];
        this.keyword = keyword;
        this.isError = false;
        this.isNoContent = false;
      }

      this.page = page + 1;
      this.isLoading = true;

      if (this.searchMode === 'all') {
        // 検索の場合
        url = '/search/api/global-search/product';
        query.content = 'talks';
        query.keyword = this.keyword;
        query.category = this.category.id;
      } else {
        // 自分で作ったトークの場合
        this.keyword = '';
        url = '/matome/api/search-user-talks';
      }

      this.$root.ajax({
        method: 'GET',
        url: url,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        if (response.status === 200 && response.data.talk.items.length === 0) {
          this.isNoContent = true;
          return;
        }

        this.searchResult.items = this.searchResult.items.concat(response.data.talk.items);

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);
        });

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError) return;

            if (document.querySelector('.modal-body .talk-elements')) {
              if (document.querySelector('.modal-body .talk-elements').clientHeight - 100 < document.querySelector('.modal-body .modal-main-column').scrollTop + document.querySelector('.modal-body .modal-main-column').clientHeight) {
                this.isLoading = true;

                this.search(this.keyword, this.page);
              }
            }
          }, 500);

          document.querySelector('.modal-body .modal-main-column').onscroll = this.throttled;
        }
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    // アイテム選択
    selectItem (item) {
      EventBus.$emit('closeModal');
      EventBus.$emit('selectItem', item);
    },

    // モーダルクローズ
    closeModal () {
      EventBus.$emit('closeModal');
    }
  },

  created () {
    let self = this;

    document.body.onclick = () => {
      self.category.classes['is-opened'] = false;
    };
  }
});

module.exports = TalkSearchAction;