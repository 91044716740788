/**
* アクティビティテンプレートビルダー
*/

import moment from 'moment';

require('moment/locale/ja');
moment.locale('ja');

export default class TemplateBuilder {
  /**
   * アクティビティを生成
   * @param  {Boolean} isMypage = false
   * @param  {Object}  json             [アクティビティの詳細]
   * @return {String}  template         [アクティビティ本文 html]
   */
  create (isMypage = false, json) {
    let
      type = json.activity_type_id,
      owner = json.owner,
      target = json.target,
      date = json.activity_date,
      isNew = json.unread,
      template = null;

    if(owner.thumb_url){
      owner.thumb_url = this.fixImageUrlScheme(owner.thumb_url);
    }

    if(target.image_url){
      target.image_url = this.fixImageUrlScheme(target.image_url);
    }

    target.content = json.target_content;

    if (type && owner && target) {
      template = this.getTemplate(isMypage, type, owner, target, this.absTime2relTime(date), isNew);
    }

    return template;
  }

  /**
   * 画像URIのSchemeをhttp -> httpsへ強制変換
   * @param {String} url
   * @return {String} convertedURL
   */
  fixImageUrlScheme (imageUri){
    if(imageUri.indexOf('http://media.dlsite.com/') === 0){
      return imageUri.replace(/^http/,'https');
    }
    return imageUri;
  }

  /**
   * テンプレート取得
   * @param  {Boolean} isMypage
   * @param  {String} type     [アクティビティ識別子]
   * @param  {Object} owner    [アクティビティの起点]
   * @param  {Object} target   [アクティビティの対象]
   * @param  {String} date     [アクティビティ発生日時]
   * @param  {Boolean} isNew   [未読かどうか]
   * @return {String} template [アクティビティ本文 html]
   */
  getTemplate (isMypage, type, owner, target, date, isNew) {
    let
      subject = '',
      body = '';

    switch(type) {
      // フォロイーがまとめにいいね
      case 'profile_matome_vote':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>まとめにいいねしました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt="${target.title}" title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがアンテナにいいね
      case 'profile_antenna_vote':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>アンテナにいいねしました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre antenna">アンテナ</div>
            </div>
            <a href="/antenna/${target.play_id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover">
                  <img src="${target.images.image_main}" alt="${target.work_name}" title="${target.work_name}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.work_name}</h2>
                <p class="notification-link-sub">${target.maker_info.name}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがまとめを作成
      case 'profile_matome_create':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>まとめを作成しました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがトークを作成
      case 'profile_talk_create':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>トークを作成しました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre talk">トーク</div>
            </div>
            <a href="/talk/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub" alt="${target.title}">テーマ：${target.work_data ? target.work_data.work_name : 'フリー'}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがまとめをウォッチ
      case 'profile_matome_watch':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>まとめをウォッチしました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがトークをウォッチ
      case 'profile_talk_watch':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<span>トークをウォッチしました。</span>`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre talk">トーク</div>
            </div>
            <a href="/talk/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub" alt="${target.title}">テーマ：${target.work_data ? target.work_data.work_name : 'フリー'}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがメーカーをウォッチ
      case 'profile_maker_watch':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `<a href="/search/contents?maker_id=${target.id}">${target.name}</a>をウォッチしました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre antenna">アンテナ</div>
            </div>
          </li>
        `;

        break;

      // フォロイーがレス
      case 'profile_talk_res':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `トークにレスしました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre talk">トーク</div>
            </div>
            <a href="/talk/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub" alt="${target.title}">テーマ：${target.work_data ? target.work_data.work_name : 'フリー'}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォロイーがコメント
      case 'profile_matome_comment':
        if (isMypage) subject = this.createSubjectOwnerString(owner);

        subject += `まとめにコメントしました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // ウォッチ中のまとめが更新
      case 'matome_matome_update':
        subject = `まとめが更新されました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // ウォッチ中のまとめに新着コメント
      case 'matome_matome_comment':
        subject = `まとめにコメントが追加されました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // ウォッチ中のトークに新着レス
      case 'talk_talk_res':
        subject = `トークにレスが追加されました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre talk">トーク</div>
            </div>
            <a href="/talk/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub" alt="${target.title}">テーマ：${target.work_data ? target.work_data.work_name : 'フリー'}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // ウォッチ中のメーカーが新作発売
      case 'maker_antenna_on_sale':
        subject = `<a href="/search/contents?maker_id=${owner.id}">${owner.name}</a>が新作を発売しました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre antenna">アンテナ</div>
            </div>
            <a href="/antenna/${target.play_id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover">
                  <img src="${target.images.image_main}" alt="${target.work_name}" title="${target.work_name}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.work_name}</h2>
                <p class="notification-link-sub">${target.maker_info.name}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // 自分のレスに返信
      case 'profile_info_add_res':
        subject = `トークであなたの書き込みにレスがありました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text"><a class="user-name" href="/profile/${owner.id}">${owner.nick_name}</a>さんがあなたの書き込みにレスしました。<span class="timestamp">${date}</span></p>
              <div class="notification-genre talk">トーク</div>
            </div>
            <a href="/talk/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt="${target.title}" title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">テーマ：${target.work_data ? target.work_data.work_name : 'フリー'}</p>
              </div>
            </a>
          </li>
        `;

        break;

      // フォローされた
      case 'profile_info_get_follower':
        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text"><a class="user-name" href="/profile/${target.id}">${target.nick_name || 'まだ名前がありません'}</a>さんにフォローされました。<span class="timestamp">${date}</span></p>
              <div class="notification-genre info">おしらせ</div>
            </div>
          </li>
        `;

        break;

      // 個人宛のおしらせ
      case 'profile_info_notification':
        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text"><a class="user-name" href="/profile/${owner.id}">${owner.nick_name}<span class="verified-badge"><i>公式アカウント</i></span></a>さんからメッセージが届いています。<span class="timestamp">${date}</span></p>
              <div class="notification-genre info">おしらせ</div>
            </div>
            <div class="official-message">
              <div class="official-message-body">
                <a href="/profile/${owner.id}" class="official-message-user">
                  <div class="icon">
                    <img src="${owner.thumb_url}">
                  </div>
                  <i>${owner.nick_name}</i>
                  <span class="verified-badge"><i>公式アカウント</i></span>
                </a>
                <p class="lead">${target.body}</p>
              </div>
        `;

        if (target.image_url) {
          body += `<div class="official-message-img">`;

          if (target.href) {
            body += `<a href="${target.href}"><img src="${target.image_url}" alt="おしらせ"></a>`;
          } else {
            body += `<img src="${target.image_url}" alt="おしらせ">`;
          }

          body += `</div>`;
        }
        
        body += `
            </div>
          </li>
        `;

        break;

      // 全体宛のおしらせ
      case 'all_info_notification':
        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text"><a class="user-name" href="/profile/${owner.id}">${owner.nick_name}<span class="verified-badge"><i>公式アカウント</i></span></a>さんからメッセージが届いています。<span class="timestamp">${date}</span></p>
              <div class="notification-genre info">おしらせ</div>
            </div>
            <div class="official-message">
              <div class="official-message-body">
                <a href="/profile/${owner.id}" class="official-message-user">
                  <div class="icon">
                    <img src="${owner.thumb_url}">
                  </div>
                  <i>${owner.nick_name}</i>
                  <span class="verified-badge"><i>公式アカウント</i></span>
                </a>
                <p class="lead">${target.body}</p>
              </div>
        `;

        if (target.image_url) {
          body += `<div class="official-message-img">`;

          if (target.href) {
            body += `<a href="${target.href}"><img src="${target.image_url}" alt="おしらせ"></a>`;
          } else {
            body += `<img src="${target.image_url}" alt="おしらせ">`;
          }

          body += `</div>`;
        }
        
        body += `
            </div>
          </li>
        `;

        break;

      // まとめに新着コメント
      case 'user_matome_comment':
        subject = `まとめにコメントが追加されました。`;

        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">まとめ</div>
            </div>
            <a href="/matome/${target.id}" class="notification-link">
              <div class="notification-link-thumb ${target.is_creators ? 'is-creator' : ''}">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;

        break;
      // ウォッチ中のマガジンが更新された
      case 'magazine_magazine_update':
        subject = 'マガジンが更新されました。';
        body = `
          <li class="notification-item ${isNew ? 'is-new' : ''}">
            <div class="notification-body">
              <p class="notification-text">${subject}<span class="timestamp">${date}</span></p>
              <div class="notification-genre matome">マガジン</div>
            </div>
            <a href="/magazine/${target.id}" class="notification-link">
              <div class="notification-link-thumb">
                <div class="img-box square cover ${target.thumb_url ? '' : 'is-blank'}">
                  <img src="${target.thumb_url}" alt=${target.title} title="${target.title}">
                </div>
              </div>
              <div class="notification-link-body">
                <h2 class="notification-link-title">${target.title}</h2>
                <p class="notification-link-sub">${target.description}</p>
              </div>
            </a>
          </li>
        `;
        break;

      default:
        break;
    }

    return body;
  }

  /**
   * 絶対時間を相対時間に変換して html を返却
   *
   * @param  {string} absTime [変換したい絶対時間]
   * @return {string} [相対時間]
   */
  absTime2relTime (absTime) {
    if (!absTime) return '';

    absTime = absTime.replace('+', 'UTC+');

    const absMoment = absTime.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) ? moment(absTime, moment.ISO_8601) : moment(new Date(absTime));

    // 不正な日付の場合
    if (!absMoment.isValid()) return '';

    return  absMoment.fromNow();
  }

  createSubjectOwnerString (owner) {
    let str = '';

    if (owner.admin_flg) {
      str = `<a class="user-name" href="/profile/${owner.id}">${owner.nick_name}<span class="verified-badge"><i>公式アカウント</i></span></a>さんが`;
    } else {
      str = `<a class="user-name" href="/profile/${owner.id}">${owner.nick_name}</a>さんが`;
    }

    return str;
  }
};