/**
 * 検索画面: まとめ検索結果
 */

import Vue from 'vue';
import EventBus from '../../utils/event-bus';
import Pagination from '../../utils/components/pagination.js';

let MatomeResults = Vue.extend({
  props: ['items', 'amount', 'currentPage', 'isSelected', 'isLoading', 'isAntenna', 'playId'],

  data () {
    return {
      compName: 'matomes',
      orderBy: {
        new: {
          label: '新着順',
          value: 'new'
        },
        update: {
          label: '最近更新された',
          value: 'update'
        },
        vote: {
          label: 'いいねされた数',
          value: 'vote'
        },
        view: {
          label: 'よく見られている',
          value: 'view'
        },
        fav: {
          label: 'いま人気',
          value: 'fav'
        },
        condition: 'new',
        classes: {
          isOpen: false
        }
      },
      currentViewsFrom: null,
      currentViewsTo: null
    };
  },

  template: require('../views/matome-results.html'),

  components: {
    'pagination-component': Pagination
  },

  watch: {
    isSelected () {
      this.init();
    }
  },

  events: {
    conditionReset () {
      this.orderBy.condition = 'new';
    },

    initialSearch (val) {
      this.orderBy.condition = val;
    }
  },

  methods: {
    /**
     * ソート条件を選択
     * @param  {String} name       ['order_by']
     * @param  {String} val        [ソート条件]
     * @param  {String|null} defaultVal   [デフォルト値]
     */
    toggleOrder (name, val, defaultVal) {
      this.orderBy.condition = val;

      EventBus.$emit('toggleCondition', name, val, defaultVal);
    },
  },

  mounted () {
    this.$nextTick(() => {
      const self = this;

      if (self.isSelected) {
        document.body.addEventListener('click', () => {
          self.orderBy.classes.isOpen = false;
        });

        this.orderBy.condition = this.$root.query.order_by;
      }
    });
  }
});

module.exports = MatomeResults;