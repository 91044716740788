/**
 * 検索画面: 関連タグ
 */

import Vue from 'vue';

var RelatedTags = Vue.extend({
  props: ['items', 'content'],

  template: require('../views/related-tags.html')
});

module.exports = RelatedTags;