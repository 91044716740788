/**
 * ヘッダ
 */

import Vue from 'vue';
import _ from 'lodash';
import Awesomplete from 'awesomplete';
import Headroom from 'headroom.js';
import Super from '../utils/super.js';

let preScrollTop;

let Header = Super.extend({
  data () {
    return {
      showUserMenu: false,
      createdAwesomplete: {},
      suggestions: {
        list: []
      },
      searching: {
        keyword: '',
        id: null,
        type: 'keyword',
        tally: null
      },
      isLoading: false,
      isSearching: false,
      isMenuOpened: false,
      isSearchOpened: false
    };
  },

  computed: {
    currentUrl () {
      return location.href;
    },

    cookie () {
      let
        cookies = document.cookie.split('; '),
        cookiesObj = {},
        eachCookie = [];

      cookies.map(val => {
        eachCookie = val.split('=');
        cookiesObj[eachCookie[0]] = eachCookie[1];
      });

      return cookiesObj;
    }
  },

  watch: {
    isMenuOpened () {
      let
        body = document.body,
        html = document.documentElement,
        scrollTop = body.scrollTop || html.scrollTop;

      if (this.isMenuOpened) {
        // スマホ: メニュー表示中はスクロールさせない
        // スクロール位置を保持
        preScrollTop = scrollTop;
        document.querySelector('body').style.top = `-${scrollTop}px`;
        document.querySelector('body').style.position = 'fixed';
      } else {
        document.querySelector('body').style.position = 'relative';
        document.querySelector('body').style.top = ``;
        // 元のスクロール位置まで戻す
        window.scrollTo(0, preScrollTop);
      }
    },

    isSearchOpened () {
      let
        body = document.body,
        html = document.documentElement,
        scrollTop = body.scrollTop || html.scrollTop;

      if (this.isSearchOpened) {
        // スマホ: 検索フォーム表示中はスクロールさせない
        preScrollTop = scrollTop;
        document.querySelector('body').style.top = `-${scrollTop}px`;
        document.querySelector('body').style.position = 'fixed';
      } else {
        if (!this.isMenuOpened) {
          document.querySelector('body').style.position = 'relative';
          document.querySelector('body').style.top = ``;

          window.scrollTo(0, preScrollTop);
        }
      }
    }
  },

  methods: {
    /**
     * R-18モード切り替え
     */
    toggleAdultMode () {
      if (!this.cookie.adult_mode || this.cookie.adult_mode === '0') {
        document.cookie = `adult_mode=1;path=/;max-age=${60 * 60 * 24 * 365}`;
      } else {
        document.cookie = `adult_mode=0;path=/;max-age=${60 * 60 * 24 * 365}`;
      }

      location.reload();
    },

    // スマホメニューの表示
    showSpMenu () {
      // 検索フォームを閉じる
      this.isSearchOpened = false;

      this.$nextTick(() => {
        this.isMenuOpened = true;
      });
    },

    /**
     * 検索キーワードサジェスト
     * @param  {String} keyword [入力中のキーワード]
     * @param  {Event} e        [keyup イベント]
     */
    inputingKeyword (keyword, e) {

      keyword = _.trim(keyword);

      if (keyword === this.tally || this.isSearching) {
        return;
      }

      // 通信を間引く
      if (!this.throttled) {
        let caches = {};

        this.throttled = _.throttle(keyword => {
          this.isLoading = true;

          (() => {
            if (caches.hasOwnProperty(keyword)) {
              return new Promise(resolve => resolve(caches[keyword]));
            } else {
              return this.ajax({
                method: 'GET',
                url: `/aspect/search/suggest`,
                params: {
                  keyword: keyword
                }
              }, false)
              .then(response => response.data)
              .catch(() => null);
            }
          })()
          .then(data => {
            this.isLoading = false;

            if (data === null) {
              return;
            }

            caches[keyword] = _.cloneDeep(data);

            this.suggestions = data.map(suggestion => {
              return {
                label: suggestion.keyword,
                value: suggestion
              };
            });

            this.createdAwesomplete.list = this.suggestions;
          });

        }, 500);
      }

      // 矢印キーの場合は取得しない
      if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
        return;
      }

      // 入力がある場合取得
      if (keyword.length > 0) this.throttled(keyword);
    },

    // キーワード入力エリアを初期化
    initSearchInput () {
      let input = document.getElementsByClassName('search-area')[0];
      let awesomplete = new Awesomplete(input, {
        minChars: 1,
        item: (suggest, inputting) => {
          let
            li = document.createElement('li'),
            i = document.createElement('i'),
            label,
            typeLabel,
            typeClass;

          if (suggest.value.type === 'tag') {
            typeLabel = 'タグ：';
            typeClass = 'tag';
          } else if (suggest.value.type === 'brand') {
            typeLabel = 'ブランド：';
            typeClass = 'brand';
          } else if (suggest.value.type === 'circle') {
            typeLabel = 'サークル：';
            typeClass = 'circle';
          } else if (suggest.value.type === 'books') {
            typeLabel = '出版社：';
            typeClass = 'publisher';
          } else {
            typeLabel = '作品：';
            typeClass = 'work';
          }

          li.setAttribute('aria-selected', 'false');
          i.classList.add(typeClass);
          i.innerText = typeLabel;
          li.appendChild(i);
          label = document.createTextNode(suggest.value.keyword);
          li.appendChild(label);

          return li;
        },
        replace: suggestion => {
          input.value = suggestion.value.keyword;
          this.searching.keyword = suggestion.value.keyword;
          this.searching.id = suggestion.value.id;
          this.searching.tally = suggestion.value.keyword;
        }
      });

      this.createdAwesomplete = awesomplete;
    },

    /**
     * 検索実行
     * @param  {String} prop  [プロパティ名]
     * @param  {String} value [プロパティ値]
     */
    search (prop = 'keyword', value = this.searching.keyword) {
      let
        content = location.pathname.split('/')[1],
        query = `/search/contents?`;

      if (content !== 'talk' && content !== 'matome') {
        content = 'antenna';
      }

      if (prop === 'tag') value = encodeURIComponent(value);

      location.href = `${query}content=${content}s&${prop}=${value}`;
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.searching.keyword = document.getElementById('search-word').getAttribute('data-value');

      document.body.addEventListener('click', e => {
        this.showUserMenu = false;

        if (!e.target.classList.contains('global-menu-inner')) this.isMenuOpened = false;
        if (e.target.classList.contains('global-search') && !e.target.classList.contains('search-box')) this.isSearchOpened = false;
      });

      // キーワードサジェストから選択した時、選択した要素による検索を行う
      document.getElementsByClassName('global-search')[0].addEventListener('awesomplete-select', selection => {
        let prop, value;
        this.isSearching = true;

        if (selection.text.value.type === 'tag') {
          prop = 'tag';
          value = selection.text.value.keyword;
        } else if (selection.text.value.type === 'brand'
          || selection.text.value.type === 'circle'
          || selection.text.value.type === 'books') {
          prop = 'maker_id';
          value = selection.text.value.id;
        } else if (selection.text.value.type === 'antenna') {
          // 作品を選択した場合、アンテナ詳細ページを表示
          location.href = `/antenna/${selection.text.value.id}`;
          return;
        } else {
          prop = 'keyword';
          value = this.searching.keyword
        }

        this.search(prop, value);
      });

      this.initSearchInput();

      // スマホ表示時のヘッダ追従
      let header = document.getElementsByClassName('l-header')[0];

      let headroom = new Headroom(header, {
        offset: 60,
        tolerance: {
          down: 10,
          up: 20
        },
        classes: {
          pinned: 'is-tracking'
        }
      });

      headroom.init();
    });
  }
});

module.exports = Header;