/**
 * バリデーション
 */

export default class Validation {
  /**
   * 文字列長
   * サロゲートペアは考慮しない(実文字数 < 検証文字数になるが許容)
   * @param  {String || number} val [対象文字列 || 対象数値列]
   * @param  {number} maxSize       [最大文字数]
   * @return {boolean}              [検証結果]
   */
  maxSize (val, maxSize) {
    var _val = (typeof val === 'number') ? val.toString() : val;

    return _val.length <= maxSize;
  }

  /**
   * URL 形式
   * @param  {String} url [対象 URL]
   * @return {boolean}    [検証結果]
   */
  urlFormat (url) {
    return (url.length === 0 || url.match(/^https?:\/\/.+/)) ? true : false;
  }

  /**
   * メールアドレス形式
   * @param  {String} email [対象メールアドレス]
   * @return {boolean}      [検証結果]
   */
  emailFormat (email) {
    return (email.match(/.+@.+\..+/)) ? true : false;
  }

  /**
   * クリエイター形式
   * @param  {Object} creator [クリエイターオブジェクト]
   * @return {boolean}        [検証結果]
   */
  creatorFormat (creator) {
    if (!(this.maxSize(creator.name, 40) && this.maxSize(creator.comment, 50))) return false;

    if (!creator.name) return false;

    return true;
  }

  /**
   * 全クリエイターの検証結果をチェック
   * @param  {Object} creatorsData [クリエイター集合オブジェクト
   *                               = { 職種A: クリエイター配列, 職種B: クリエイター配列... }
   *                               ]
   * @return {boolean} result     [検証結果]
   */
  checkAllCreatorsValidationResult (creatorsData) {
    var result = true;

    _.forEach(creatorsData, creatorsList => {
      if (_.some(creatorsList, ['_isValid', false])) {
        result = false;
      }
    });

    return result;
  }
}