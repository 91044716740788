/**
 * トーク: トークトップ画面
 */

import _ from 'lodash';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import '../utils/filters.js';

const TalkTop = Super.extend({
  data() {
    return {
      cpTalks: [],
      hotTalks: [],
      newTalks: [],
      categoryId: null,
      sideView: null,
      campaignKey: null,
    };
  },

  computed: {
    moreHrefParam() {
      let param = '';

      if (this.categoryId === 1) {
        param = '&ntag=大喜利';
      } else if (this.categoryId === 2) {
        param = '&tag=大喜利';
      }

      return param;
    }
  },

  methods: {
    fetchTalkLists() {
      this.ajax({
        method: 'GET',
        url: `/talk/api/talk-lists/${this.apiSuffix}`,
      }, true)
        .then(response => {
          this.cpTalks = _.values(response.data.campaign);
          this.hotTalks = _.values(response.data.rank);
          this.newTalks = _.values(response.data.new);
          this.sideView = response.data.sideView;

          this.$nextTick(() => {
            const images = document.querySelectorAll('.img-box > img');
            if (images.length > 0) objectFitImages(images);

            this.initTwitterWidget();
            this.isRendered = true;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.categoryId = 0;
  
      // 年齢認証表示判定
      if (this.userInfo.is_adult_mode && !this.userInfo.can_show_adult) {
        document.body.classList.add('is-scroll-lock');
        document.body.classList.add('adult-check');
      }
  
      this.fetchTalkLists();
    });
  },
});

module.exports = TalkTop;
