import Vue from 'vue';
import crossroads from 'crossroads';
import objectFitImages from 'object-fit-images';
import swal from 'sweetalert';
import userInfo from './stores/user-info.js';

// プロダクションビルドの場合デバッグツールを無効化
if (process.env.NODE_ENV === 'production') Vue.config.devtools = false;

// 対応ブラウザ判定
if (typeof history.pushState !== 'function') {
  swal({
    title: '対応環境外',
    text: "お使いの端末はDLチャンネルに対応していません。\n続行しても正常に動作しない可能性があります。",
    type: 'error',
    confirmButtonText: 'OK',
    confirmButtonColor: '#17A1E6',
  });
}

if (!(location.pathname === '/' || location.pathname.match(/(campaign|lp)\/.+/))) {
  const Header = require('./modules/header/header.js');
  const header = new Header({ el: '#header' });

  const AgeVerification = require('./modules/utils/age-verification.js');
  const ageVerification = new AgeVerification({ el: '#age-verification' });
}

// LP
crossroads.addRoute('/:?query:', () => {
  const Landing = require('./modules/static/landing.js');
  const landing = new Landing({ el: '#root' });
});

// 6周年キャンペーン
crossroads.addRoute('/campaign/6thslot:?query:', () => {
  const CampaignSlot = require('./modules/static/campaign-slot.js');
  const campaignSlot = new CampaignSlot({ el: '#campaign-slot' });
});

// キャンペーン
crossroads.addRoute('/campaign/{title}:?query:', () => {
  const Campaign = require('./modules/static/campaign.js');
  const campaign = new Campaign({ el: '#root' });
});

// 企画 LP 系(仮)
crossroads.addRoute('/lp/{title}:?query:', () => {
  const Campaign = require('./modules/static/campaign.js');
  const campaign = new Campaign({ el: '#root' });
});

// アンテナ: アンテナトップ画面
crossroads.addRoute('/antenna:?query:', () => {
  const AntennaTop = require('./modules/antenna/antenna-top.js');
  const antennaTop = new AntennaTop({ el: '#root' });
});

// アンテナ: 作品詳細画面
crossroads.addRoute('/antenna/{playId}:?query:', playId => {
  const AntennaDetail = require('./modules/antenna/antenna-detail.js');
  const antenaDetail = new AntennaDetail({ el: '#root' });

  antenaDetail.playId = playId;
});

// アンテナ: 作品データベース編集画面
crossroads.addRoute('/antenna/{playId}/edit:?query:', playId => {
  const AntennaStore = require('./modules/antenna/antenna-store.js');
  const antenaStore = new AntennaStore({ el: '#root' });

  antenaStore.playId = playId;
});

// アンテナ: 動画登録画面
crossroads.addRoute('/antenna/{playId}/videos/edit:?query:', playId => {
  const VideoStore = require('./modules/antenna/video-store.js');
  const videoStore = new VideoStore({ el: '#root' });

  videoStore.playId = playId;
});

// アンテナ: 関連コンテンツ画面
crossroads.addRoute('/antenna/{playId}/contents:?query:', (playId, query) => {
  const AntennaContent = require('./modules/antenna/antenna-content.js');
  const antennaContent = new AntennaContent({ el: '#root' });

  antennaContent.playId = playId;
  antennaContent.mode = 'matome';

  if (query) {
    if (query.mode === 'talk' || query.mode === 'matome') antennaContent.mode = query.mode;
  }
});

// トーク: トークトップ画面
crossroads.addRoute('/talk:?query:', () => {
  const TalkTop = require('./modules/talk/talk-top.js');
  const talkTop = new TalkTop({ el: '#root' });
});

// トーク: トーク作成画面
crossroads.addRoute('/talk/create:?query:', () => {
  const TalkStore = require('./modules/talk/talk-store.js');
  const talkStore = new TalkStore({ el: '#root' });

  talkStore.isNew = true;
});

// トーク: トーク詳細画面
crossroads.addRoute('/talk/{threadId}:?query:', (threadId, query) => {
  const TalkStore = require('./modules/talk/talk-store.js');
  const talkStore = new TalkStore({ el: '#root' });

  talkStore.isNew = false;
  talkStore.id = threadId;
});

// まとめ: まとめトップ画面
crossroads.addRoute('/matome:?query:', () => {
  const MatomeTop = require('./modules/matome/matome-top.js');
  const matomeTop = new MatomeTop({ el: '#root' });
});

// まとめ: 記事投稿画面
crossroads.addRoute('/matome/create:?query:', () => {
  const MatomeStore = require('./modules/matome/matome-store.js');
  const matomeStore = new MatomeStore({ el: '#root' });

  matomeStore.isNew = true;
});

// まとめ: 記事編集画面
crossroads.addRoute('/matome/{matomeId}/edit:?query:', matomeId => {
  const MatomeStore = require('./modules/matome/matome-store.js');
  const matomeStore = new MatomeStore({ el: '#root' });

  matomeStore.matomeId = ~~matomeId;
  matomeStore.isNew = false;
});

// まとめ: 記事閲覧画面
crossroads.addRoute('/matome/{matomeId}:?query:', matomeId => {
  const MatomeShow = require('./modules/matome/matome-show.js');
  const matomeShow = new MatomeShow({ el: '#root' });

  matomeShow.matomeId = ~~matomeId;
});

// 検索結果
crossroads.addRoute('/search/contents:?query:', query => {
  const SearchStore = require('./modules/search/search-store.js');
  const searchStore = new SearchStore({ el: '#root' });

  if (query) {
    let
      search = location.search.slice(1).split('&'),
      prop,
      keyword;

    search.some(param => {
      prop = param.split('=');

      if (prop[0] === 'keyword') {
        keyword = prop[1];
        keyword = decodeURIComponent(keyword.replace(/\+/g, ' ').trim());
        return true;
      }
    });

    searchStore.query = {
      keyword: keyword || null,
      tag: query.tag || null,
      content: query.content || 'matomes',
      category: ~~query.category || 0,
      sub_category: ~~query.sub_category || 0,
      work_type: query.work_type || 0,
      division: query.division || 0,
      gender: ~~query.gender || 0,
      age: ~~query.age || 0,
      year: query.year || 'all',
      os: query.os || 0,
      os_v: query.os_v || 0,
      page: query.page && ~~query.page > 0 ? query.page : 1,
      order_by: query.order_by || 'new',
      play_id: query.play_id || null,
      maker_id: query.maker_id || null,
      admin: ~~query.admin || 0,
      campaign_id: query.campaign_id || null,
      ntag: query.ntag || null
    };
  }

  searchStore.initialize();
});

// マイページ設定画面
crossroads.addRoute('/mypage/account', () => {
  const Setting = require('./modules/profile/settings.js');
  const settings = new Setting({ el: '#root' });
});

// マイページ画面
crossroads.addRoute('/mypage/:mode::?query:', mode => {
  const Mypage = require('./modules/profile/mypage.js');
  const mypage = new Mypage({ el: '#root' });

  mypage.isMypage = true;
  mypage.mode = mode || 'timeline';
});

// ユーザープロフィール画面
crossroads.addRoute('/profile/{profileId}/:mode::?query:', (profileId, mode) => {
  const Profile = require('./modules/profile/profile.js');
  const profile = new Profile({ el: '#root' });

  profile.id = ~~profileId;
  profile.mode = mode || 'timeline';
});

// お問い合わせ画面
crossroads.addRoute('/aspect/contact:?query:', () => {
  const Contact = require('./modules/contact/contact.js');
  const contact = new Contact({ el: '#root' });
});

// ヘルプ画面
crossroads.addRoute('/static/help:?query:', () => {
  const Help = require('./modules/static/help.js');
  const help = new Help({ el: '#root' });
});

// クリエイター申請画面
crossroads.addRoute('/aspect/creators', () => {
  const Creators = require('./modules/creators/creators.js');
  const creators = new Creators({ el: '#root' });
});

// ランキング画面
crossroads.addRoute('/ranking:?query:', () => {
  const RankingTop = require('./modules/ranking/ranking-top.js');
  const rankingTop = new RankingTop({ el: '#root' });
});

// マガジン編集画面
crossroads.addRoute('/magazine/{magazineId}/edit:?query:', magazineId => {
  const MagazineStore = require('./modules/magazine/magazine-store.js');
  const magazineStore = new MagazineStore({ el: '#root' });

  magazineStore.magazineId = ~~magazineId;
  magazineStore.isNew = false;
});

// マガジン閲覧画面
crossroads.addRoute('/magazine/{magazineId}:?query:', magazineId => {
  const MagazineShow = require('./modules/magazine/magazine-show.js');
  const magazineShow = new MagazineShow({ el: '#root' });

  magazineShow.magazineId =  ~~magazineId;
  magazineShow.search();
});

// URI をパース
userInfo.fetchUserInfo().then(() => {
  if (!document.body.classList.contains('_error')) {
    crossroads.parse(location.pathname + location.search);
  }
});

// object-fit polyfill
let images = document.querySelectorAll('.img-box > img');
if (images.length > 0) objectFitImages(images);
