/**
 * 基底コンポーネント
 */

import Vue from 'vue';
import Velocity from 'velocity-animate';
import axios from 'axios';
import UAParser from 'ua-parser-js';
import userInfo from '../../stores/user-info.js';
import EventBus from '../utils/event-bus';
import Sentry from '../../classes/sentry';
import store from '../../stores';
import { checkAutomated } from '../../utils/browser-checker';

const token = document.head.querySelector('meta[name="csrf-token"]');
const uaParser = new UAParser();
const deviceModel = uaParser.getDevice().model;

if (token && token.content) {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': token.content,
    'X-Requested-With': 'XMLHttpRequest'
  };
} else {
  console.error('CSRF token not found');
}

const sentry = new Sentry();
const client = sentry.getClient();

let Super = Vue.extend({
  data () {
    return {
      showNavigation: false,
      redirectTo: '/profile/login',
      noticeType: 'login',
      previousRequest: null,
      serverErrors: [],
      userInfo: userInfo.state,
      isRendered: false,
      cancelToken: {
        source: null,
        url: null,
      },
      hasModal: false
    };
  },

  computed: {
    loginUrl () {
      return `https://www.dlsite.com/home/login/=/_query/${encodeURIComponent(location.href)}`;
    },

    /**
     * クライアントでの閲覧か
     * @return {Boolean} [クライアントの場合 true]
     */
    isNest () {
      return /DLsiteNest/.test(navigator.userAgent);
    },

    /**
     * API 呼び出し時のパスの末尾
     * @return {String}
     */
    apiSuffix () {
      return this.userInfo.is_adult_mode ? 'adult' : 'general';
    }
  },

  events: {
    /**
     * ログイン誘導ナビゲーション表示
     */
    navigationCalled () {
      this.showNavigation = true;
    }
  },

  methods: {
    /**
     * ブラウザ自動化チェック
     * 自動化されていたらログを残す
     * @param {String} profileId プロフィールID
     * @param {String} command 実行コマンド
     */
    checkBrowser (profile_id = '', command) {
      const result = checkAutomated();
      if (result.isAutomated) {
        const postObject = {browser: result.browser, command: command, profile_id: profile_id};
        axios({url: '/api/browser-check',method:'POST',data: postObject}).catch(error => console.warn(error));
      }
    },
    /**
     * ログインチェック
     * ログインしていなければログイン誘導ボックスを表示してイベントをキャンセルする。
     * @param  {Number} isLogined [ログイン済み -> 1 / 未ログイン -> 0]
     * @param  redirectTo         [ログイン後のリダイレクト先パス]
     * @param  {Event} e          [クリックイベント]
     * @returns {Boolean}
     */
    loginCheck (isLogined, redirectTo, e) {
      if (!isLogined) {
        if (redirectTo) this.redirectTo = redirectTo;

        this.$nextTick(() => {
          this.showNavigation = true;
        });

        e.preventDefault();

        return false;
      }

      return true;
    },

    /**
     * ツイッターリンクを生成・返却
     */
    createTwitterLink () {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(location.href)}&text=${encodeURIComponent(document.title)}&hashtags=${encodeURIComponent('DLチャンネル')}`;
    },

    /**
     * viewport 切り替え
     */
    switchViewport () {
      let checkValue = screen.width;

      if (deviceModel === 'iPhone') {
        checkValue = window.orientation === 0 ? screen.width : screen.height;
      }

      if (checkValue < 768 || /^\/(campaign|lp)/.test(location.pathname)) {
        document.getElementById('viewport').setAttribute('content', 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover');
      } else {
        document.getElementById('viewport').setAttribute('content', 'width=960, viewport-fit=cover');
      }
    },

    /**
     * ajax 通信のラッパー
     * @param  {Object} options        [{ method, url[, params] }]
     * @param  {Boolean} handleOnError [例外発生時に処理をハンドリングするか]
     * @return {Promise}               [通信結果]
     */
    ajax (options, handleOnError = false) {
      if (this.cancelToken.url === options.url) {
        this.cancelToken.source.cancel('Operation canceled.');
      }

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      let setting = {
        method: options.method,
        url: options.url,
        cancelToken: source.token
      };

      if (options.params) setting.params = options.params;
      if (options.data) setting.data = options.data;

      this.cancelToken = {
        source: source,
        url: options.url
      };

      return axios(setting)
        .then(response => {
          console.warn('ajax success');

          this.cancelToken = {
            source: null,
            url: null
          };

          return response;
        })
        .catch(error => {
          const err = error.response

          if (axios.isCancel(error)) {
            console.warn('Request canceled', error.message);

            throw error;
          } else {
            console.warn('ajax error');

            this.cancelToken = {
              source: null,
              url: null
            };

            let errorObj = {
              status: err.status,
              statusText: err.statusText,
              data: err.data,
              url: err.config.url
            };

            if (!(err.status === 0 || err.status === 401 || err.status === 404)) {
              // キャンセル、未認証、404じゃなければエラーハンドリング

              const url = options.url.substr(0, options.url.indexOf('?'));

              if (url === '/matome/api/url-data' && err.status === 504) {
                // リンク先取得処理がタイムアウトした場合はエラーを通知しない
                throw error;
              }

              if (/^\//.test(errorObj.url)) {
                // DLチャンネルの中のエラーはキャプチャ
                  if (options.params) errorObj.params = options.params;
                  if (options.body) errorObj.body = options.body;

                  client.configureScope(scope => {
                    scope.setExtra('error', errorObj);
                    scope.setUser({ id: this.userInfo.is_admin ? 'admin' : this.userInfo.profile_id || 'guest' });
                  });

                  client.captureMessage(url);
              }

              if (handleOnError) {
                // 想定外のエラーの場合
                let err = {
                  type: 'serverError',
                  message: 'エラーが発生しました。画面を更新してください。'
                };

                this.serverErrors.push(err);
              }

            }

            throw error;
          }
        });
    },

    /**
     * Google アナリティクスにイベント送信
     * @param  {Object} eventParam [イベント情報]
     */
    sendGaEvent (eventParam) {
      console.log(eventParam);

      try {
        if (typeof gtag === 'function') {
          gtag('event', eventParam.eventAction, {
            event_category: eventParam.eventCategory,
            event_label: eventParam.eventLabel,
            value: eventParam.eventValue
          });
        }
      } catch (error) {
        client.configureScope(scope => {
          scope.setExtra('error', error);
          scope.setUser({ id: this.userInfo.is_admin ? 'admin' : this.userInfo.profile_id || 'guest' });
        });

        client.captureMessage('Error sendGaEvent');
      }
    },

    // twitter ウィジェットのセット
    initTwitterWidget () {
      !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");
    },

    scrollTop () {
      Velocity(document.body, 'scroll', { duration: 200, offset: 0 });
    },

    /**
     * 隣接要素数を取得
     * @param  {Object} el   [HTML Element]
     * @param  {String} name [クラス名]
     */
    getSiblingElemetsIndex (el, name) {
      let index = 1;
      let sib = el;

      while (sib = sib.previousElementSibling ) {
        if (sib.nodeName.toLowerCase() === name) {
          ++index;
        }
      }

      return index;
    },

    /**
     * a タグ要素のセレクタを辿る
     * @param  {Object} el [a タグ]
     * @return {Array}     [クラス名の配列]
     */
    getSelectorFromElement (el) {
      let names = [];
      if (!(el instanceof Element)) return names;

        while (el && el.nodeType === Node.ELEMENT_NODE) {
          let name = el.className.toLowerCase().split(' ')[0];

          let nodeName = el.nodeName.toLowerCase();
          if (el.id) {
              // id はページ内で一意となるため、これ以上の検索は不要
              name += '#' + el.id;
              names.unshift(name);

              break;
          }

          // 同じ階層に同名要素が複数ある場合は識別のためインデックスを付与する
          let index = this.getSiblingElemetsIndex(el, nodeName);
          if (1 < index) {
              name += `:nth-of-type(${index})`;
          }

          names.unshift(name);
          el = el.parentNode;
        }

        return names;
    },

    /**
     * バナ管セット
     */
    initBcs () {
      let script = document.createElement('script');

      // prod
      script.src = document.location.protocol + '//banner.eisys-bcs.jp/js/bcs.min.js';
      // stg(フレーム新規作成、動作確認など行いたい場合はこちらで動作確認する)
      // script.src = document.location.protocol + '//stg.banner.eisys-bcs.jp/js/bcs.min.js';
      document.body.appendChild(script);
    },

    /**
     * リンククリック時のハンドラ
     * @param  {Event} e [click イベント]
     */
    onClickAnchorHandler (e) {
      let item = e.target;

      while (item && item.nodeName && item.nodeName !== 'A') {
        item = item.parentNode;
      }

      if (item && item.nodeName && item.nodeName === 'A') {
        let selector = this.getSelectorFromElement(item);
        let selectorStr = '.' + selector.join(' .');

        this.sendGaEvent({
          eventCategory: item.href,
          eventAction: 'Tracking',
          eventLabel: `${location.pathname} ${selectorStr}`,
          eventValue: 1
        });
      }
    },

    /**
     * canonicalを追加する
     */
    addCanonical (query = '', url = '') {
      let canonicalUrl = location.href.split('?')[0];
      // ページ独自のURLにしたい場合
      if(url !== ''){
        canonicalUrl = url;
      }
      // ページ独自のクエリストリング追加
      canonicalUrl = canonicalUrl + query;

      const isCanonical = !!document.querySelector('link[rel="canonical"]');
      const link = isCanonical ?
          document.querySelector('link[rel="canonical"]') :
          document.createElement('link');
      link.setAttribute('rel', 'canonical')
      link.setAttribute('href', canonicalUrl)
      if (!isCanonical) {
        document.head.appendChild(link)
      }
    },
  },

  watch: {
    'userInfo.isInitialized' () {
      // Google アナリティクスにページビューを送信
      if (typeof gtag === 'function' && !this.userInfo.pageview_sent) userInfo.sendGaPageview();
    },

    isRendered () {
      // バナ管初期化
      this.initBcs();

      // リンクセレクタ解析
      document.body.addEventListener('click', this.onClickAnchorHandler);
    }
  },

  store,

  created () {
    this.ajax({method:'GET',url:'/api/restricted-domains'},false).then(
      response => {
        const domains = _.get(response, 'data.domains', []);
        this.$store.commit('restrictedDomains/setRestrictedDomains',domains);
      }
    )
  },

  mounted () {
    this.$nextTick(() => {
      // viewport の切り替え
      this.switchViewport();

      // ログイン誘導ボックス表示イベント設定
      document.body.addEventListener('click', () => {
        this.showNavigation = false;
      });

      // viewport 切り替えイベント設定
      window.addEventListener('resize', () => {
        this.switchViewport();
      });

      // 外部画像右クリック禁止
      document.body.addEventListener('contextmenu', event => {
        if (event.target.tagName === 'IMG' && /^https:\/\/media\.dlsite\.com/.test(event.target.src)) {
          event.preventDefault();
        }
      }, true);
    });
  }
});

module.exports = Super;
