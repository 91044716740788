/**
 * アンテナ: 作品詳細画面
 */

import Vue from 'vue';
import Super from '../utils/super.js';
import WatchUnit from '../utils/watch-unit.js';
import TalkResults from '../search/components/talk-results.js';
import MatomeResults from '../search/components/matome-results.js';

let AntennaContent = Super.extend({
  data () {
    return {
      playId: null,
      mode: null,
      items: {
        talk: [],
        matome: []
      },
      facet: {
        talk: 0,
        matome: 0
      },
      isAntenna: true,
      isLoading: false
    };
  },

  components: {
    'watch-unit-component': WatchUnit,
    'talk-results-component': TalkResults,
    'matome-results-component': MatomeResults
  },

  watch: {
    /**
     * 初回表示時 URL を掃除、関連コンテンツを取得
     */
    playId () {
      history.replaceState(null, null, `/antenna/${this.playId}/contents`);

      this.fetchContents('talk');
      this.fetchContents('matome');
    }
  },

  methods: {
    /**
     * 表示コンテンツ切り替え
     * @param  {String} mode [コンテンツ名]
     */
    changeMode (mode) {
      this.mode = mode;
    },

    /**
     * 検索実行
     */
    fetchContents (mode) {
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/search/api/global-search/product?play_id=${this.playId}&content=${mode}s`
      }, true)
      .then(response => {
        this.items[mode] = response.data[mode].items;

        if (this.items[mode].length > 20) this.items[mode] = this.items[mode].splice(0, 20);

        this.facet[mode] = response.data.facet.content[`${mode}s`];
        this.isLoading = false;
        this.isRendered = true;
      })
      .catch(error => {
        this.isLoading = false;
      });
    }
  }
});

module.exports = AntennaContent;