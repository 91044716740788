/**
 * アンテナ: 動画登録部
 */

import Vue from 'vue';
import EventBus from '../../utils/event-bus';

let VideoAction = Vue.extend({
  props: ['selectedVideo', 'isSubmitting'],

  data () {
    return {
      compName: 'videoAction',
      type: {
        label: '種類を選択',
        value: '',
        classes: {
          'is-opened': false
        }
      },
      validation: {
        video: {
          isValid: true,
          message: ''
        },
        type: {
          isValid: true,
          message: ''
        }
      }
    }
  },

  template: require('../views/video-action.html'),

  methods: {
    /**
     * バリデーション: 動画の種類
     */
    validateType () {
      this.validation.type.isValid = true;
      this.validation.type.message = '';

      if (!this.type.value) {
        this.validation.type.isValid = false;
        this.validation.type.message = '※動画の種類を選択してください。';
      }
    },

    /**
     * バリデーション: 動画の選択状態
     */
    validateVideo () {
      this.validation.video.isValid = true;
      this.validation.video.message = '';

      if (!this.$root.selectedVideo.classes['is-selected']) {
        this.validation.video.isValid = false;
        this.validation.video.message = '※動画を選択してください。';
      }
    },

    callModal (mediaType, searchMode, item) {
      EventBus.$emit('callModal', mediaType, searchMode, item);
    },

    /**
     * 動画の種類セレクトボックスの開閉
     */
    toggleSelectBox () {
      this.type.classes['is-opened'] = !this.type.classes['is-opened'];
    },

    /**
     * 動画の種類を選択
     * @param  {String} type      [動画の種類]
     * @param  {String} typeLabel [動画の種類のラベル]
     */
    selectVideoType (type, typeLabel) {
      EventBus.$emit('selectVideoType', type, typeLabel);
      this.type.label = typeLabel;
      this.type.value = type;

      this.validateType();
    },

    /**
     * 動画を登録
     */
    registerVideo () {
      this.validateVideo();
      this.validateType();

      if (!(this.validation.video && this.validation.type.isValid)) return;

      EventBus.$emit('save');
    }
  },

  created () {
    let self = this;

    document.body.onclick = () => {
      self.type.classes['is-opened'] = false;
    };
  }
});

module.exports = VideoAction;
