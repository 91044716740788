/**
 * 検索画面
 */

import _ from 'lodash';
import Velocity from 'velocity-animate';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import RelatedTags from './components/related-tags.js';
import AntennaResults from './components/antenna-results.js';
import TalkResults from './components/talk-results.js';
import MatomeResults from './components/matome-results.js';
import MagazineResults from './components/magazine-results.js';
import EventBus from '../utils/event-bus';

const SearchStore = Super.extend({
  data () {
    return {
      // 検索用パラメータ
      query: {
        keyword: null,
        tag: null,
        content: '',
        category: 0,
        sub_category: 0,
        work_type: 0,
        division: 0,
        age: 0,
        gender: 0,
        year: 'all',
        os: 0,
        os_v: 0,
        page: 1,
        order_by: null,
        play_id: null,
        maker_id: null,
        admin: 0,
        campaign_id: null,
        ntag: null
      },
      // メニューの class 状態
      conditionsClasses: {
        division: {
          isOpened: true
        },
        age: {
          isOpened: true
        },
        gender: {
          isOpened: true
        },
        year: {
          isOpened: true
        },
        os: {
          isOpened: true
        }
      },
      relatedTags: [],
      // 検索結果
      searchResult: {
        antenna: { items: [] },
        talk: { items: [] },
        matome: { items: [] },
        related_tags: [],
        // ファセットカウント
        facet: {
          content: {
            all: 0,
            antenna: 0,
            talks: 0,
            matomes: 0
          },
          category: {},
          division: {
            all: 0,
            DOJIN: 0,
            BUSINESS: 0
          },
          age_category: {
            all: 0,
            everyone: 0,
            r15: 0,
            r18: 0
          },
          sex_category: {
            all: 0,
            male: 0,
            female: 0,
            gay: 0
          },
          os: {
            PC: 0,
            SP: 0
          },
          year_ranges: {
            all: {
              label: 'すべて',
              count: 0
            },
            years: []
          }
        }
      },
      queryObj: {},
      originalResultText: '',
      isLoading: false
    };
  },

  computed: {
    /**
     * 検索結果用テキスト
     */
    resultText() {
      let headText, noResultText;

      if (this.query.tag) {
        headText = `「${this.originalResultText}」`;
        noResultText = `タグ「${this.originalResultText}」`;
      } else if (this.query.maker_id) {
        headText = `「${this.originalResultText}」`;
        noResultText = `メーカー「${this.originalResultText}」`;
      } else if (this.query.keyword) {
        headText = `「${this.originalResultText}」`;
        noResultText = `キーワード「${this.originalResultText}」`;
      } else {
        headText = 'すべて';
        noResultText = '';
      }

      return {
        headText: headText,
        noResultText: noResultText
      };
    },

    /**
     * 検索結果エリアのオフセットトップ
     */
    offsetTop () {
      return document.getElementById('header').offsetTop;
    }
  },

  components: {
    'related-tags-component': RelatedTags,
    'antenna-results-component': AntennaResults,
    'talk-results-component': TalkResults,
    'matome-results-component': MatomeResults,
    'magazine-results-component': MagazineResults,
  },

  methods: {
    /**
     * 初回表示時の条件反映
     */
    initialize () {
      this.createQueryObj();

      // 絞り込みがアンテナ以外なら、性別・年齢条件を解除
      if (this.query.content !== 'antennas') {
        this.query.age = this.queryObj.age = 0;
        this.query.gender = this.queryObj.gender = 0;
        this.query.work_type = this.queryObj.work_type = 0;
      }

      this.$nextTick(() => {
        // 並び替え条件の反映
        if (this.query.content !== 'all' && this.query.order_by !== null) {
          this.$children.forEach(child => {
            if (child.compName === this.query.content) {
              child.$emit('initialSearch', this.query.order_by);
            }
          });
        }
      });

      this.search(!!this.query.tag);
    },

    /**
     * 検索実行
     * @param  {boolean} tagMode = false [タグ検索か]
     */
    search (tagMode = false) {
      this.isLoading = true;

      let path;

      if (tagMode) {
        // タグ検索
        path = '/search/api/global-tag-search/product';
      } else {
        // キーワード検索
        path = '/search/api/global-search/product';
      }

      // urlにplay_idが付いているときはcanonical変える
      let play_id = this.existsQueryString('play_id');
      if (play_id === false) {
        this.addCanonical('?content=' + this.query.content + '&page=' + this.query.page + '&order_by=new');
      } else {
        this.addCanonical('', location.protocol + '//' + location.host + '/antenna/' + play_id + '/contents');
      }

      this.ajax({
        method: 'GET',
        url: path,
        params: this.query,
      }, false)
      .then(response => {
        this.searchResult = response.data;
        this.isLoading = false;

        // meta robots取得
        let metaRobots = document.querySelector('meta[name="robots"]');
        // 検索結果0件の場合noindex付与
        if(this.searchResult.facet.content.all === 0) {
          // meta robotsなければmetaタグ追加
          if (!metaRobots) {
            metaRobots = document.createElement('meta');
            metaRobots.setAttribute('name', 'robots');
            document.head.appendChild(metaRobots);
          }
          // noindex付与
          metaRobots.setAttribute('content', 'noindex');
        } else {
          if(metaRobots) {
            // 検索結果あればmeta robots削除
            document.head.removeChild(metaRobots);
          }
        }

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.isRendered = true;
        });
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
      });

      Velocity(document.body, 'scroll', { duration: 200, offset: this.offsetTop });
    },

    /**
     * カテゴリ選択
     * @param  {number} categoryId        [カテゴリ ID]
     * @param  {number} subCategoryId = 0 [サブカテゴリ ID]
     */
    selectCategory (categoryId, subCategoryId = 0) {
      this.createQueryObj();

      if (categoryId == this.query.category && subCategoryId == this.query.sub_category) return;

      this.query.category = this.queryObj.category = categoryId;
      this.query.sub_category = this.queryObj.sub_category = subCategoryId;
      this.query.page = this.queryObj.page = 1;

      this.search(!!this.query.tag);
      this.pushState();
    },

    /**
     * 対応 OS 選択
     * @param  {number} os          [OS の種類]
     * @param  {number} version = 0 [サブカテゴリ ID]
     */
    selectOs (os, version = 0) {
      this.createQueryObj();

      if (os === this.query.os && version === this.query.os_v){
        this.query.os = this.queryObj.os = 0;
        this.query.os_v = this.queryObj.os_v = 0;
        this.query.page = this.queryObj.page = 1;
      } else {
        this.query.os = this.queryObj.os = os;
        this.query.os_v = this.queryObj.os_v = version;
        this.query.page = this.queryObj.page = 1;
      }


      this.search(!!this.query.tag);
      this.pushState();
    },

    /**
     * 絞り込み条件をトグル
     * @param  {String} name                 [絞り込み条件]
     * @param  {String || number} val        [条件値]
     * @param  {String || number} defaultVal [デフォルト値]
     */
    toggleCondition (name, val, defaultVal) {
      this.createQueryObj();
      this.query.page = this.queryObj.page = 1;

      if (name === 'content') {
        // 対象サービスが変更された場合、絞り込み条件をリセット
        if (this.query.content !== val) this.resetNarrowedCondition();

        this.query[name] = this.queryObj[name] = val;

        this.$children.forEach(child => {
          if (child.compName === val) {
            child.$emit('conditionReset');
          }
        });
      } else {
        if (this.query[name] === val) {
          this.query[name] = this.queryObj[name] = defaultVal;
        } else {
          this.query[name] = this.queryObj[name] = val;
        }
      }

      this.search(!!this.query.tag);
      this.pushState();
    },

    /**
     * 絞り込みメニューの開閉
     * @param  {String} type [絞り込み条件]
     */
    toggleAccordion (type) {
      this.conditionsClasses[type].isOpened = !this.conditionsClasses[type].isOpened;
    },

    /**
     * OS メニューの開閉
     * @param  {String} type ['pc' || 'sp']
     */
    toggleOsItems (type) {
      this.conditionsClasses[type].isOpenable = !this.conditionsClasses[type].isOpenable;
      this.conditionsClasses[type].isClosable = !this.conditionsClasses[type].isClosable;
    },

    /**
     * 絞り込み条件のリセット
     */
    resetNarrowedCondition () {
      this.query.division = this.queryObj.division = 0;
      this.query.work_type = this.queryObj.work_type = 0;
      this.query.age = this.queryObj.age = 0;
      this.query.gender = this.queryObj.gender = 0;
      this.query.year = this.queryObj.year = 'all';
      this.query.os = this.queryObj.os = 0;
      this.query.os_v = this.queryObj.os_v = 0;
      this.query.page = this.queryObj.page = 1;
      this.query.order_by = this.queryObj.order_by = 'new';
      this.query.admin = this.queryObj.admin = 0;

      this.$children.forEach(child => {
        child.$emit('conditionReset');
      });
    },

    /**
     * 履歴追加
     */
    pushState () {
      let state = this.copyState();
      history.pushState(state, null, this.buildQueryString());

      gtag('set', {'page_path': location.pathname + location.search});
      gtag('event', 'page_view');
    },

    /**
     * 現在のクエリの状態を複製
     */
    copyState () {
      return {
        query: _.cloneDeep(this.query)
      };
    },

    /**
     * 現在のクエリストリングをオブジェクトに変換して保持
     */
    createQueryObj () {
      let
        params = location.search.split(/[?&]/),
        param,
        key,
        val;

      params.forEach(p => {
        param = p.split('=');
        key = param[0];
        val = param[1];

        if (key) {
          this.queryObj[key] = val;
        }
      });
    },

    /**
     * history 用クエリストリングを作成
     */
    buildQueryString () {
      let query = 'contents?';

      Object.keys(this.queryObj).forEach(key => {
        if (this.queryObj[key] != 0 && this.queryObj[key] !== 'all' && this.queryObj[key] !== null) {
          query += `${key}=${this.queryObj[key]}&`;
        }
      });
      return query.slice(0, -1);
    },

    /**
     * ページング
     * @param  {number} page [ページ数]
     */
    changePage (page) {
      this.createQueryObj();

      this.query.page = this.queryObj.page = page;

      this.search(!!this.query.tag);
      this.pushState();
    },

    /**
     * クエリストリングに特定文字列があるか調査
     */
    existsQueryString(param){
      // 現在のURLをURLオブジェクト化
      const url = new URL(window.location.href, 'https://ch.dlsite.com');
      const searchParams = url.searchParams;
      if (typeof searchParams === 'object' && searchParams.has(param)) {
        return searchParams.get(param);
      }
      return false;
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.originalResultText = document.getElementById('result-text').value;
      EventBus.$on('changePage', this.changePage);
      EventBus.$on('toggleCondition', this.toggleCondition);
    });
  },

  created () {
    window.onpopstate = e => {
      if (e.state) {
        this.query = e.state.query;
      } else {
        this.resetNarrowedCondition();
        this.query.category = 0;
      }

      this.search(!!this.query.tag);
    };
  }
});

module.exports = SearchStore;
