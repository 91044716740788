/**
 * アンテナ: 作品DB編集画面
 */

import Vue from 'vue';
import _ from 'lodash';
import HasModal from '../utils/has-modal.js';
import MusicsDataAction from './components/musics-data-action.js';
import CreatorsDataAction from './components/creators-data-action.js';
import AntennaDbSchema from './antenna-db-schema.js';
import jobLabels from './job-labels.json';
import Validation from '../utils/validation.js';
import EventBus from '../utils/event-bus';

const validation = new Validation();
let initialData;

let AntennaStore = HasModal.extend({
  data () {
    return {
      playId: null,
      // 作品情報
      formData: new AntennaDbSchema(),
      // 編集内容の要約
      comment: null,
      // JSON文字列
      json: null,
      published_at: null,
      category: null,
      validation: {
        comment: {
          isValid: true,
          message: ''
        }
      },
      jobLabels: jobLabels,
      isSubmitting: false
    };
  },

  computed: {
    // 入力値判定
    isValid () {
      if (!(this.comment && this.validation.comment)) {
        return false;
      }

      let state = true;

      // 作品情報・クリエイター情報の全項目のバリデーション結果をチェック
      this.$children.forEach(child => {
        if (child.compName === 'creatorsData' || child.compName === 'musicsData') {
          if (state && !child.isValid) {
            state = false;
          }
        }
      });

      return state;
    }
  },

  components: {
    'musics-data-action-component': MusicsDataAction,
    'creators-data-action-component': CreatorsDataAction
  },

  methods: {
    /**
     * バリデーション: 編集内容の要約
     */
    validateComment () {
      this.validation.comment.isValid = true;
      this.validation.comment.message = '';

      if (this.comment.trim().length === 0) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※編集内容の要約の入力は必須です。';

        return;
      }

      if (!validation.maxSize(this.comment, 80)) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※80字以内で入力してください。';

        return;
      }
    },

    /**
     * クリエイター追加
     * @param  {Array} job      [クリエイター配列]
     * @param  {Object} creator [クリエイターオブジェクト]
     */
    appendCreator (job, creator) {
      job.push(creator);
    },

    /**
     * クリエイター削除
     * @param  {Array} job      [クリエイター配列]
     * @param  {Number} index   [削除対象のインデックス]
     */
    removeCreator (job, index) {
      job.splice(index, 1);

      this.$children.forEach(child => {
        if (child.compName === 'creatorsData' || child.compName === 'musicsData') child.validateAll();
      });
    },

    /**
     * 楽曲の種類選択
     * @param  {Number} index [対象楽曲のインデックス]
     * @param  {String} val   [楽曲の種類]
     */
    selectMusicType (index, val) {
      this.formData.musics_data[index].type = val;
    },

    /**
     * 楽曲追加
     * @param  {Object} music [楽曲オブジェクト]
     */
    appendMusic (music) {
      this.formData.musics_data.push(music);
    },

    /**
     * 楽曲削除
     * @param {Number} index [削除対象のインデックス]
     */
    removeMusic (index) {
      this.formData.musics_data.splice(index, 1);

      this.$children.forEach(child => {
        if (child.compName === 'creatorsData' || child.compName === 'musicsData') child.validateAll();
      });
    },

    /**
     * 保存実行
     */
    save () {
      this.serverErrors = [];

      if (JSON.stringify(this.formData) === JSON.stringify(initialData)) {
        // 編集されていない場合は中断
          this.validation.comment.isValid = false;
          this.validation.comment.message = '※1項目以上編集してください。';

          return;
        }

      this.$children.forEach(child => {
        if (child.compName === 'creatorsData' || child.compName === 'musicsData') child.validateAll();
      });

      this.$nextTick(() => {
        if (!this.isValid) return;

        this.isSubmitting = true;

        let
          dataClone = _.cloneDeep(this.formData),
          _dataClone = {};

        // 保存用データの作成
        do {
          _dataClone = _.cloneDeep(dataClone);

          // データの整理
          _.forEach(dataClone, (value, key) => {
            this.modifyPostData(dataClone, key);
          });
        } while (!_.isEqual(dataClone, _dataClone));

        this.json = JSON.stringify(dataClone);

        this.$nextTick(() => {
          let data = {
            play_id: this.playId,
            json: this.json,
            published_at: this.published_at,
            comment: this.comment,
            profile: 1
          };

          window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);

          this.ajax({
            method: 'PUT',
            url: `/antenna/${this.playId}`,
            data: data
          }, false)
          .then(response => {
            if (typeof ga === 'function') {
              this.sendGaEvent({
                eventCategory: 'AntennaProduct',
                eventAction: 'Edit',
                eventLabel: `play/${this.playId}`,
                eventValue: 1
              });

              this.sendGaEvent({
                eventCategory: 'AntennaProduct',
                eventAction: 'Edit',
                eventLabel: `category/${this.category}`,
                eventValue: 1
              });
            }

            location.href = `/antenna/${this.playId}`;
          })
          .catch(error => {
            console.log(error);

            this.isSubmitting = false;

            if (error.status === 422) {
              this.setValidationResult(error.data);
            } else if (error.status === 500) {
              let error = {
                type: 'serverError',
                id: `#`,
                message: '保存に失敗しました。画面を更新してください。'
              };

              this.serverErrors.push(error);
            }
          });
        });
      });
    },

    /**
     * submit 時のバリデーションの結果をセット
     * @param {Object} responseData [バリデーション結果]
     */
    setValidationResult (responseData) {
      let
        isConfrict = false,
        error = {};

      this.serverErrors = [];

      Object.keys(responseData).every(index => {
        let
          data = index.match(/([^\[\]\.]+)(\[(\d)\])?.*/)[1];
        if (data === 'published_at') {
          isConfrict = true;
          // 他のエラーはチェックしない
          return true;
        }
      });

      if (isConfrict) {
        // 競合の場合
        error = {
          type: 'confrict',
          id: `#`,
          message: '編集中に他のユーザーによって編集されました。画面を更新してください。'
        };

        this.serverErrors.push(error);

        return;
      }

      Object.keys(responseData).forEach(index => {
        let
          data = index.match(/([^\[\]\.]+)(\[(\d)\])?.*/)[1],
          message = responseData[index][0],
          preMatches,
          matches,
          musicIdx,
          job,
          jobIdx,
          prop;

        if (data === 'creators_data') {
          // クリエイターの場合
          matches = index.match(/([^\.]+)\.([^\[\]]+)\[(\d)\]\.([^\.]+)/);
          job = matches[2];
          jobIdx = ~~matches[3];
          prop = matches[4];

          console.warn(`job: ${job}\nindex: ${jobIdx}\nprop: ${prop}\nmessage: ${message}`);

          if (this.formData.creators_data[job][jobIdx]) {
            error = {
              type: 'creatorsData',
              job: job,
              index: jobIdx,
              id: `#${job}-${jobIdx}-input`,
              prop: prop,
              message: `${this.jobLabels[job]}(${jobIdx + 1}) ${message}`
            };

            this.formData.creators_data[job][jobIdx]._isValid = false;
            this.formData.creators_data[job][jobIdx]._message = `※${message}`;
          } else {
            error = {
              type: 'creatorsData',
              job: job,
              index: jobIdx,
              id: `#${job}-${jobIdx}-input`,
              prop: prop,
              message: 'データが不正です。このクリエイターを削除してください。'
            };
          }
        } else if (data === 'musics_data') {
          // 楽曲情報の場合
          preMatches = index.match(/([^\[\]]+)\[(\d)\]\.([^.]+).*/);
          musicIdx = ~~preMatches[2];

          if (preMatches[3] === 'creators') {
            // クリエイター情報の場合
            matches = index.match(/([^\[\]]+)\[(\d)\]\.([^.]+)\.([^\[\]]+)\[(\d)\]\.([^\.]+)/);
            job = matches[4];
            jobIdx = matches[5];
            prop = matches[6];

            error = {
              type: 'musicsData',
              index: musicIdx,
              job: job,
              jobIndex: jobIdx,
              id: `#m-${musicIdx}-${job}-${jobIdx}-input`,
              message: `${musicIdx + 1}曲目 ${message}`
            };

            console.warn(`musicIndex: ${musicIdx}\njob: ${job}\njobIndex: ${jobIdx}\nmessage: ${message}`);

            this.formData.musics_data[musicIdx].creators[job][jobIdx]._isValid = false;
            this.formData.musics_data[musicIdx].creators[job][jobIdx]._message = `※${message}`;
          } else {
            prop = preMatches[3];

            error = {
              type: 'musicsData',
              index: musicIdx,
              prop: prop,
              id: `#m-${musicIdx}-${prop}`,
              message: `${musicIdx + 1}曲目 ${message}`
            };

            console.warn(`musicIndex: ${musicIdx}\nprop: ${prop}\nmessage: ${message}`);

            this.formData.musics_data[musicIdx]._validation[prop].isValid = false;
            this.formData.musics_data[musicIdx]._validation[prop].message = `※${message}`;
          }
        } else {
          if (this.validation[data]) {
            // 編集内容の要約の場合
            error = {
              type: data,
              id: `#${data}`,
              message: message
            };

            this.validation[data].isValid = false;
            this.validation[data].message = `※${message}`;
          } else {
            error = {
              type: data,
              id: '#',
              message: message
            };
          }
        }

        this.serverErrors.push(error);
      });
    },

    /**
     * 送信するデータから、未入力のプロパティ・描画用の一時的なプロパティを削除
     * @param  {Object} value [プロパティ値]
     * @param  {String} key   [プロパティ]
     */
    modifyPostData (value, key) {
      if (!value[key] || /\b_/.test(key)) {
        delete value[key];
      } else {
        if (_.isObject(value[key])) {
          // 空オブジェクト・空配列を削除
          if (_.size(value[key]) === 0) {
            delete value[key];
          } else {
            _.forEach(value[key], (_value, _key) => {
              this.modifyPostData(value[key], _key);

              if (_.isArray(value[key])) {
                value[key] = _.compact(value[key]);
              }
            });
          }
        }
      }
    },

    /**
     * 楽曲オブジェクトのマージ
     *
     * テンプレートとなるオブジェクトとマージして初期化する。
     * @param  {Object} musicsData [楽曲オブジェクト配列]
     */
    mergeMusicsData (musicsData) {
      let musicTemplate = {
       type: '',
       title: null,
       creators: {
         artists: [],
         lyricists: [],
         composers: [],
         arrangers: []
       },
       _validation: {
         type: {
           isValid: true,
           message: ''
         },
         title: {
           isValid: true,
           message: ''
         }
       }
     };

      Object.keys(musicsData).forEach(index => {
        Object.keys(musicTemplate).forEach(prop => {
          if (prop === 'creators') {
            if (!musicsData[index].creators) musicsData[index].creators = {};

            Object.keys(musicTemplate.creators).forEach(job => {
              if (musicTemplate.creators.hasOwnProperty(job) && !musicsData[index].creators.hasOwnProperty(job)) {
                // マージする楽曲に存在しない職種がある場合、空配列を追加
                musicsData[index].creators[job] = [];
              }
            });
          } else {
            if (musicTemplate.hasOwnProperty(prop) && !musicsData[index].hasOwnProperty(prop)) {
              // マージする楽曲に存在しないプロパティがある場合、テンプレートからコピー
              musicsData[index][prop] = _.cloneDeep(musicTemplate[prop]);
            }
          }
        });
      });

      return musicsData;
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler (e) {
      const message = 'このページから移動してもよろしいですか？';

      if (JSON.stringify(this.formData) !== JSON.stringify(initialData)) {
        e.returnValue = message;
        return message;
      }
    }
  },

  watch: {
    playId () {
      // 作品データベース情報取得
      this.ajax({
        method: 'GET',
        url: `/antenna/api/antenna-data/${this.playId}`
      }, true)
      .then(response => {
        if (_.size(response.data) > 0) {
          // 楽曲情報
          if (response.data.musics_data) {
            let _musicsData = response.data.musics_data;

            this.formData.musics_data = this.mergeMusicsData(_musicsData);
          } else {
            this.formData.musics_data = [];
          }

          // クリエイター情報
          if (response.data.creators_data) {
            _.forEach(this.formData.creators_data, (creatorsList, key, creatorsData) => {
              if (response.data.creators_data[key]) {
                creatorsData[key] = response.data.creators_data[key];
              }
            });
          }
        }

        // 初期状態の保存
        initialData = _.cloneDeep(this.formData);
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      let self = this;
      // 初期状態の保存
      initialData = _.cloneDeep(self.formData);
  
      window.addEventListener('beforeunload', self.onBeforeUnloadHandler);
      this.isRendered = true;

      this.published_at = document.getElementById('published_at').value;
      this.category = document.getElementById('category').value;

      EventBus.$on('appendCreator', this.appendCreator);
      EventBus.$on('removeCreator', this.removeCreator);
      EventBus.$on('appendMusic', this.appendMusic);
      EventBus.$on('removeMusic', this.removeMusic);
      EventBus.$on('selectMusicType', this.selectMusicType);
    });
  }
});

module.exports = AntennaStore;