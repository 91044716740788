/**
 * ページネーション
 */

import Vue from 'vue';
import EventBus from '../event-bus';

let Pagination = Vue.extend({
  props: ['currentPage', 'count', 'position', 'viewsPerPage'],

  data () {
    return {
      paginationsNumber: {
        all: 5,
        center: 3
      },
      pages: [],
    };
  },

  computed: {
    // 現在の表示開始件数
    currentViewsFrom () {
      if (this.currentPage < 0 || this.currentPage > this.lastPage.num) {
        return 0;
      } else {
        return this.count > 0 ? (this.currentPage - 1) * this.viewsPerPage + 1 : 0;
      }
    },

    // 現在の表示終了件数
    currentViewsTo () {
      if (this.currentPage < 0 || this.currentPage > this.lastPage.num) {
        return 0;
      } else {
        return (this.count < this.currentPage * this.viewsPerPage) ? this.count : this.currentPage * this.viewsPerPage;
      }
    },

    // 最後のページ
    lastPage () {
      let lastPageNumber = Math.ceil(this.count / this.viewsPerPage);

      let page = {
        num: lastPageNumber,
        classes: {
          'is-current': ~~this.currentPage === lastPageNumber,
          'last-page': (~~this.currentPage < lastPageNumber - 2) && (lastPageNumber > 5)
        }
      };

      return page;
    },

    // ページネーションの表示
    paginationIsShown () {
      return (this.count > this.viewsPerPage);
    }
  },

  template: require('../views/pagination.html'),

  watch: {
    currentPage () {
      this.createPagenation();
    },

    count () {
      this.createPagenation();
    }
  },

  methods: {
    // ページ移動
    setPage (page) {
      EventBus.$emit('changePage', page);
    },

    // ページネーションの生成
    createPagenation () {
      let
        currentPage = ~~this.currentPage,
        i,
        ii;

      this.pages = [{
        num: 1,
        classes: {
          'is-current': currentPage === 1,
          'first-page': currentPage > 3 && this.lastPage.num > this.paginationsNumber.all
        }
      }];

      if (this.lastPage.num <= this.paginationsNumber.all) {
        // 全5ページ以下の場合 [1, 2, 3, 4, 5]
        for (i = 2; i < this.lastPage.num; i++) {
          this.pages.push({
            num: i,
            classes: {
              'is-current': currentPage === i,
            }
          });
        }
      }

      if (this.lastPage.num > this.paginationsNumber.all) {
        // 全5ページより多い場合 [1, ... , lastPage]
        if (currentPage < 3) {
          // 前方ページが省略されない場合
          for (i = 2; i < this.paginationsNumber.all; i++) {
            this.pages.push({
              num: i,
              classes: {
                'is-current': currentPage === i,
              }
            });
          }
        } else if (currentPage > 2 && currentPage < this.lastPage.num - 1) {
          // 前方ページ・後方ページが省略される場合
          for (i = -1, ii = 0; ii < this.paginationsNumber.center; i++, ii++) {
            this.pages.push({
              num: currentPage + i,
              classes: {
                'is-current': currentPage === currentPage + i,
              }
            });
          }
        } else if (currentPage > this.lastPage.num - 3) {
          // 後方ページが省略されない場合
          for (i = -3, ii = 0; ii < this.paginationsNumber.center; i++, ii++) {
            this.pages.push({
              num: this.lastPage.num + i,
              classes: {
                'is-current': currentPage === this.lastPage.num + i,
              }
            });
          }
        }
      }

      this.pages.push(this.lastPage);

      // 親コンポーネントに表示用の件数をセット
      if (this.currentPage < 0 || this.currentPage > this.lastPage.num) {
        this.$parent.currentViewsFrom = 0;
        this.$parent.currentViewsTo = 0;
        this.$parent.count = 0;
      } else {
        this.$parent.currentViewsFrom = this.currentViewsFrom;
        this.$parent.currentViewsTo = this.currentViewsTo;
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.createPagenation();
    });
  }
});

module.exports = Pagination;