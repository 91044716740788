/**
 * 6周年キャンペーンのスロット
 */

import Vue from 'vue';
import SLOTs from './6th_slot/slots.json';
const SLOT_SUFFIX = [
    'する話の紹介！',
    '思い出/体験談'
];

let CampaignSlot = Vue.extend({
    template: require('./views/campaign-slot.html'),
    data() {
        return {
            slot: {
                0: {
                    currentReels: [],
                    slotTimeoutId: [],
                    isFinished: false,
                    result: '',
                    createMatomeUrl: '',
                    tweetUrl: '',
                },
                1: {
                    currentReels: [],
                    slotTimeoutId: [],
                    isFinished: false,
                    result: '',
                    createMatomeUrl: '',
                    tweetUrl: '',
                }
            },
            SLOTs
        }
    },
    methods: {
        startSlot(slotIndex = 0) {
            this.initSlot(slotIndex);
            SLOTs[slotIndex].forEach((element, index) => {
                this.startReel(index, slotIndex);
            });
            this.slot[slotIndex].isFinished = false;
        },
        async stopSlot(slotIndex = 0) {
            const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
            for (let i = 0; i < SLOTs[slotIndex].length; i++) {
                await this.stopReel(i, slotIndex);
                await sleep(300);
            }
            this.slot[slotIndex].isFinished = true;
            this.slot[slotIndex].result = SLOTs[slotIndex].map((reel, index) => reel[this.slot[slotIndex].currentReels[index]]).join('') + SLOT_SUFFIX[slotIndex];
            // まとめ作成リンク生成
            this.slot[slotIndex].createMatomeUrl = '/matome/create?title=' + encodeURIComponent(this.slot[slotIndex].result);
            // ツイート用URL生成
            this.slot[slotIndex].tweetUrl = 'https://twitter.com/intent/tweet?text='
                + encodeURIComponent(this.slot[slotIndex].result)
                + '&url' + encodeURIComponent('https://ch.dlsite.com/campaign/6thslot')
                + '&hashtags=' + encodeURIComponent('DLチャンネル6周年');
        },
        startReel(reelIndex, slotIndex = 0) {
            this.slot[slotIndex].currentReels.splice(
                reelIndex,
                1,
                Math.floor(Math.random() * SLOTs[slotIndex][reelIndex].length)
            );
            this.slot[slotIndex].slotTimeoutId.splice(
                reelIndex,
                1,
                window.setTimeout(() => this.startReel(reelIndex, slotIndex), 50)
            );
        },
        stopReel(reelIndex, slotIndex = 0) {
            if (this.slot[slotIndex].slotTimeoutId[reelIndex] !== null) {
                window.clearTimeout(this.slot[slotIndex].slotTimeoutId[reelIndex]);
                this.slot[slotIndex].slotTimeoutId.splice(reelIndex, 1, null);
            }
        },
        initSlot(slotIndex = 0){
            this.slot[slotIndex].currentReels = SLOTs[slotIndex].map(() => 0);
            this.slot[slotIndex].slotTimeoutId = SLOTs[slotIndex].map(() => null);
        },
        copyTitle (slotIndex = 0, event) {
            if(event.target.firstElementChild.classList.contains('show')){
                event.target.firstElementChild.classList.remove('show');
            }
            navigator.clipboard.writeText(this.slot[slotIndex].result)
                .then(() => {
                    event.target.firstElementChild.classList.add('show');
                })
                .catch(e => {
                    console.error(e);
                })
        }
    },
    mounted() {
        this.startSlot(0);
        this.startSlot(1);
    }
});

module.exports = CampaignSlot;