/**
 * トーク
 */

import Vue from 'vue';
import _ from 'lodash';
import Velocity from 'velocity-animate';
import swal from 'sweetalert';
import objectFitImages from 'object-fit-images';
import HasModal from '../utils/has-modal.js';
import VoteUnit from '../utils/vote-unit.js';
import WatchUnit from '../utils/watch-unit.js';
import GlobalTagSelector from '../utils/global-tag-selector.js';
import TagSelector from '../utils/components/tag-selector.js';
import Validation from '../utils/validation.js';
import categories from '../utils/categories.json';
import EventBus from '../utils/event-bus';

const validation = new Validation();
let initialData;

let TalkStore = HasModal.extend({
  data () {
    return {
      id: null,
      // 新規トークか
      isNew: false,
      // 1: 作品関連, 2: フリートーク
      target: 0,
      title: {
        body: '',
        classes: {
          'is-error': false
        }
      },
      category: {
        id: '',
        subCategoryId: '',
        name: 'カテゴリを選択',
        classes: {
          'is-opened': false,
          'is-error': false
        }
      },
      ageCategory: null,
      thumbImg: {
        id: null,
        url: null
      },
      // 書き込み本文
      comment: {
        body: '',
        preview: null,
        attachment: 'image',
        image: {
          isComment: true,
          id: null,
          url: null
        },
        work: {
          isComment: true,
          playId: '',
          thumbUrl: null,
          workName: null,
          makerName: null,
          introS: null,
          categoryId: null,
          ageCategory: null,
          category: null,
          classes: {
            'is-error': false
          }
        },
        classes: {
          'is-error': false
        }
      },
      // 関連作品
      work: {
        playId: '',
        thumbUrl: null,
        workName: null,
        makerName: null,
        introS: null,
        categoryId: null,
        category: null,
        classes: {
          'is-error': false
        }
      },
      selectedTags: [],
      tagJson: null,
      isPreview: false,
      dropdownOpenedId: null,
      // バリデーション
      validation: {
        title: {
          isValid: true,
          message: ''
        },
        category: {
          isValid: true,
          message: ''
        },
        ageCategory: {
          isValid: true,
          message: ''
        },
        work: {
          isValid: true,
          message: ''
        },
        comment: {
          isValid: true,
          message: ''
        },
        thumbImg: {
          isValid: true,
          message: ''
        },
        image: {
          isValid: true,
          message: ''
        }
      },
      isSubmitting: false,
      hasError: false,
      isDropdownOpened: false,
      isAdultMode: null,
      threadAgeCategory: null,
      hasForcedState: true,
      forcedWatchingId: null,
      isForcedAnimate: false,
      isForcedActioned: false,
      forcedWatchingCount: 0,
    };
  },

  components: {
    'vote-unit-component': VoteUnit,
    'watch-unit-component': WatchUnit,
    'global-tag-selector-component': GlobalTagSelector,
    'tag-selector-component': TagSelector
  },

  computed: {
    /**
     * 投稿内容が全て適切か
     */
    isValid () {
      if (this.target) {
        // 新規トークの場合

        if (this.target == 2) {
          // フリートークの場合
          if (!(this.thumbImg.id && this.thumbImg.url && this.title.body.length && this.category.subCategoryId && this.ageCategory && this.comment.body)) {
            // 必須項目が不足してる場合は不正
            return false;
          }

          return this.validation.title.isValid
            && this.validation.category.isValid
            && this.validation.ageCategory.isValid
            && this.validation.comment.isValid
            && this.validation.thumbImg.isValid
            && this.validation.image.isValid;
        } else {
          // 作品に関連するトークの場合
          if (!(this.title.body.length && this.comment.body && this.work.playId)) {
            return false;
          }

          return this.validation.title.isValid
            && this.validation.comment.isValid
            && this.validation.work.isValid
            && this.validation.thumbImg.isValid
            && this.validation.image.isValid;
        }
      } else {
        // レス投稿の場合
        if (!this.comment.body) return false;

        return this.validation.comment.isValid
          && this.validation.image.isValid;
      }
    },

    /**
     * 本文入力エリアの行数
     * @return {Number} [description]
     */
    rows () {
      if (this.comment.body.match(/\n|\r\n/g)) {
        return this.comment.body.match(/\n|\r\n/g).length > 8 ? this.comment.body.match(/\n|\r\n/g).length + 2 : 10;
      } else {
        return 10;
      }
    },

    /**
     * 成人向け作品を検索させるか
     * @return {Boolean} [成人向け作品検索許可設定]
     */
    isAdultSearchAllowed () {
      // レスの場合、トークの設定に従う
      if (this.threadAgeCategory) return this.threadAgeCategory == 1 ? false : true;

      // 新規トークの場合
      if (this.target == 1) {
        // テーマトークの場合、現在の表示設定に従う
        return this.isAdultMode ? true : false;
      } else {
        // フリートークの場合、選択中の R18 設定ラジオボタンに従う
        return this.ageCategory == 1 ? false : true;
      }
    },

    /**
     * レスに添付する作品の play_id
     * @return {Number} [play_id]
     */
    resPlayId () {
      if (this.target == 1) {
        return this.work.playId;
      } else {
        return this.comment.work.playId;
      }
    }
  },

  watch: {
    /**
     * 新規トークかどうか
     * 初期表示時に設定。
     */
    isNew () {
      // 初期状態の保存
      initialData = this.isNew ? _.cloneDeep(this.$data) : _.cloneDeep(this.comment);
    },

    /**
     * サブカテゴリ ID
     */
    'category.subCategoryId' () {
      this.validateCategory();
    },

    /**
     * R18 設定
     */
    ageCategory () {
      if (this.ageCategory == 1 && this.comment.work.playId) {
        swal({
          title: '',
          text: 'R18設定が変更されたため、添付作品を解除します',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'キャンセル'
        }, isConfirm => {
          if (isConfirm) {
            this.removeWork();
          } else {
            this.ageCategory = this.ageCategory == 1 ? 2 : 1;
          }
        });
      }

      this.validateAgeCategory();
    },

    /**
     * テーマラジオボタン
     */
    target () {
      if (this.target == 1 && (this.comment.image.id || this.comment.work.playId)) {
        swal({
          title: '',
          text: 'テーマが変更されたため、添付画像・添付作品を解除します',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'キャンセル'
        }, isConfirm => {
          if (isConfirm) {
            this.removeImage('comment');
            this.removeWork();
          } else {
            this.target = 2;
          }
        });
      } else if (this.target == 2 && this.work.playId) {
        swal({
          title: '',
          text: 'テーマが変更されたため、作品の設定を解除します',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'キャンセル'
        }, isConfirm => {
          if (isConfirm) {
            this.work = {
              playId: '',
              thumbUrl: null,
              workName: null,
              makerName: null,
              introS: null,
              categoryId: null,
              category: null,
              classes: {
                'is-error': false
              }
            };

            this.validation.work = {
              isValid: true,
              message: ''
            }
          } else {
            this.target = 1;
          }
        });
      }
    },

    /**
     * 添付ラジオボタン
     */
    'comment.attachment' () {
      if (this.comment.attachment === 'image' && this.comment.work.playId) {
        swal({
          title: '',
          text: '添付作品を解除します',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'キャンセル'
        }, isConfirm => {
          if (isConfirm) {
            this.removeWork();
          } else {
            this.comment.attachment = 'work';
          }
        });
      } else if ((this.comment.attachment === 'work' && this.comment.image.id)
        || (this.comment.attachment === 'work' && !this.validation.image.isValid)) {
        swal({
          title: '',
          text: '添付画像を解除します',
          confirmButtonColor: '#17A1E6',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'キャンセル'
        }, isConfirm => {
          if (isConfirm) {
            this.removeImage('comment');
          } else {
            this.comment.attachment = 'image';
          }
        });
      }
    },

    /**
     * プレビュー表示状態か
     */
    isPreview () {
      let
        headerHeight = document.getElementById('header').clientHeight,
        offsetTop = 0;

      if (!this.isNew) {
        offsetTop = document.getElementById('input-area').offsetTop;
      }

      Velocity(document.body, 'scroll', { duration: 200, offset: headerHeight + offsetTop });
    }
  },

  methods: {
    /**
     * バリデーション: サムネイル
     */
    validateThumbImg () {
      this.validation.thumbImg.isValid = true;
      this.validation.thumbImg.message = '';

      if (!(this.thumbImg.id && this.thumbImg.url)) {
        this.validation.thumbImg.isValid = false;
        this.validation.thumbImg.message = '※サムネイル画像を選択してください。';

        return;
      }
    },

    /**
     * バリデーション: タイトル
     */
    validateTitle () {
      this.validation.title.isValid = true;
      this.validation.title.message = '';
      this.title.classes['is-error'] = false;

      if (this.title.body.trim().length === 0) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※トークタイトルを入力してください。';
        this.title.classes['is-error'] = true;

        return;
      }

      if (!validation.maxSize(this.title.body, 50)) {
        this.validation.title.isValid = false;
        this.validation.title.message = '※50字以内で入力してください。';
        this.title.classes['is-error'] = true;

        return;
      }
    },

    /**
     * バリデーション: カテゴリ
     */
    validateCategory () {
      this.validation.category.isValid = true;
      this.validation.category.message = '';
      this.category.classes['is-error'] = false;

      if (!this.category.subCategoryId) {
        this.validation.category.isValid = false;
        this.validation.category.message = '※カテゴリを選択してください。';
        this.category.classes['is-error'] = true;

        return;
      }
    },

    /**
     * バリデーション: 年齢区分
     */
    validateAgeCategory () {
      this.validation.ageCategory.isValid = true;
      this.validation.ageCategory.message = '';

      if (!this.ageCategory) {
        this.validation.ageCategory.isValid = false;
        this.validation.ageCategory.message = '※どちらかにチェックを入れてください。';

        return;
      }
    },

    /**
     * バリデーション: 関連作品
     */
    validateWork () {
      this.validation.work.isValid = true;
      this.validation.work.message = '';
      this.work.classes['is-error'] = false;

      if (!this.work.playId) {
        this.validation.work.isValid = false;
        this.validation.work.message = '※作品を選択してください。';
        this.work.classes['is-error'] = true;

        return;
      }
    },

    /**
     * バリデーション: コメント
     */
    validateComment () {
      this.validation.comment.isValid = true;
      this.validation.comment.message = '';
      this.comment.classes['is-error'] = false;

      if (this.comment.body.trim().length === 0) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※本文を入力してください。';
        this.comment.classes['is-error'] = true;

        return;
      }

      if (!validation.maxSize(this.comment.body, 1000)) {
        this.validation.comment.isValid = false;
        this.validation.comment.message = '※1000字以内で入力してください。';
        this.comment.classes['is-error'] = true;

        return;
      }
    },

    /**
     * 全項目をバリデート
     */
    validateAll () {
      if (this.target) {
        // 新規トークの場合
        if (this.target == 2) {
          // フリートークの場合
          this.validateThumbImg();
          this.validateTitle();
          this.validateCategory();
          this.validateAgeCategory();
          this.validateComment();
        } else {
          // 作品に関連するトークの場合
          this.validateThumbImg();
          this.validateTitle();
          this.validateWork();
          this.validateComment();
        }
      } else {
        // レス投稿の場合
        this.validateComment();
      }
    },

    /**
     * 選択画像を削除
     * @param  {String} type [サムネかコメントの添付画像か]
     */
    removeImage (type) {
      if (type === 'thumbImg') {
        this.thumbImg.id = null;
        this.thumbImg.url = null;
        this.validation.thumbImg.isValid = true;
        this.validation.thumbImg.message = '';
      } else {
        this.comment.image.id = null;
        this.comment.image.url = null;
        this.validation.image.isValid = true;
        this.validation.image.message = '';
      }
    },

    /**
     * 選択作品を削除
     */
    removeWork () {
      this.comment.work.playId = '';
      this.comment.work.thumbUrl = null;
      this.comment.work.workName = null;
      this.comment.work.makerName = null;
      this.comment.work.introS = null;
      this.comment.work.categoryId = null;
      this.comment.work.category = null;
    },

    /**
     * カテゴリセレクトボックスの開閉
     */
    toggleSelectBox () {
      this.category.classes['is-opened'] = !this.category.classes['is-opened'];
    },

    /**
     * カテゴリを選択
     * @param  {Number} categoryId     [カテゴリ ID]
     * @param  {Number} subCategoryId  [サブカテゴリ ID]
     * @param  {String} categoryName   [カテゴリラベル]
     * @param  {String} categoryValue [カテゴリ値]
     */
    selectCategory (categoryId, subCategoryId, categoryName, categoryValue) {
      this.category.id = categoryId;
      this.category.subCategoryId = subCategoryId;
      this.category.name = categoryName;
      this.category.value = categoryValue;
    },

    /**
     * アンカーを付けて入力フォームにフォーカス
     * @param  {Number} isLogined [ログイン済み -> 1 / 未ログイン -> 0]
     * @param  {Number} resNum    [対象のレス番号]
     * @param  {Event} e          [クリックイベント]
     */
    focusWithAnchor (isLogined, resNum, e) {
      if (!isLogined) {
        // 未ログインならキャンセル
        this.loginCheck(isLogined, null, e);
        return;
      }

      this.comment.body = `>>${resNum}\n`;

      let headerHeight = document.getElementById('header').clientHeight;
      let offsetTop = document.getElementById('input-area').offsetTop;
      Velocity(document.body, 'scroll', { duration: 200, offset: headerHeight + offsetTop });

      document.getElementById('comment-body').focus();
    },

    /**
     * レスメニューをトグル
     * @param  {Number} resNum = null [対象のレス番号]
     */
    toggleDropdownContainer (resNum = null) {
      if (resNum) {
        this.dropdownOpenedId = (this.dropdownOpenedId === resNum) ? null : resNum;
      } else {
        this.dropdownOpenedId = null;
      }
    },

    /**
     * プレビューを表示
     *
     * プレビューはサーバーサイドで生成する。
     */
    showPreview () {
      this.validateAll();

      if (!this.isValid) return;

      this.isSubmitting = true;

      // 送信データの設定
      let data = this.setPostData();

      // プレビューを生成
      this.ajax({
        method: 'POST',
        url: '/talk/api/preview',
        data: data
      }, true)
      .then(response => {
        this.isSubmitting = false;

        this.comment.preview = response.data.view;
        this.isPreview = true;
      })
      .catch(error => {
        this.isSubmitting = false;
      });
    },

    /**
     * タグを JSON文字列 に変換
     */
    createTagJson () {
      this.tagJson = JSON.stringify({ tags: this.selectedTags });
    },

    /**
     * トーク作成・書き込み実行
     */
    save () {
      
      // ブラウザ自動操作チェック
      this.checkBrowser(this.userInfo.profile_id, 'トーク作成/レス投稿');

      this.validateAll();

      if (!this.isValid) return;

      this.isSubmitting = true;

      if (this.isNew) this.createTagJson();

      this.$nextTick(() => {
        if (typeof ga === 'function') {
          this.sendGaEvent({
            eventCategory: 'TalkThread',
            eventAction: this.isNew ? 'Create' : 'Comment',
            eventLabel: `category/${this.category.value}`,
            eventValue: 1
          });

          if (!this.isNew) {
            this.sendGaEvent({
              eventCategory: 'TalkThread',
              eventAction: 'Comment',
              eventLabel: `talk/${this.id}`,
              eventValue: 1
            });
          }
        }

        this.$nextTick(() => {
          window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
          document.forms.talk.submit();
        });

      });
    },

    /**
     * エラーボックスを非表示
     */
    closeErrorBox () {
      document.querySelector('.fixed-notice.is-visible').classList.remove('is-visible');
    },

    /**
     * 送信データの設定
     * @return {Object} [プレビュー表示に必要なデータ]
     */
    setPostData () {
      let data = {
        body: this.comment.body
      };

      if (this.isNew) {
        // 新規トークの場合
        if (this.target == 1
          || (this.target == 2 && this.comment.attachment === 'work' && this.resPlayId)) {
          // テーマトークの場合、またはフリートークで作品が添付されている場合
          data.res_play_id = this.resPlayId;
        } else if (this.target == 2 && this.comment.attachment === 'image' && this.comment.image.id) {
          // フリートークで画像が添付されている場合
          data.image_id = this.comment.image.id;
        }
      } else {
        // レスの場合
        if (this.comment.attachment === 'work' && this.resPlayId) {
          // 作品が添付されている場合
          data.res_play_id = this.resPlayId;
        } else if (this.comment.attachment === 'image' && this.comment.image.id) {
          // 画像が添付されている場合
          data.image_id = this.comment.image.id;
        }
      }

      return data;
    },

    /**
     * レスを削除
     * @param  {Number} talkId [トーク ID]
     * @param  {Number} resId  [削除対象レス ID]
     * @param  {Number} resNum [削除対象レス番号]
     */
    deleteRes (talkId, resId, resNum) {
      swal({
        title: '',
        text: 'この書き込みを削除しますか？\n削除すると再表示することはできません。',
        confirmButtonColor: '#c00',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'キャンセル'
      }, isConfirm => {
        if (isConfirm) {
          this.ajax({
            method: 'DELETE',
            url: `/talk/${talkId}/detail/${resId}`
          }, true)
          .then(response => {
            if(resNum === 1) {
              // 1レス目削除の場合は要素削除しない
              document.getElementById(resNum).classList.add('is-forbidden');
              document.getElementById(resNum).innerHTML = response.data;
            } else {
              // 該当のレス要素削除
              document.getElementById(resNum).remove();
            }

            if (typeof ga === 'function') {
              this.sendGaEvent({
                eventCategory: 'TalkThread',
                eventAction: 'Comment',
                eventLabel: `talk/${this.id}`,
                eventValue: -1
              });

              this.sendGaEvent({
                eventCategory: 'TalkThread',
                eventAction: 'Comment',
                eventLabel: `category/${this.category.value}`,
                eventValue: -1
              });
            }
          })
          .catch(error => {
            console.log(error);

            swal({
              title: '',
              type: 'error',
              text: '処理に失敗しました。画面を更新します。',
              confirmButtonColor: '#17A1E6',
            }, isConfirm => {
              location.reload();
            });
          });
        }
      });
    },

    /**
     * サムネイル選択時読み込み失敗
     */
    loadThumbFailed () {
      this.thumbImg.id = null;
      this.thumbImg.url = null;
      this.validation.thumbImg.isValid = false;
      this.validation.thumbImg.message = '※この画像は使用できません。';
    },

    /**
     * 添付画像選択時読み込み失敗
     */
    loadImageFailed () {
      this.comment.image.id = '';
      this.comment.image.url = '';
      this.validation.image.isValid = false;
      this.validation.image.message = '※この画像は使用できません。';
    },

    /**
     * ページ移動時の確認
     *
     * 項目に変更があればポップアップを表示する。
     * レス投稿の場合はコメント部分のみ比較する。
     * @param  {Event} e [beforeunload イベント]
     */
    onBeforeUnloadHandler (e) {
      const
        message = 'このページから移動してもよろしいですか？',
        originalData = this.isNew ? this.$data : this.comment;

      if (JSON.stringify(originalData) !== JSON.stringify(initialData)) {
        e.returnValue = message;
        return message;
      }
    },

    /**
     * モーダル内で選択したアイテム情報をセット
     * @param  {Object} item [モーダルで選択したアイテムオブジェクト]
     */
    selectItem (item) {
      switch (this.mediaType) {
        // 画像の場合
        case 'image':
          let data = {
            source: this.searchMode,
            url: item.origin
          };

          // 出典元の利用規約に違反する画像が指定されている場合は拒否する
          if (this.$store.getters['restrictedDomains/isRestricted'](item.origin)){
            const isComment = this.currentItem.isComment;
            let imageValidation = isComment ? this.validation.image : this.validation.thumbImg;
            imageValidation.isValid = false;
            imageValidation.message = '※出典元の利用規約に違反する可能性のある画像のため、再度選択し直してください。';
            break;
          }

          if (this.currentItem.isComment) {
            this.validation.image.isValid = true;
            this.validation.image.message = '';
          } else {
            this.validation.thumbImg.isValid = true;
            this.validation.thumbImg.message = '';
          }

          // 画像を DB に登録
          this.ajax({
            method: 'POST',
            url: '/aspect/authed/img/image',
            params: data
          }, false)
          .then(response => {
            this.currentItem.id = '';
            this.currentItem.url = '';

            this.$nextTick(() => {
              this.currentItem.id = response.data.id;
              this.currentItem.url = response.data.url;
            });
          })
          .catch(error => {
            // 想定外のエラーの場合
            console.log(error);

            this.currentItem.id = '';
            this.currentItem.url = '';

            if (this.currentItem.isComment) {
              this.validation.image.isValid = false;
              this.validation.image.message = '※この画像は使用できません。';
            } else {
              this.validation.thumbImg.isValid = false;
              this.validation.thumbImg.message = '※この画像は使用できません。';
            }
          });

          break;

        // 作品の場合
        case 'work':
          this.currentItem.playId = item.play_id;
          this.currentItem.thumbUrl = item.thumb_url;
          this.currentItem.workName = item.work_name;
          this.currentItem.makerName = item.maker_name;
          this.currentItem.introS = item.intro_s;
          this.currentItem.categoryId = item.category.category.id;
          this.currentItem.ageCategory = item.age_category;
          this.currentItem.category = item.category;

          if (!this.currentItem.isComment) {
            this.category.value = item.category.category.class_label;
            this.validateWork();
          }

          break;
      }

      this.$nextTick(() => {
        let images = document.querySelectorAll('.img-box > img');
        if (images.length > 0) objectFitImages(images);
      });
    },

    /**
     * 新規タグの追加
     * @param  {object} newTag [新規タグ]
     */
    pushNewTag (newTag) {
      this.selectedTags.push(newTag);
    },

    /**
     * タグの同期
     * @param  {object} tag [選択中のタグ]
     */
    removeTag (tag) {
      let r = [];

      this.selectedTags.forEach(v => {
        if (v.name !== tag.name) r.push(v);
      })

      this.selectedTags = r;
    },

    updateForcedState (prop, value) {
      this[prop] = value;
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.isNew) {
        this.isAdultMode = this.userInfo.is_adult_mode;
        this.ageCategory = document.getElementById('g').disabled ? 1 : null;
      }

      let self = this;

      // 初期状態の保存
      initialData = self.isNew ? _.cloneDeep(self.$data) : _.cloneDeep(self.comment);

      // サーバ側でバリデーションエラーだった場合
      if (self.hasError) {
        self.validateAll();

        if (!self.isNew) {
          let headerHeight = document.getElementById('header').clientHeight;
          let offsetTop = document.getElementById('input-area').offsetTop;

          Velocity(document.body, 'scroll', { duration: 200, offset: headerHeight + offsetTop });
        }
      }

      // タグが入力済みの場合復元
      if (self.tagJson) {
        self.selectedTags = JSON.parse(self.tagJson)['tags'];
      }

      document.body.addEventListener('click', () => {
        self.toggleDropdownContainer(null);
        self.category.classes['is-opened'] = false;
        self.isDropdownOpened = false;
      });

      if (!self.isNest) window.addEventListener('beforeunload', self.onBeforeUnloadHandler);

      this.isRendered = true;

      EventBus.$on('selectItem', this.selectItem);
      EventBus.$on('removeTag', this.removeTag);
      EventBus.$on('pushNewTag', this.pushNewTag);
      EventBus.$on('updateForcedState', this.updateForcedState);
    });
  }
});

module.exports = TalkStore;