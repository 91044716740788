/**
 * アンテナ: アンテナトップ画面
 */

import Vue from 'vue';
import _ from 'lodash';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';

let AntennaTop = Super.extend({
  data () {
    return {
      fetchedDomString: {
        pr_content: null,
        category: {},
        tags: null
      }
    };
  },

  methods: {
    fetchDomString () {
      this.ajax({
        method: 'GET',
        url: `/antenna/open/api/antenna-top-contents/${this.apiSuffix}`
      }, true)
      .then(response => {
        this.fetchedDomString = response.data;

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.initTwitterWidget();
          this.isRendered = true;
        });
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      // 年齢認証表示判定
      if (this.userInfo.is_adult_mode && !this.userInfo.can_show_adult) {
        document.body.classList.add('is-scroll-lock');
        document.body.classList.add('adult-check');
      }
  
      this.fetchDomString();
    });
  }
});

module.exports = AntennaTop;