/**
 * ウォッチ中のコンテンツ
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import WatchUnit from '../../utils/watch-unit.js';
import Pagination from '../../utils/components/pagination.js';
import EventBus from '../../utils/event-bus';

let Watchings = Super.extend({
  props: [
    'mode',
    'watchings',
    'isLoading',
    'amount',
    'currentPage',
    'matomeCount',
    'magazineCount',
    'talkCount',
    'makerCount',
    'tagCount',
    'isMypage'
  ],

  data () {
    return {
      filter: null
    }
  },

  template: require('../views/watchings.html'),

  components: {
    'pagination-component': Pagination,
    'watch-unit-component': WatchUnit
  },

  methods: {
    changeMode (mode) {
      EventBus.$emit('changeMode', mode);
    }
  }
});

module.exports = Watchings;
