/**
 * アイコン設定モーダル
 */

import Vue from 'vue';
import Cropper from 'cropperjs';
import EventBus from '../event-bus';

let cropper;

let IconUploadAction = Vue.extend({
  props: ['currentItem'],

  template: require('../views/icon-upload-action.html'),

  methods: {
    // モーダルクローズ
    closeModal () {
      EventBus.$emit('closeModal');
    },

    crop () {
      let dataUrl = cropper.getCroppedCanvas().toDataURL(this.currentItem._type);

      EventBus.$emit('closeModal');
      EventBus.$emit('setIcon', dataUrl);
    }
  },

  mounted () {
    this.$nextTick(() => {
      // 切り取り範囲指定エリア
      let cropping = document.getElementById('cropping');
  
      if (cropping.getAttribute('src')) {
        cropper.replace(this.currentItem._blobUri);
      } else {
        cropping.setAttribute('src', this.currentItem._blobUri);
  
        cropper = new Cropper(cropping, {
          viewMode: 1,
          dragMode: 'move',
          guides: false,
          aspectRatio: 1 / 1
        });
      }
    });
  },
});

module.exports = IconUploadAction;
