/**
 * まとめ管理
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import WatchUnit from '../../utils/watch-unit.js';
import Pagination from '../../utils/components/pagination.js';
import '../../utils/filters.js';

let Watchings = Super.extend({
  props: [
    'matomes',
    'isLoading',
    'amount',
    'currentPage',
    'isMypage'
  ],

  data () {
    return {
      filter: null
    }
  },

  template: require('../views/matomes.html'),

  components: {
    'watch-unit-component': WatchUnit,
    'pagination-component': Pagination
  },

  watch: {
    // matomes () {
    //   this.matomes.forEach(matome => {
    //     console.log(matome);
    //     matome.campaign.forEach(campaign => {
    //       if (campaign.campaign_id === 2 && campaign.status === 1) {
    //         matome.isGood = true;
    //       } else {
    //         matome.isGood = false;
    //       }
    //     });
    //   });
    // }
  },

  methods: {
    /**
     * まとめ記事削除
     * @param  {Number} matomeId [削除対象のまとめ ID]
     */
    deleteMatome (matomeId) {
      swal({
        title: '',
        text: '削除すると元には戻せません。よろしいですか？ ',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#c00',
        cancelButtonText: 'キャンセル'
      },
      isConfirm => {
        if (isConfirm) {

          this.ajax({
            method: 'DELETE',
            url: `/matome/api/matome/${matomeId}`
          }, true)
          .then(response => {
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
        }
      });
    }
  }
});

module.exports = Watchings;