/**
 * ユーザープロフィール
 */

import Vue from 'vue';
import Velocity from 'velocity-animate';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import WatchUnit from '../utils/watch-unit.js';
import Timeline from '../profile/components/timeline.js';
import Watchings from '../profile/components/watchings.js';
import Matomes from '../profile/components/matomes.js';
import Magazines from '../profile/components/magazines.js';
import Follows from '../profile/components/follows.js';
import Followers from '../profile/components/followers.js';
import TemplateBuilder from './template-builder.js';
import EventBus from '../utils/event-bus';

const templateBuilder = new TemplateBuilder();

let Profile = Super.extend({
  data () {
    return {
      id: null,
      mode: null,
      currentPage: 1,
      amount: 0,
      showingItems: [],
      throttled: null,
      isLoading: false,
      isError: false,
      isNoContent: false,
      isLocked: false,
      isProfileOpen: false
    }
  },

  computed: {
    /**
     * コンテンツエリアのオフセットトップ
     */
    offsetTop () {
      return document.getElementById('header').offsetTop;
    }
  },

  components: {
    'watch-unit-component': WatchUnit,
    'timeline-component': Timeline,
    'watchings-component': Watchings,
    'matomes-component': Matomes,
    'magazines-component': Magazines,
    'follows-component': Follows,
    'followers-component': Followers
  },

  methods: {
    /**
     * アクティビティタイムラインを取得
     *
     * @param  {Number} page = 1 [ページ番号]
     */
    fetchTimeline (page = 1) {
      let
        _timeline = [],
        query = {
          page: page
        },
        scrollTop;

      this.currentPage = page + 1;
      this.isLocked = true;
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/timeline/profile/${this.id}`,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        _timeline = response.data.items;

        if (_timeline.length === 0) {
          this.isNoContent = true;

          return;
        }

        _timeline.forEach((event, index) => {
          _timeline[index].content = templateBuilder.create(false, event);
        });

        this.showingItems = this.showingItems.concat(_timeline);

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.isRendered = true;
        });

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError) return;
            if (this.mode !== 'timeline') return;

            if (document.querySelector('.notification-items').clientHeight - 100 < document.body.scrollTop + document.body.clientHeight) {
              this.isLoading = true;

              this.fetchTimeline(this.currentPage);
            }
          }, 500);

          document.body.onscroll = this.throttled;
        }
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    /**
     * 選択したコンテンツを取得
     * @param  {Number} page = 1 [ページ番号]
     */
    fetchContents (page = 1) {
      let query = { page: page };

      this.isLocked = true;
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/profile/api/${this.id}/${this.mode}`,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;
        this.$root.previousRequest = null;

        this.showingItems = response.data.data;
        this.amount = response.data.total;

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.isRendered = true;
        });

        if (this.showingItems.length === 0) {
          this.isNoContent = true;

          return;
        }

      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
        this.previousRequest = null;
      });
    },

    /**
     * 表示コンテンツ切り替え
     * @param  {String} mode [表示コンテンツ]
     */
    changeMode (mode) {
      if (this.mode !== mode) {
        this.showingItems = [];
        this.amount = 0;
        this.currentPage = 1;
        this.isNoContent = false;
        this.mode = mode;
      }
    },

    /**
     * ページングイベント
     * @param  {Number} page [ページ数]
     */
    changePage (page) {
      Velocity(document.body, 'scroll', { duration: 200, offset: this.offsetTop });

      this.showingItems = [];
      this.currentPage = page;
      this.fetchContents(page);
    },
  },

  watch: {
    mode () {
      if (this.isProfileOpen) {
        if (this.mode === 'watch') {
          this.mode = 'watching_matome';
          return false;
        }

        switch(this.mode) {
          case 'watching_matome':
          case 'watching_talk':
          case 'watching_maker':
          case 'watching_tag':
          case 'watching_magazine':
            history.replaceState(null, null, `/profile/${this.id}/watch`);
            this.fetchContents();
            break

          case 'matome':
            history.replaceState(null, null, `/profile/${this.id}/matome`);
            this.fetchContents();
            break

          case 'follow':
            history.replaceState(null, null, `/profile/${this.id}/follow`);
            this.fetchContents();
            break

          case 'follower':
            history.replaceState(null, null, `/profile/${this.id}/follower`);
            this.fetchContents();
            break

          case 'timeline':
            history.replaceState(null, null, `/profile/${this.id}/timeline`);
            this.fetchTimeline();
            break;

          case 'magazine':
            history.replaceState(null, null, `/profile/${this.id}/magazine`);
            this.fetchContents();
            break

          default:
            break;
        }
      }
    }
  },

  beforeMount() {
    this.isProfileOpen = document.getElementById('profile_open_flg').value;
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('changeMode', this.changeMode);
      EventBus.$on('changePage', this.changePage);
      EventBus.$on('fetchTimeline', this.fetchTimeline);
    });
  }
});

module.exports = Profile;