/**
 * ブラウザ自動操作系チェック
 * @returns Object<{isAutomated: boolean, browser: string}>
 */
export const checkAutomated = () => {
  if(navigator.webdriver){
    return {
      isAutomated: true,
      browser: 'WebDriver',
    };
  }
  if(window.callPhantom || window.__phantom || window.phantom){
    return {
      isAutomated: true,
      browser: 'PhantomJS',
    };
  }
  if(window.__nightmare){
    return {
      isAutomated: true,
      browser: 'Nightmare',
    };
  }
  if(document.__selenium_unwrapped || document.__webdriver_evaluate || document.__driver_evaluate){
    return {
      isAutomated: true,
      browser: 'Selenium',
    };
  }
  return {isAutomated: false, browser: ''};
}

