import Vue from 'vue';
import { Multiselect } from 'vue-multiselect';
import _ from 'lodash';
import EventBus from '../event-bus';
import Validation from '../validation.js';

const validation = new Validation();

let TagSelector = Vue.extend({
  props: ['selectedTags', 'isMatome'],

  data () {
    return {
      selected: [],
      options: [],
      isLoading: false,
      validation: {
        name: {
          isValid: true,
          message: '',
        },
      },
    }
  },

  template: require('../views/tag-selector.html'),

  components: {
    'multiselect':  Multiselect
  },

  watch: {
    selectedTags () {
      this.selected = _.cloneDeep(this.selectedTags);

      if (this.$refs.multiselect) this.$refs.multiselect.$el.focus();
    }
  },

  methods: {
    fetchTags (q) {
      if (!q) {
        // 空のときはリセット
        this.options = [];
        return;
      }

      this.isLoading = true;

      // タグが最大数入力されている時は検索しない
      if (this.selectedTags.length > 9) {
        this.options = [];
        this.isLoading = false;
      } else {
        this.$root.ajax({
          method: 'GET',
          url: '/api/tag-suggest',
          params: { search: q }
        }, false)
        .then(response => {
          this.isLoading = false;
          this.previousRequest = null;

          if (response && response.data.length > 0) {
            this.options = [];
            response.data.forEach(tag => {
              this.options.push({ name: tag.name, id: tag.id, type: tag.type });
            });
          }
        })
        .catch(error => {
          console.log(error);

          this.isLoading = false;
          this.previousRequest = null;
        });
      }
    },

    // 新規タグ
    addTag (newTag) {
      let name = _.trim(newTag);
      this.resetValidation();
      this.validateTag(name);
      
      if(!this.validation.name.isValid) return;
      
      // EventBusしなければキャンセル
      if (this.selectedTags.length < 10 && name.length > 0) {
        let tag = {
          name: name,
          // 仮IDを設定
          id: 'isNew',
          type: 'keyword'
        };

        EventBus.$emit('pushNewTag', tag);
        this.options = [];
      }
    },

    updateSelectingTags (selecting) {
      EventBus.$emit('pushNewTag', selecting);
      this.options = [];

      if (this.isMatome) EventBus.$emit('updateTag');
    },

    removeTag (tag) {
      EventBus.$emit('removeTag', tag);
    },

    /**
     * バリデーションの値リセット
     */
    resetValidation () {
      this.validation.name.isValid = true;
      this.validation.name.message = "";
    },
    /**
     * バリデーションまとめて実行
     * @param tag
     */
    validateTag(tag) {
      this.validateNameLength(tag);
      this.validateNameIllegalCharacters(tag);
    },

    /**
     * 禁則文字チェック
     * @param tag
     */
    validateNameIllegalCharacters(tag) {
      if (tag.match('　') || tag.indexOf('#') === 0 || tag.indexOf('＃') === 0) {
        this.validation.name.isValid = false;
        this.validation.name.message = '※タグに利用できない文字が含まれています。';

        return;
      }
    },
    
    /**
     * 文字数チェック
     * @param tag
     */
    validateNameLength (tag) {
      if (!validation.maxSize(tag, 40)) {
        this.validation.name.isValid = false;
        this.validation.name.message = '※タグは40字以内で入力してください。';

        return;
      }
    }
  }
});

module.exports = TagSelector;