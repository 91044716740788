/**
 * アンテナ: 作品データベース->楽曲情報入力
 */

import Vue from 'vue';
import _ from 'lodash';
import Awesomplete from 'awesomplete';
import jobLabels from '../job-labels.json';
import musicLabels from '../music-labels.json';
import Validation from '../../utils/validation.js';
import EventBus from '../../utils/event-bus';

let validation = new Validation();

let MusicsDataAction = Vue.extend({
  props: ['musicsData'],

  template: require('../views/musics-data-action.html'),

  data () {
    return {
      compName: 'musicsData',
      throttled: null,
      openedId: null,
      createdAwesomplete: {},
      suggestingCreators: {
        job: null,
        creatorKey: null,
        creators: []
      },
      isValid: true,
      isLoading: false
    };
  },

  methods: {
    /**
     * バリデーション: 楽曲の種類
     *
     * @param  {Object} music [楽曲オブジェクト]
     */
    validateType (music) {
      music._validation.type.isValid = true;
      music._validation.type.message = '';

      if (!music.type) {
        music._validation.type.isValid = false;
        music._validation.type.message = '※楽曲の種類を選択してください。';
        this.isValid = false;

        return;
      }
    },

    /**
     * バリデーション: 楽曲タイトル
     *
     * @param  {Object} music [楽曲オブジェクト]
     */
    validateTitle (music) {
      music.title = _.trim(music.title);
      music._validation.title.isValid = true;
      music._validation.title.message = '';

      if (music.title.length === 0) {
        music._validation.title.isValid = false;
        music._validation.title.message = '※タイトルを入力してください。';
        this.isValid = false;

        return;
      }

      if (!validation.maxSize(music.title, 100)) {
        music._validation.title.isValid = false;
        music._validation.title.message = '※100字以内で入力してください。';

        return;
      }
    },

    /**
     * バリデーション: クリエイター
     * @param  {Object} creator [クリエイターオブジェクト]
     */
    validateCreator (creator) {
      let
        messages = [],
        state = true;
      creator.name = _.trim(creator.name);
      creator.comment = _.trim(creator.comment);
      creator._isValid = validation.creatorFormat(creator);

      if (!creator._isValid) {
        if (!creator.name) {
          messages.push('※クリエイター名を入力してください。');
        }

        if (creator.name.length > 40) {
          messages.push('※クリエイター名は40字以内で入力してください。');
        }

        if (creator.comment.length > 50) {
          messages.push('※備考は50字以内で入力してください。');
        }
      }
      // 文字数に関わらずチェックする
      if (creator.name.match('　') || creator.name.indexOf('#') === 0 || creator.name.indexOf('＃') === 0) {
        messages.push('※クリエイター名に利用できない文字が含まれています。');
        creator._isValid = false;
      }

      creator._message = messages.join("\n");

      this.musicsData.forEach(music => {
        this.isValid = (!validation.checkAllCreatorsValidationResult(music.creators)) ? false : state;
      });
    },

    /**
     * 全項目をバリデート
     */
    validateAll () {
      this.isValid = true;

      this.musicsData.forEach(music => {
        this.validateType(music);
        this.validateTitle(music);

        _.forEach(music.creators, (creatorsList, job) => {
          creatorsList.forEach(creator => {
            if (creator._isNew) this.validateCreator(creator);
          });
        });
      });
    },

    // 楽曲追加
    appendMusic () {
      let music = {
        type: '',
        title: '',
        creators: {
          artists: [],
          lyricists: [],
          composers: [],
          arrangers: []
        },
        _validation: {
          type: {
            isValid: true,
            message: ''
          },
          title: {
            isValid: true,
            message: ''
          }
        },
        _other: false
      };

      EventBus.$emit('appendMusic', music);
    },

    // 楽曲削除
    removeMusic (index) {
      EventBus.$emit('removeMusic', index);
    },

    // クリエイター追加
    appendCreator (musicKey, music, job) {
      let creator = {
        id: 'isNew',
        name: '',
        comment: '',
        _isValid: true,
        _message: '',
        _isNew: true,
        _other: false
      };

      EventBus.$emit('appendCreator', music.creators[job], creator);

      this.$nextTick(() => {
        this.initCreatorInput(musicKey, music, job, music.creators[job].length - 1);
      });
    },

    // クリエイター削除
    removeCreator (music, job, creatorKey) {
      EventBus.$emit('removeCreator', music.creators[job], creatorKey);
    },

    // クリエイターの職種を取得
    getLabel (key) {
      return jobLabels[key];
    },

    // 楽曲の種類セレクトボックスのトグル
    toggleSelectBox (musicKey = null) {
      if (musicKey !== null) {
        this.openedId = (musicKey === this.openedId) ? null : musicKey;
      } else {
        this.openedId = null;
      }
    },

    // 楽曲の種類を選択
    selectMusicType (musicKey, val) {
      EventBus.$emit('selectMusicType', musicKey, val)
      this.validateType(this.musicsData[musicKey]);
    },

    // 楽曲の種類を取得
    getMusicLabel (key) {
      return key ? musicLabels[key] : '楽曲の種類を選択';
    },

    // クリエイター名のサジェスト
    inputingCreatorName (musicKey, creatorName, music, job, creatorKey, e) {
      if (creatorName === music.creators[job][creatorKey]._tally) {
        return;
      } else {
        music.creators[job][creatorKey].id = 'isNew';
      }

      // 通信を間引く
      if (!this.throttled) {
        this.throttled = _.throttle((musicKey, creatorName, job, creatorKey) => {
          this.isLoading = true;
          this.suggestingCreators.job = job;
          this.suggestingCreators.creatorKey = creatorKey;

          this.$root.ajax({
            method: 'GET',
            url: '/api/tag-suggest',
            params: {
              search: creatorName,
              type: 'creator'
            }
          }, false)
          .then(response => {
            this.isLoading = false;

            this.suggestingCreators.creators = response.data.map(tag => {
              return { label: tag.name, value: tag.id };
            });

            this.createdAwesomplete[`m-${musicKey}-${job}-${creatorKey}`].list = this.suggestingCreators.creators;
          })
          .catch(error => {
            console.log(error);

            this.isLoading = false;
          });
        }, 500);
      }

      // 矢印キーの場合は取得しない
      if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
        return;
      }

      // 入力がある場合取得
      if (creatorName.length > 0) this.throttled(musicKey, creatorName, job, creatorKey);
    },

    // クリエイター入力エリアを初期化
    initCreatorInput (musicKey, music, job, creatorKey) {
      if (!this.createdAwesomplete[`m-${musicKey}-${job}-${creatorKey}`]) {
        let input = document.getElementById(`m-${musicKey}-${job}-${creatorKey}-input`);
        let awesomplete = new Awesomplete(input, {
          minChars: 1,
          autoFirst: true,
          replace: suggestion => {
            input.value = suggestion.label;
            music.creators[job][creatorKey].name = suggestion.label;
            music.creators[job][creatorKey].id = suggestion.value;
            music.creators[job][creatorKey]._tally = suggestion.label;
          }
        });

        this.createdAwesomplete[`m-${musicKey}-${job}-${creatorKey}`] = awesomplete;
      }
    }
  },

  events: {
    // 職種毎にバリデーション
    validateCreators (job) {
      _.forEach(this.musicsData, music => {
        _.forEach(music.creators[job], creator => {
          this.validateCreator(creator);
        });
      });
    }
  },

  created () {
    let self = this;

    document.body.onclick = () => {
      self.toggleSelectBox(null);
    };
  }
});

module.exports = MusicsDataAction;