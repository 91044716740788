/**
 * まとめ記事作成・編集画面 編集メニュー
 */

import Vue from 'vue';
import EventBus from '../../utils/event-bus';

var EditMenu = Vue.extend({
  props: ['index'],

  computed: {
    itemLength () {
      return this.$root.items.length;
    }
  },

  template: require('../views/edit-menu.html'),

  methods: {
    /**
     * 編集モードに切り替え
     */
    editStart () {
      EventBus.$emit('editStart', this.index);
    },

    /**
     * アイテムを削除
     */
    removeNode () {
      EventBus.$emit('removeNode', this.index);
    },

    /**
     * アイテムの位置を変更
     * @param  {number} indexTo [移動先インデックス]
     */
    shiftItem (indexTo) {
      EventBus.$emit('shiftItem', this.index, indexTo);
    }
  }
});

module.exports = EditMenu;