/**
 * 検索画面: トーク検索結果
 */

import Vue from 'vue';
import EventBus from '../../utils/event-bus';
import Pagination from '../../utils/components/pagination.js';
import '../../utils/filters.js';

let TalkResults = Vue.extend({
  props: ['items', 'amount', 'currentPage', 'isSelected', 'isLoading', 'isAntenna', 'playId'],

  data () {
    return {
      compName: 'talks',
      orderBy: {
        new: {
          label: '新着順',
          value: 'new'
        },
        hot: {
          label: 'レスが新しい',
          value: 'hot'
        },
        res: {
          label: 'レスが多い',
          value: 'res'
        },
        watch: {
          label: '注目されている',
          value: 'watch'
        },
        'rank.day': {
          label: '盛り上がっている',
          value: 'rank.day'
        },
        condition: 'new',
        classes: {
          isOpen: false
        }
      },
      currentViewsFrom: null,
      currentViewsTo: null
    };
  },

  template: require('../views/talk-results.html'),

  components: {
    'pagination-component': Pagination
  },

  watch: {
    isSelected () {
      this.init();
    }
  },

  events: {
    conditionReset () {
      this.orderBy.value = 'new';
    },

    initialSearch (val) {
      this.orderBy.condition = val;
    }
  },

  methods: {
    /**
     * ソート条件を選択
     * @param  {String} name       ['order_by']
     * @param  {String} val        [ソート条件]
     * @param  {null} defaultVal   [デフォルト値]
     */
    toggleOrder (name, val, defaultVal) {
      this.orderBy.condition = val;

      EventBus.$emit('toggleCondition', name, val, defaultVal);
    },
  },

  mounted () {
    this.$nextTick(() => {
      const self = this;

      if (self.isSelected) {
        document.body.addEventListener('click', () => {
          self.orderBy.classes.isOpen = false;
        });

        this.orderBy.condition = this.$root.query.order_by;
      }
    });
  }
});

module.exports = TalkResults;
