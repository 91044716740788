/**
 * ユーザー情報ストア
 */

import axios from 'axios';

export default {
  state: {
    isInitialized: false,
    can_show_adult: false,
    customer_id: null,
    is_admin: false,
    is_adult_mode: false,
    is_logined: null,
    login_id: null,
    nick_name: null,
    profile_id: null,
    remote_ip: null,
    thumb_url: null,
    twitter_authed: false,
    pageview_sent: false,
    unread_count: 0,
    header_menu: false
  },

  /**
   * ユーザー情報取得
   */
  fetchUserInfo () {
    let date = new Date();

    return axios.get(`/api/member-status?token=${date.getTime()}`)
      .then(response => {
        Object.keys(response.data).forEach(prop => {
          this.state[prop] = response.data[prop];
        });

        console.log(this.state);

          let portalEndPoint = null;
          if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
            portalEndPoint = 'https://login.dlsite.com/api/user/self';
          } else {
            portalEndPoint = 'https://login.dev.dlsite.com/api/user/self';
          }
          const credentialClient = axios.create({
            withCredentials: true,
            xsrfCookieName: null
          });

          credentialClient.get(portalEndPoint).then((res) => {
              this.state.login_id = res.data.logged_in;
              this.state.isInitialized = true;
              document.body.addEventListener('click', () => {
                this.state.header_menu = false;
              });
          }).catch(error => {
            this.state.isInitialized = true;
            console.error(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  },

  /**
   * Google アナリティクスにページビューを送信
   */
  sendGaPageview () {
    gtag('set', {'dimension1': this.state.is_logined ? '{member}' : '{guest}'});
    gtag('set', {'dimension2': this.state.is_adult_mode ? '{adult}' : '{general}'});
    gtag('set', {'dimension4': this.state.remote_ip ? this.state.remote_ip : 'unknown IP'});
    gtag('event', 'page_view');

    this.state.pageview_sent = true;
  }
}
