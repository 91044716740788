/**
 * マイページ
 */

import Vue from 'vue';
import Velocity from 'velocity-animate';
import objectFitImages from 'object-fit-images';
import Super from '../utils/super.js';
import WatchUnit from '../utils/watch-unit.js';
import Timeline from '../profile/components/timeline.js';
import Watchings from '../profile/components/watchings.js';
import Matomes from '../profile/components/matomes.js';
import Follows from '../profile/components/follows.js';
import Followers from '../profile/components/followers.js';
import Magazines from '../profile/components/magazines.js';
import TemplateBuilder from './template-builder.js';
import EventBus from '../utils/event-bus';

const templateBuilder = new TemplateBuilder();

let Mypage = Super.extend({
  data () {
    return {
      mode: null,
      currentPage: 1,
      amount: 0,
      showingItems: [],
      iconImage: {
        dataUrl: null,
        thumbData: null,
        delFlg: false,
        _blobUri: null,
        _type: null
      },
      tlContent: '',
      throttled: null,
      isLoading: false,
      isError: false,
      isNoContent: false,
      isLocked: false
    };
  },

  computed: {
    /**
     * コンテンツエリアのオフセットトップ
     */
    offsetTop () {
      return document.getElementById('header').offsetTop;
    }
  },

  components: {
    'watch-unit-component': WatchUnit,
    'timeline-component': Timeline,
    'watchings-component': Watchings,
    'matomes-component': Matomes,
    'magazines-component': Magazines,
    'follows-component': Follows,
    'followers-component': Followers
  },

  methods: {
    /**
     * まとめ記事削除
     * @param  {number} matomeId [削除対象のまとめ ID]
     */
    deleteMatome (matomeId) {
      swal({
        title: '',
        text: '削除すると元には戻せません。よろしいですか？ ',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#c00',
        cancelButtonText: 'キャンセル'
      },
      isConfirm => {
        if (isConfirm) {

          this.ajax({
            method: 'DELETE',
            url: `/matome/api/matome/${matomeId}`
          }, true)
          .then(response => {
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
        }
      });
    },

    /**
     * アクティビティタイムラインを取得
     *
     * @param  {Number} page = 1 [ページ番号]
     */
    fetchTimeline (page = 1) {
      let
        _timeline = [],
        query = {
          page: page
        },
        scrollTop;

      this.currentPage = page + 1;
      this.isLocked = true;
      this.isLoading = true;

      this.ajax({
        method: 'GET',
        url: `/timeline/mypage/${this.tlContent}`,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        _timeline = response.data.items;

        if (_timeline.length === 0) {
          this.isNoContent = true;

          return;
        }

        _timeline.forEach((event, index) => {
          _timeline[index].content = templateBuilder.create(true, event);
        });

        this.showingItems = this.showingItems.concat(_timeline);

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.isRendered = true;
        });

        if (!this.throttled) {
          this.throttled = _.throttle(() => {
            if (this.isLocked || this.isNoContent || this.isError) return;
            if (this.mode !== 'timeline') return;

            scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            if (document.querySelector('.notification-items').clientHeight - 100 < scrollTop + document.body.clientHeight) {
              this.isLoading = true;

              this.fetchTimeline(this.currentPage);
            }
          }, 500);

          window.onscroll = this.throttled;
        }
      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    /**
     * 選択したコンテンツを取得
     * @param  {Number} page = 1 [ページ番号]
     */
    fetchContents (page = 1) {
      let query = { page: page };

      this.isLocked = true;
      this.isLoading = true;

      return this.ajax({
        method: 'GET',
        url: `/mypage/api/${this.mode}`,
        params: query,
      }, false)
      .then(response => {
        this.isLoading = false;
        this.isLocked = false;

        this.showingItems = response.data.data;
        this.amount = response.data.total;

        if (this.mode === 'matome') {
          // ポイントバッジ付与
          this.showingItems.forEach(matome => {
            matome.isGood = false;

            matome.campaign.some(campaign => {
              if ((campaign.campaign_id === 2 || campaign.campaign_id === 9) && campaign.status === 1) {
                // 旧常設キャンペーン id === 2, 100ポイントキャンペーン id === 9
                matome.isGood = true;
                return true;
              }
            });
          });
        }

        this.$nextTick(() => {
          let images = document.querySelectorAll('.img-box > img');
          if (images.length > 0) objectFitImages(images);

          this.isRendered = true;
        });

        if (this.showingItems.length === 0) {
          this.isNoContent = true;

          return;
        }

      })
      .catch(error => {
        console.log(error);

        this.isLoading = false;
        this.isLocked = false;
        this.isError = true;
      });
    },

    /**
     * 表示コンテンツ切り替え
     * @param  {String} mode [表示コンテンツ]
     */
    changeMode (mode) {
      if (this.mode !== mode) {
        this.showingItems = [];
        this.amount = 0;
        this.currentPage = 1;
        this.isNoContent = false;
        this.mode = mode;
      }
    },

    /**
     * タイムラインのコンテンツ切り替え
     * @param  {String} content [取得対象のコンテンツ名]
     */
    changeTlContent (content) {
      this.showingItems = [];
      this.tlContent = content;
      this.currentPage = 1;
      this.isNoContent = false;
      this.fetchTimeline();
    },

    /**
     * ページングイベント
     * @param  {Number} page [ページ数]
     */
    changePage (page) {
      Velocity(document.body, 'scroll', { duration: 200, offset: this.offsetTop });

      this.showingItems = [];
      this.currentPage = page;

      this.fetchContents(page);
    }
  },

  watch: {
    mode () {
      if (this.mode === 'watch') {
        this.mode = 'watching_matome';
        return false;
      }

      this.$nextTick(() => {
        switch(this.mode) {
          case 'watching_matome':
          case 'watching_talk':
          case 'watching_maker':
          case 'watching_tag':
          case 'watching_magazine':
            history.replaceState(null, null, '/mypage/watch');
            this.fetchContents();
            break

          case 'matome':
            history.replaceState(null, null, '/mypage/matome');
            this.fetchContents();
            break

          case 'follow':
            history.replaceState(null, null, '/mypage/follow');
            this.fetchContents();
            break

          case 'follower':
            history.replaceState(null, null, '/mypage/follower');
            this.fetchContents();
            break

          case 'timeline':
            history.replaceState(null, null, '/mypage/timeline');
            this.fetchTimeline();
            break;
            
          case 'magazine':
            history.replaceState(null, null, '/mypage/magazine');
            this.fetchContents();
            break;

          default:
            break;
        }
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      EventBus.$on('changeMode', this.changeMode);
      EventBus.$on('changeTlContent', this.changeTlContent);
      EventBus.$on('changePage', this.changePage);
      EventBus.$on('fetchTimeline', this.fetchTimeline);
    });
  }
});

module.exports = Mypage;
