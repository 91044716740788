/**
 * フォロー一覧
 */

import Vue from 'vue';
import Super from '../../utils/super.js';
import WatchUnit from '../../utils/watch-unit.js';
import Pagination from '../../utils/components/pagination.js';
import '../../utils/filters.js';

let Watchings = Super.extend({
  props: [
    'follows',
    'isLoading',
    'amount',
    'currentPage',
    'isMypage'
  ],

  data () {
    return {
      filter: null
    }
  },

  template: require('../views/follows.html'),

  components: {
    'pagination-component': Pagination,
    'watch-unit-component': WatchUnit
  }
});

module.exports = Watchings;