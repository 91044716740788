import Vue from 'vue';
import _ from 'lodash';
import TagSelector from './components/tag-selector.js';
import WatchUnit from './watch-unit.js';
import EventBus from './event-bus';

let GlobalTagSelector = Vue.extend({
  props: ['type', 'target', 'contentId', 'isLogined', 'isLocked'],

  data () {
    return {
      selectedTags: [],
      tags: [],
      isEdit: false
    }
  },

  template: require('./views/global-tag-selector.html'),

  components: {
    'tag-selector-component': TagSelector,
    'watch-unit-component': WatchUnit
  },

  methods: {
    /**
     * 編集中のタグの更新
     * @param  {Array} selecting [編集中のタグ]
     */
    updateSelectedTags (selecting) {
      this.selectedTags = selecting;
    },

    /**
     * 新規タグの追加
     * @param  {object} newTag [新規タグ]
     */
    pushNewTag (newTag) {
      this.selectedTags.push(newTag);
    },

    /**
     * タグの削除
     * @param  {object} removedOption [削除対象タグ]
     */
    removeTag (removedOption) {
      this.selectedTags = _.differenceBy(this.selectedTags, [removedOption], 'id');
    },

    /**
     * タグの編集・表示モードを切り替える
     * @param  {Event} e [クリックイベント]
     */
    toggleMode (e) {
      // 未ログインならキャンセル
      if (!this.isLogined) {
        this.$root.loginCheck(this.isLogined, null, e);
        return;
      }

      if (this.isEdit && !this.isLocked) {
        this.save();
      } else {
        this.fetchTags();
        this.selectedTags = this.tags;
      }

      this.isEdit = !this.isEdit;
    },

    /**
     * 登録されているタグを取得
     */
    fetchTags () {
      this.$root.ajax({
        method: 'GET',
        url: `/aspect/open/tag/index/${this.type}/${this.contentId}`
      }, true)
      .then(response => {
        this.tags = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * タグを保存
     */
    save () {
      let data = {
        type: this.type,
        id: this.contentId,
        tags: this.selectedTags
      };

      this.$root.ajax({
        method: 'POST',
        url: '/aspect/tag/add',
        data: { json: JSON.stringify(data) }
      }, true)
      .then(response => {
        this.fetchTags();
      })
      .catch(error => {
        console.log(error);
      });
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.fetchTags();

      EventBus.$on('pushNewTag', this.pushNewTag);
      EventBus.$on('updateSelectedTags', this.updateSelectedTags);
      EventBus.$on('removeTag', this.removeTag);
    });
  },

  created () {
    let self = this;

    document.body.addEventListener('click', () => {
      self.isDropdownOpened = false;
    });
  }
});

module.exports = GlobalTagSelector;